import React from 'react';
import { Link } from 'react-router-dom';
import MapPng from '../../../assets/map.png';
import Phone from '../../../assets/phone.png';
import Envelope from '../../../assets/envelope.png';
import EmailModel from '../therapistProfile/emailModel';
import CallModel from '../therapistProfile/callModel';


let backendServerURL = process.env.REACT_APP_API_URL;


class SearchResult extends React.Component{
 
    render(){
        const { profile } = this.props;
    return (
    <div className="col-sm-6 col-lg-4 searchParentContainer text-center">
        <Link to={`/profile/${profile['listing_id']}`}>
            { profile['image'] ?
                <img src={ `${backendServerURL}/images/${profile['image']}` } className="img-fluid searchImage position-absolute" alt="DPCircle" />
            : <img src={ `${backendServerURL}/images/undefined.png` } className="img-fluid searchImage position-absolute" alt="DPCircle" /> }
        </Link>
        <div className="searchContent">    
            <div className="searchResultText">
                <div className="checkContainer w-100">
                    { profile['is_verified'] && <i className="fa fa-check mb-0" aria-hidden="true"></i> }
                </div>

                <Link to={`/profile/${profile['listing_id']}`}>
                    <h5 className="searchTitle font-weight-bolder text-white">{ profile['practice_name'] }</h5>
                </Link>

                <p className="searchTextOrange font-weight-bold mb-1">{ profile['title'] }</p>
                
                <p className="searchLocation font-weight-bold mb-1"><i className="fa fa-map-marker" aria-hidden="true"></i>
                { profile['city'] }, { profile['state_province'] } </p>

                <div className="socialIcons d-flex">

                    <div className="orangeBg">
                    
                        <a target="_blank" rel="noopener noreferrer"  href={`${profile['website']}`} >
                            <img src={ MapPng} className="map" alt="map" />
                        </a>
                    </div>

                    <div className="orangeBg">
                        { !profile['accepting_new_clients'] && <div className="profile-overlay"></div> }
                            <img src={ Phone } className="phone cursor-pointer" alt="phone" data-toggle="modal" data-backdrop="static" data-keyboard="false" data-target="#CallModal"/>
                            {  profile['listing_id'] && <CallModel therapist={profile['listing_id']} /> }
                    </div>

                    <div className="orangeBg">
                        { !profile['accepting_new_clients'] && <div className="profile-overlay"></div> }
                            <img src={ Envelope } alt="Envelope" data-toggle="modal" data-target="#messageModal" className="cursor-pointer" />
                        {  profile['listing_id'] && <EmailModel therapist={profile['listing_id']} /> }
                    </div>

        
                    
                </div>
                <div className="accepting-Text-container">
                    {  !profile['accepting_new_clients'] && <p className="notAccepting-Text">Not accepting new Clients</p> }
                </div>
            </div>
        </div>
    </div>
    )}
}

export default SearchResult;

