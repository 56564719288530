import React from 'react';
import PropTypes from 'prop-types';
import {Route, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';

const AdminPrivateRoute = ({ component: Component, admin, ...rest }) => (
	
	<Route
		{...rest}
		render={
			props => admin.isAuthenticated ? (
				<Component {...props} />
			) : (
				<Redirect to={"/admin/login"} />
			)
		}
	/>
);

AdminPrivateRoute.propTypes = {
	admin: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	admin: state.admin
});

export default connect(mapStateToProps)(AdminPrivateRoute);