import {
    MENU_CONTAINER_ADD_CLASSNAME,
    MENU_CLICK_MOBILE_MENU,
    MENU_CHANGE_DEFAULT_CLASSES,
    MENU_CHANGE_HAS_SUB_ITEM_STATUS
} from './types';

export const changeSelectedMenuHasSubItems = (payload) => {
    return (
        {
            type: MENU_CHANGE_HAS_SUB_ITEM_STATUS,
            payload: payload
        }
    )
}

export const changeDefaultClassnames = (strCurrentClasses) => {
    return (
        {
            type: MENU_CHANGE_DEFAULT_CLASSES,
            payload: strCurrentClasses
        }
    )
}

export const addContainerClassname = (classname, strCurrentClasses) => {
    const newClasses = !strCurrentClasses.indexOf(classname) > -1 ? strCurrentClasses + ' ' + classname : strCurrentClasses;
    return (
        {
            type: MENU_CONTAINER_ADD_CLASSNAME,
            payload: newClasses
        }
    )
}

export const clickOnMobileMenu = (strCurrentClasses) => {
    const currentClasses = strCurrentClasses ? strCurrentClasses.split(' ').filter(x => x !== '' && x !== 'sub-show-temporary') : '';
    let nextClasses = '';
    if (currentClasses.includes('main-show-temporary')) {
        nextClasses = (currentClasses.filter(x => x !== 'main-show-temporary')).join(' ');
    } else {
        nextClasses = currentClasses.join(' ') + ' main-show-temporary';
    }
    return (
        {
            type: MENU_CLICK_MOBILE_MENU,
            payload: { containerClassnames: nextClasses, menuClickCount: 0 }
        }
    )
}