import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { getConnections, toggleEndorsement, deleteConnection } from '../../../../store/actions/therapistActions';

class MyConnections extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            find: '',
            connections: {}
        }
    }


    static getDerivedStateFromProps(props, state) {

        const { connections } = props.therapist;

        let changedState = {};
        let stateChanged = false;

        if (connections && JSON.stringify(state.connections) !== JSON.stringify(connections)) {
            changedState.connections = connections;
            stateChanged = true;
        }

        if (stateChanged) {
            return changedState;
        }

        return null;
    }

    componentDidMount() {

        this.props.getConnections(this.props.match.params.id);
    }

    setEndorsement = (connectionId, requestSenderId, value) => {

        this.props.toggleEndorsement({
            connectionId: connectionId,
            id: requestSenderId,
            value: value
        })
    }

    onDeleteConnection = (connectionId, requestSenderId) => {
        this.props.deleteConnection(connectionId, requestSenderId)
    }

    render() {
        const { connections } = this.state;
        console.log('connection', connections)
        return (
            <div className="tab-content support-table" id="myTabContent">
                <div className="" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col" className="connection-page-id-column">ID</th>
                                <th scope="col">Image</th>
                                <th scope="col">Name</th>
                                <th scope="col">Email</th>
                                <th scope="col">Endorsements</th>
                                <th scope="col">Endorsement</th>
                                <th scope="col">Connection</th>
                            </tr>
                        </thead>

                        <tbody>
                            {connections.map((list, index) => (
                                <tr key={index}>
                                    <td >
                                        {list.network_connection_id}
                                    </td>
                                    <td>Image</td>
                                    <td>{list.reciver.practice_name}</td>
                                    <td>{list.reciver.email}</td>
                                    <td>{list.endorsement}</td>
                                    <td>
                                        {list.is_confirmed ?
                                            <button onClick={() => this.setEndorsement(list.network_connection_id, list.listing_id, false)} type="button" className="btn btn-deny">Hide </button> :
                                            <button onClick={() => this.setEndorsement(list.network_connection_id, list.listing_id, true)} type="button" className="btn btn-allow">Show</button>
                                        }
                                    </td>
                                    <td>
                                        <button onClick={() => this.onDeleteConnection(list.network_connection_id, list.listing_id)} type="button" className="btn btn-deny">Delete</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {connections.length === 0 && "There is no connection"}

                </div>
            </div>
        )
    }
}

MyConnections.propTypes = {
    getConnections: PropTypes.func.isRequired,
    deleteConnection: PropTypes.func.isRequired,
    toggleEndorsement: PropTypes.func.isRequired,
    therapist: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    therapist: state.therapist
});

export default connect(mapStateToProps, { getConnections, deleteConnection, toggleEndorsement })(withRouter(MyConnections));