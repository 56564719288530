import { 
	SET_THERAPIST,
	CLEAR_THERAPIST
 } from '../actions/types';

const initialState = {
	isAuthenticated: false,
	accountId: '',
	applicationId : ''
};

export default function(state = initialState, action) {
	switch (action.type) {
		case SET_THERAPIST:
			return {
				...state,
				isAuthenticated: true,
				accountId: action.payload.accountId,
				applicationId: action.payload.applicationId
			};
		case CLEAR_THERAPIST:
			return {
				...state,
				isAuthenticated: false,
				accountId : '',
				applicationId : ''
			};

		default:
			return state;
	}
}