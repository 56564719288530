import React from 'react';


function SideBar(props) {
    const { activePage, setActivePage } = props;
    return(
        <ul className="list-group">
            <li className="list-group-item" > 
                <span className="icon-status">
                    <i className={[ "fa ", activePage === 1 ? " fa-stop-circle " :  activePage > 1 ? " fa-check": " fa-circle-o" ]} aria-hidden="true"></i>
                </span><span className="cursor-pointer" onClick={() => setActivePage(1)}>General</span>
            </li>
            <li className="list-group-item" >
                <span className="icon-status">
                    <i className={[ "fa ", activePage === 2 ? " fa-stop-circle " :  activePage > 2 ? " fa-check": " fa-circle-o" ]} aria-hidden="true"></i>
                </span> <span className="cursor-pointer" onClick={() => setActivePage(2)}>Location</span>
            </li>
            <li className="list-group-item" >
                <span className="icon-status">
                    <i className={[ "fa ", activePage === 3 ? " fa-stop-circle " :  activePage > 3 ? " fa-check": " fa-circle-o" ]} aria-hidden="true"></i>
                </span><span className="cursor-pointer" onClick={() => setActivePage(3)}>Practice Details</span>
            </li>
            <li className="list-group-item" >
                <span className="icon-status">
                    <i className={[ "fa ", activePage === 4 ? " fa-stop-circle " :  activePage > 5 ? " fa-check": " fa-circle-o" ]} aria-hidden="true"></i>
                </span><span className="cursor-pointer" onClick={() => setActivePage(5)}>Review</span>
            </li>
        </ul>
    )
}

export default SideBar;