import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';

const SuperAdminPrivateRoute = ({ component: Component, superAdmin, ...rest }) => (
	
	
	<Route
		{...rest}
		render={
			props => superAdmin.isAuthenticated ? (
				<Component {...props} />
			) : (
				<Redirect to={"/super-admin/login"} />
			)
		}
	/>
);


const mapStateToProps = state => ({
	superAdmin: state.superAdminAuth
});

export default connect(mapStateToProps)(SuperAdminPrivateRoute);