import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import Header from '../../common/Header';
import Footer from '../../common/Footer';
import HomeBanner from '../../../assets/new-desktop-home-banner.png';
import MobileBanner from '../../../assets/34.png';
import MobileBanner2 from '../../../assets/34w.png';
import HomerLowerBanner from '../../../assets/home-lower-banner.png';
import HomerLowerBanner2 from '../../../assets/56.png';

class Home extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            homeSearch : ''
        }
    }


    onChange= (e) =>{

        this.setState({
            [e.target.name] : e.target.value
        }) 

    }

    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
          this.props.history.push('/search-results?search='+ this.state.homeSearch)
        }
    }

    render(){

        return (
        <div className="home-bg">
            <Header />
                <div className="container max-width desktopBannerContainer">
                    <div className="row">
                        <div className="col-sm-12 col-md-5 col-lg-4 order-1 order-sm-2 order-md-1">
                        <div className="desktopBannerText">
                            <h1>Therapy comes <br className="LineBreak" />in many colors</h1>
                        </div>

                        <div className="desktopBannerSmallText">
                            <p>Experienced therapists that <br className="LineBreakSmall" />understand all that makes <br className="LineBreakSmall" />you <b>you.</b></p>

                            <p className="findTherapist">Find your therapist</p>
                            <i className="fa fa-search searchIcon" aria-hidden="true"></i>
                            <input 
                                type="text" 
                                className="searchField" 
                                placeholder="Search by City, Zip or Name" 
                                name="homeSearch"
                                value={ this.state.homeSearch}
                                onChange={this.onChange}
                                onKeyDown={this._handleKeyDown} 
                            />
                        </div>
                        </div>

                        <div className="col-sm-12 col-md-7 col-lg-8 order-2 order-sm-1 order-md-2">
                        <div className="homeBannerContainer">
                            <div className="mainBanner">
                            <img src={HomeBanner} className="desktopBanner" alt="HomeBanner"/>
                            <img src={MobileBanner} className="mobileBanner" alt="HomeBanner" />
                            <img src={MobileBanner2} className="mobileBanner" alt="HomeBanner" />
                            <img src={HomerLowerBanner2} className="mobileBanner" alt="HomeBanner" />
                            </div>

                        </div>
                        </div>
                    </div>
                </div>

                <div className="container max-width">
                    <div className="titleText">
                        <h1>We do more than just listen</h1>
                    </div>
                    <div className="titlePlainText">
                        <p>Tones of therapy is a directory of licensed therapists for racial
                        and ethnic minorities aimed at removing the burden of accessibility and stigma of mental health while building meaningful client-therapist relationships</p>
                    </div>

                    <div className="therapistBtn">
                        <Link to={"/sign-up"} >
                            <button type="button">Join as</button>
                            </Link>
                        <Link to={"/sign-up"} >
                            <button type="button">Join as therapist</button>
                        </Link>
                    </div>

                    <div className="therapistCoupleImg">
                        <img src={HomerLowerBanner} alt="HomerLowerBanner" />
                    </div>
                </div>
            <Footer />
        </div>
        )
    }
}

export default withRouter(Home);