import React from 'react';



class Review extends React.Component {

    render(){
        const { general, locations,  onSubmit  , filters , selected} = this.props;  

    return(
        <form onSubmit={ onSubmit } encType="multipart/form-data">    
            <div className="image-contents">
                <div className="cat-card" > 
                    <div className={[general.listingType === 'solo' && 'textWhite ' , 'ac-front-side face']}>
                        <div className="hovering-c"> 
                            <span className="cat-icon"> 
                                <i className={[general.listingType === 'solo' && "iconTextBlue  " ," fa fa-plus"]} aria-hidden="true"></i>
                            </span> 
                            <span className="category-name">Solo</span>
                        </div>
                    </div>
                </div>
                        
                <div className="cat-card"> 
                    <div className={[general.listingType === 'group' && 'textWhite ' , 'ac-front-side face']}>
                        <div className="hovering-c"> 
                            <span className="cat-icon" style={{color: "#fff"}}> 
                            <i className={[general.listingType === 2 && "iconTextBlue  " ," fa fa-plus"]} aria-hidden="true"></i>
                            </span> 
                            <span className="category-name">Group</span>
                        </div>
                    </div>
                </div>
            </div>

        <div className="listing-form general listing-review">
            <div className="listing-form-container-div"> 
            <h1 className="listingTitle">General</h1>

                <div className="row listingFormFields">
                    <div className="col-md-12 col-lg-6 d-flex justify-content-between align-items-baseline">
                        <label htmlFor="Name">Practice Name : </label>
                        <span className="review-info" >{general.name}</span> 
                    </div>
                    
                    <div className="col-md-12 col-lg-6 d-flex justify-content-between align-items-baseline">
                        <label htmlFor="Title">Title : </label>
                        <span className="review-info" >{general.title}</span> 
                        
                    </div>
                </div>

                <div className="row listingFormFields">
                    <div className="col-md-12 col-lg-6 d-flex justify-content-between align-items-baseline">
                        <label htmlFor="WOrk Phone">Work Phone :</label>
                        <span className="review-info" >Ahmad</span> 
                    </div>
                    
                    <div className="col-md-12 col-lg-6 d-flex justify-content-between align-items-baseline">
                        <label htmlFor="Mobile Phone">Mobile Phone : </label>
                        <span className="review-info" >{ general.phone }</span> 
                    </div>
                </div>

                <div className="row listingFormFields">
                    <div className="col-md-12 col-lg-6 d-flex justify-content-between align-items-baseline">
                        <label htmlFor="Email">Email : </label>
                        <span className="review-info" >{ general.email }</span> 
                    </div>
                    
                    <div className="col-md-12 col-lg-6 d-flex justify-content-between align-items-baseline">
                        <label htmlFor="Website">Website :</label>
                        <span className="review-info" >{ general.website }</span> 
                    </div>
                </div>

                <div className="row listingFormFields">
                    <div className="col-md-12 col-lg-6 d-flex justify-content-between align-items-baseline">
                        <label htmlFor="License-no">License Number :</label>
                        <span className="review-info" >{ general.license }</span> 
                    </div>
                    
                    <div className="col-md-12 col-lg-6 d-flex justify-content-between align-items-baseline">
                        <label htmlFor="License-state">License State :</label>
                        <span className="review-info" >{ general.licenseState }</span> 
                    </div>
                </div>

                <div className="row listingFormFields">
                    <div className="col-md-12 col-lg-6 d-flex justify-content-between align-items-baseline">
                        <label htmlFor="License-no">Remote Sessions:</label>
                        <span className="review-info" >{ general.remoteSessions ? 'YES' : 'NO' }</span> 
                    </div>
                    
                    <div className="col-md-12 col-lg-6 d-flex justify-content-between align-items-baseline">
                        <label htmlFor="License-state">Prescribe Medication:</label>
                        <span className="review-info" >{ general.prescribeMedication ? 'YES' : 'NO' }</span> 
                    </div>
                </div>
                
                <div className="row listingFormFields">
                    <div className="col-md-12 col-lg-6 d-flex justify-content-between align-items-baseline">
                        <label htmlFor="License-no">New Clients:</label>
                        <span className="review-info" > { general.newClients ? 'YES' : 'NO' }</span> 
                    </div>
                    
                    <div className="col-md-12 col-lg-6 d-flex justify-content-between align-items-baseline">
                        <label htmlFor="License-no">Sliding Scale:</label>
                        <span className="review-info" >{ general.slidingScale ? 'YES' : 'NO' }</span> 
                    </div>
                </div>

                
                { locations.map((location,idx)=>(
                <React.Fragment key={idx}>
                    <h2 className="listingTitle">Location { idx+1 } </h2>
                    <div className="row listingFormFields">
                        <div className="col-md-12 col-lg-6 d-flex justify-content-between align-items-baseline">
                            <label htmlFor="Street">Street Address :</label>
                            <span className="review-info" >{ location.address }</span> 
                        </div>
                        
                        <div className="col-md-12 col-lg-6 d-flex justify-content-between align-items-baseline">
                            <label htmlFor="City">City : </label>
                            <span className="review-info" >{ location.city }</span> 
                        </div>
                    </div>

                    <div className="row listingFormFields">
                        <div className="col-md-12 col-lg-6 d-flex justify-content-between align-items-baseline">
                            <label htmlFor="State">State :</label>
                            <span className="review-info" >{ location.state }</span> 
                        </div>
                        
                        <div className="col-md-12 col-lg-6 d-flex justify-content-between align-items-baseline">
                            <label htmlFor="Zip Code">Zip Code : </label>
                            <span className="review-info" >{ location.zipCode }</span> 
                        </div>
                    </div>
                </React.Fragment>
                )) }

                <h1 className="listingTitle">Practise Details</h1>
                
                <div className="form-group">

                    {   filters.map((filter, index) => {

                        // let attributes =  filter.sub_categories.filter(attribute => selected.includes(item => item.sub_category_id === attribute.sub_category_id)).length;

                        const attributes = selected.find(element => element.category_id === filter.category_id ) ? true : false; 

                        if(attributes){

                            return (
                                <div key={index} className="filterResultTitle">
                                        <h4 className="subheading">{ filter.category }</h4>
                                            { filter.sub_categories.map( (attribute,index2) =>{
                                                let show = '';
                                                
                                                const value = selected.find(element => element.sub_category_id === attribute.sub_category_id ) ? true : false;  
                                                
                                                if(value){
                                                    show = <div key={index2} className="form-check">
                                                        
                                                        <input 
                                                            className="form-check-input" 
                                                            type="radio"
                                                            id={ attribute.sub_category }
                                                            checked={ value }
                                                            readOnly
                                                        />
                                                        
                                                        <span className="checkmark"></span>
                                                        <label className="form-check-label" htmlFor={ attribute.sub_category }>
                                                            { attribute.sub_category } 
                                                        </label>
                                                    </div>
                                                }
                                                
                                                return show

                                                } )
                                            }
                        
                                    </div>
                                )
                            }
                            return '' 
                        }) 
                    } 
                </div>

                <button type="submit" className="NextBtn">Update</button>
            </div>
        </div>
        </form>
        )
    }
}

export default Review;