import axios from 'axios';

import {
    SET_ERRORS,
    SET_NOTIFICATION,
    PAGE_LOADING,
    PAGE_LOADED,
    SET_KNOWLEDGEBASE,
    SET_DASHBOARD,
    SET_LISTINGS,
    SET_ACCOUNT_DETAILS,
    SET_PROFILE,
    SET_LIST_CONNECTIONS,
    SET_FIND_CONNECTIONS,
    UPDATE_CONNECTION,
    DELETE_CONNECTION,
    SET_SUCCESS,
    UPDATE_LIST_SUBSCRIPTION,
    SET_BILLING,
    DELETE_PAYMENT_METHOD,
    SET_PAYMENT_DATA
} from './types';

import { logouttherapist } from './authActions';
const backendServerURL = process.env.REACT_APP_API_URL;


export const settherapistLoading = () => {
    return {
        type: PAGE_LOADING
    };
};

export const cleartherapistLoading = () => {
    return {
        type: PAGE_LOADED
    };
};

export const setError = (message) => dispatch => {

    dispatch({ type: SET_ERRORS, payload: message })
}

export const getknowledgebas = () => dispatch => {

    dispatch(settherapistLoading());

    axios
        .get(
            backendServerURL + '/account/knowledgebase'
        )
        .then(res => {

            dispatch({ type: SET_KNOWLEDGEBASE, payload: res.data })
        })
        .catch(err => dispatch({ type: SET_ERRORS, payload: err.response.data && err.response.data.message }))
        .finally(() => dispatch(cleartherapistLoading()));
};

export const getDashboard = () => dispatch => {

    dispatch(settherapistLoading());

    axios
        .get(
            backendServerURL + '/account/dashboard'
        )
        .then(res => {

            dispatch({ type: SET_DASHBOARD, payload: res.data })
        })
        .catch(err => dispatch({ type: SET_ERRORS, payload: err.response.data }))
        .finally(() => dispatch(cleartherapistLoading()));
};

export const getListings = () => dispatch => {

    dispatch(settherapistLoading());

    axios
        .get(
            backendServerURL + '/account/listing'
        )
        .then(res => {

            dispatch({ type: SET_LISTINGS, payload: res.data })
        })
        .catch(err => dispatch({ type: SET_ERRORS, payload: err.response.data && err.response.data.message }))
        .finally(() => dispatch(cleartherapistLoading()));
};

export const getAccountDetails = () => dispatch => {

    dispatch(settherapistLoading());

    axios
        .get(
            backendServerURL + '/account/get'
        )
        .then(res => {

            dispatch({ type: SET_ACCOUNT_DETAILS, payload: res.data });
        })
        .catch(err => dispatch({ type: SET_ERRORS, payload: err.response.data && err.response.data.message }))
        .finally(() => dispatch(cleartherapistLoading()));
};

export const editAccountDetails = (data) => dispatch => {
    dispatch(settherapistLoading());

    axios
        .post(
            backendServerURL + '/account/edit',
            data
        )
        .then(res => {

            dispatch({ type: SET_ACCOUNT_DETAILS, payload: res.data })
            dispatch({ type: SET_SUCCESS, payload: 'Account Updated Successfully' })
        })
        .catch(err => dispatch({ type: SET_ERRORS, payload: err.response.data && err.response.data.message }))
        .finally(() => dispatch(cleartherapistLoading()));
};


export const addListings = (formData, history) => dispatch => {

    dispatch(settherapistLoading());

    axios
        .post(
            backendServerURL + '/account/listing/add',
            formData
        )
        .then(res => {
            history.push(`/therapist/listings`);
            dispatch({ type: SET_SUCCESS, payload: 'New Listing Added Succesfully' })
        })
        .catch(err => dispatch({ type: SET_ERRORS, payload: err.response.data && err.response.data.message }))
        .finally(() => dispatch(cleartherapistLoading()));
};

export const editListing = (data, history) => dispatch => {

    dispatch(settherapistLoading());

    axios
        .post(
            backendServerURL + '/account/listing/edit',
            data
        )
        .then(res => {

            history.push(`/therapist/listings`);

            dispatch({ type: SET_SUCCESS, payload: 'Listing Succesfully Edited' })
        })
        .catch(err => dispatch({ type: SET_ERRORS, payload: err.response.data && err.response.data.message }))
        .finally(() => dispatch(cleartherapistLoading()));
};


export const getListing = (id) => dispatch => {

    dispatch(settherapistLoading());

    axios
        .get(
            backendServerURL + '/account/listing/' + id,
        )
        .then(res => {

            dispatch({ type: SET_PROFILE, payload: res.data })
        })
        .catch(err => dispatch({ type: SET_ERRORS, payload: err.response.data && err.response.data.message }))
        .finally(() => dispatch(cleartherapistLoading()));
};


export const getNotifications = () => dispatch => {

    dispatch(settherapistLoading());

    axios
        .get(
            backendServerURL + '/account/notification'
        )
        .then(res => {
            dispatch({ type: SET_NOTIFICATION, payload: res.data })
        })
        .catch(err => dispatch({ type: SET_ERRORS, payload: err.response.data }))
        .finally(() => dispatch(cleartherapistLoading()));
};


export const cancelSubscription = (id) => dispatch => {

    dispatch(settherapistLoading());

    axios
        .get(
            backendServerURL + '/account/listing/cancel-subscription/' + id
        )
        .then(res => {

            dispatch({ type: UPDATE_LIST_SUBSCRIPTION, payload: res.data })
            dispatch({ type: SET_SUCCESS, payload: 'Subscription Canceled Successfully' })
        })
        .catch(err => dispatch({ type: SET_ERRORS, payload: err.response.data && err.response.data.message }))
        .finally(() => dispatch(cleartherapistLoading()));
};

export const toggleLsiting = (id) => dispatch => {

    dispatch(settherapistLoading());
    axios
        .get(
            backendServerURL + '/account/listing/toggle-activation/' + id
        )
        .then(res => {
            dispatch({ type: UPDATE_LIST_SUBSCRIPTION, payload: res.data })
            dispatch({ type: SET_SUCCESS, payload: `List ${res.data.is_active ? 'Activated' : 'Deactivated'} Successfully` })
        })
        .catch(err => dispatch({ type: SET_ERRORS, payload: err.response.data && err.response.data.message }))
        .finally(() => dispatch(cleartherapistLoading()));
};

export const renewSubscription = (form, history) => dispatch => {

    dispatch(settherapistLoading());

    axios
        .post(
            backendServerURL + '/account/listing/renew-subscription',
            form
        )
        .then(res => {
            history.push('/therapist/listings')
            dispatch({ type: SET_SUCCESS, payload: 'New Subscription Successfully Created' })
        })
        .catch(err => dispatch({ type: SET_ERRORS, payload: err.response.data && err.response.data.message }))
        .finally(() => dispatch(cleartherapistLoading()));

};

export const getConnections = (listId) => dispatch => {

    dispatch(settherapistLoading());
    axios
        .get(
            backendServerURL + '/account/listing/connection/' + listId
        )
        .then(res => {

            dispatch({ type: SET_LIST_CONNECTIONS, payload: res.data })
        })
        .catch(err => dispatch({ type: SET_ERRORS, payload: err.response.data }))
        .finally(() => dispatch(cleartherapistLoading()));
};

export const findConnections = (searchString) => dispatch => {

    dispatch(settherapistLoading());

    axios
        .get(
            backendServerURL + '/account/listing/connection/find/' + searchString,
        )
        .then(res => {

            dispatch({ type: SET_FIND_CONNECTIONS, payload: res.data })
        })
        .catch(err => dispatch({ type: SET_ERRORS, payload: err.response.data && err.response.data.message }))
        .finally(() => dispatch(cleartherapistLoading()));
};

export const toggleEndorsement = (connectionsData) => dispatch => {

    dispatch(settherapistLoading());

    axios
        .put(
            backendServerURL + '/account/listing/connection/update',
            connectionsData
        )
        .then(res => {

            dispatch({ type: UPDATE_CONNECTION, payload: connectionsData })
            dispatch({ type: SET_SUCCESS, payload: 'Connection Successfully Updated' })
        })
        .catch(err => dispatch({ type: SET_ERRORS, payload: err.response.data && err.response.data.message }))
        .finally(() => dispatch(cleartherapistLoading()));
};


export const deleteConnection = (connectionId, requesterId) => dispatch => {
    dispatch(settherapistLoading());
    axios
        .delete(
            backendServerURL + `/account/listing/connection/delete/?id=${connectionId}&requesterId=${requesterId}`
        )
        .then(res => {

            dispatch({ type: DELETE_CONNECTION, payload: connectionId });
            dispatch({ type: SET_SUCCESS, payload: 'Connection Deleted' })
        })
        .catch(err => dispatch({ type: SET_ERRORS, payload: err.response.data && err.response.data.message }))
        .finally(() => dispatch(cleartherapistLoading()));
};

export const createConnection = (connectionsData) => dispatch => {

    dispatch(settherapistLoading());

    axios
        .post(
            backendServerURL + '/account/listing/connection/add',
            connectionsData
        )
        .then(res => {

            dispatch({ type: SET_SUCCESS, payload: 'New Connection Created' })
        })
        .catch(err => dispatch({ type: SET_ERRORS, payload: err.response.data && err.response.data.message }))
        .finally(() => dispatch(cleartherapistLoading()));
};

export const getBillingData = () => dispatch => {

    dispatch(settherapistLoading());
    axios
        .get(
            backendServerURL + '/account/billing'
        )
        .then(res => {

            dispatch({ type: SET_BILLING, payload: res.data })
        })
        .catch(err => dispatch({ type: SET_ERRORS, payload: err.response.data && err.response.data.message }))
        .finally(() => dispatch(cleartherapistLoading()));
};

export const editAddress = (data) => dispatch => {

    dispatch(settherapistLoading());
    axios
        .post(
            backendServerURL + '/account/billing/edit',
            data
        )
        .then(res => {

            dispatch({ type: SET_SUCCESS, payload: 'Billing Address SuccessFully Changed' })
        })
        .catch(err => dispatch({ type: SET_ERRORS, payload: err.response.data && err.response.data.message }))
        .finally(() => dispatch(cleartherapistLoading()));
};

export const detachCard = (paymentId) => dispatch => {

    dispatch(settherapistLoading());

    axios
        .get(backendServerURL + `/account/detach-card/` + paymentId)
        .then(res => {

            dispatch({ type: DELETE_PAYMENT_METHOD, payload: paymentId })
            dispatch({ type: SET_SUCCESS, payload: `Successfully payment method detached!` })
        })
        .catch(err => {

            dispatch(setError(err.response.data && err.response.data.message));
        })
        .finally(() => dispatch(cleartherapistLoading()));
}

export const getPaymentData = () => dispatch => {

    dispatch(settherapistLoading());

    axios
        .get(
            backendServerURL + `/account/payment-data`
        )
        .then(res => {
            dispatch({ type: SET_PAYMENT_DATA, payload: res.data });
        })
        .catch(err => {
            dispatch(setError(err.response.data && err.response.data.message));
        })
        .finally(() => dispatch(cleartherapistLoading()));
}

export const getAccounts = (searchString) => dispatch => {

    dispatch(settherapistLoading());

    axios
        .get(
            backendServerURL + '/account/listing/find-account/' + searchString,
        )
        .then(res => {

            dispatch({ type: SET_FIND_CONNECTIONS, payload: res.data })
        })
        .catch(err => dispatch({ type: SET_ERRORS, payload: err.response.data && err.response.data.message }))
        .finally(() => dispatch(cleartherapistLoading()));
};

export const transferAccount = (data, history) => dispatch => {

    dispatch(settherapistLoading());
    axios
        .post(
            backendServerURL + '/account/listing/transfer',
            data
        )
        .then(res => {

            dispatch({ type: SET_SUCCESS, payload: `List Successfully Transfered to Another Account!` })
            history.push('/therapist/listings')
        })
        .catch(err => dispatch({ type: SET_ERRORS, payload: err.response.data && err.response.data.message }))
        .finally(() => dispatch(cleartherapistLoading()));

}

export const sendTicket = (data) => dispatch => {

    dispatch(settherapistLoading());
    axios
        .post(
            backendServerURL + '/account/ticket',
            data
        )
        .then(res => {

            dispatch({ type: SET_SUCCESS, payload: `Your Query has Been Sent!` })
        })
        .catch(err => dispatch({ type: SET_ERRORS, payload: err.response.data && err.response.data.message }))
        .finally(() => dispatch(cleartherapistLoading()));

}


export const closeAccount = (data) => dispatch => {

    dispatch(settherapistLoading());
    axios
        .get(
            backendServerURL + '/account/close',
        )
        .then(res => {

            dispatch(logouttherapist())
        })
        .catch(err => dispatch({ type: SET_ERRORS, payload: err.response.data && err.response.data.message }))
        .finally(() => dispatch(cleartherapistLoading()));

}