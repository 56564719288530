import React from 'react';
import Logo from '../../assets/logo.png';
import UserDp from '../../assets/dp-placeholder.png';
import { Link } from 'react-router-dom';

import {connect} from 'react-redux';
import { logoutSuperAdmin } from '../../store/actions/superAdminActions';


class Header extends React.Component {
    render() {
        return(
        <header className="super-dashboard">
            <div className="container-fluid">
                <div className="row m-0 justify-content-between align-items-center">
                    <div className="col-4 col-sm-3 col-lg-2">
                        <Link to={"/super-admin/"}>
                            <img src={Logo} alt="" className="img-fluid" />
                        </Link>
                    </div>

                    <div className="col-8 col-sm-9 col-lg-3 text-right pr-lg-4">
                        <img src={UserDp} alt="" className="dp-placeholder" />
                        <div className="dropdown d-inline">
                            <button className="btn dropdown-toggle text-white" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Tones of Therapy
                            </button>
                            <div className="dropdown-menu rounded-0" aria-labelledby="dropdownMenuButton">
                                <Link className="dropdown-item border-bottom-0" to={"/super-admin/users"}>Users</Link>
                                <Link className="dropdown-item border-bottom-0" to={"/super-admin/applications"}>Applications</Link>
                                <Link className="dropdown-item border-bottom-0" onClick={this.props.logoutSuperAdmin } to={"#"}>Log Out</Link>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </header>
        )
    }
    
}
  
export default connect(null, {logoutSuperAdmin })(Header);