import React from 'react';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import { Link } from 'react-router-dom';
import { Field } from "formik";
import { FormGroup } from "reactstrap";

export const Item = SortableElement(({value, idx, onChange }) => (
    <li tabIndex={0} style={{ width : '80%' }}>
        <FormGroup style={{ width : '100%'}}>
            <Field
                className=" form-control-theme"
                id={idx} 
                value={value.sub_category}
                name={"attribute"}
                onChange={(e) =>  onChange('attribute', e) }
                placeholder="Add Sub-category" 
                style={{ width : '100%' }}
            />
        </FormGroup>
    </li>
));

export const List = SortableContainer(({items, onChange, removeAttibute } ) => {
    return (
        <ul>
        { items.map((value, index) => (
            <div key={`item-${index}`} style={{ display: 'flex', justifyContent : 'space-between', alignItems : 'center'}}>
            <Item key={`item-${index}`} index={ index } idx={ index } onChange={ onChange } value={value} />
            <Link to={"#"} onClick={() => removeAttibute( index ) } className="more-category">Remove Category </Link>
            </div>
        ))}
        </ul>
    );
});