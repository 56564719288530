import React from 'react';
import {BrowserRouter as Router , Route } from 'react-router-dom';
import {Provider} from 'react-redux';
import jwt_decode from 'jwt-decode';

import setAuthToken from './utils/setAuthToken';
import store from './store';
import SuperAdminPrivateRoute from './components/common/superAdminPrivateRoute';
import TherapistPrivateRoute from './components/common/TherapistPrivateRoute';
import AdminPrivateRoute from './components/common/adminPrivateRoute';
import { setCurrentTherapist, logouttherapist } from './store/actions/authActions';
import { setCurrentSuperAdmin, logoutSuperAdmin } from './store/actions/superAdminActions';
import { setCurrentAdmin, logoutAdmin } from './store/actions/adminAuthActions';

// ***** Client import Start *****
import Home from './components/user/home';
import ContactUs from './components/user/contactUs';
import Login from './components/auth/Login';
import AdminLogin from './components/admin/login';
import SuperAdminLogin from './components/superAdmin/login'
import ForgetPassword from './components/auth/ForgetPassword';
import ResetPassword from './components/auth/ResetPassword';
import SignUp from './components/auth/SignUp';
import ForTherapist from './components/user/forTherapist';
import TherapistPorfile from './components/user/therapistProfile';
import SearchResults from './components/user/searchResults';

// ***** Client import End *****
import SuperAdmin from './components/superAdmin';
import Admin from './components/admin';
import Therapist from './components/therapist';

function App() {

    if (localStorage.superJwtToken) {

        const decoded = jwt_decode(localStorage.superJwtToken);
        if(decoded.adminId){
            setAuthToken(localStorage.superJwtToken);
            store.dispatch(setCurrentSuperAdmin(decoded));
            const currentTime = Date.now()/1000;

            if (decoded.exp < currentTime) {
                store.dispatch(logoutSuperAdmin())

                window.location.href = '/super-admin/login';
            }  
        }
    }


    if (localStorage.adminJwtToken) {
        const decoded = jwt_decode(localStorage.adminJwtToken);
        if(decoded.adminId){
            setAuthToken(localStorage.adminJwtToken);
            store.dispatch(setCurrentAdmin(decoded));
            const currentTime = Date.now()/1000;

            if (decoded.exp < currentTime) {
                store.dispatch(logoutAdmin());

                window.location.href = '/admin/login';
                
            }  
        }
    }

    if (localStorage.jwtToken) {
        const decoded = jwt_decode(localStorage.jwtToken);
        if(decoded.accountId){
            setAuthToken(localStorage.jwtToken);
            store.dispatch(setCurrentTherapist(decoded));
            const currentTime = Date.now()/1000;
    
            if (decoded.exp < currentTime) {
                store.dispatch(logouttherapist());
                window.location.href = '/login';
            }  
        }
    }
            

    return (
        <Provider store={store}>
            <Router >
                
                <Route
                    exact 
                    path={"/"} 
                    component= {() => <Home /> } 
                />

                <Route
                    exact 
                    path={"/contact-us"} 
                    component= { ContactUs } 
                />

                <Route
                    exact 
                    path={"/login"} 
                    component= { Login } 
                />

                <Route
                    exact 
                    path={"/forget-password"} 
                    component= { ForgetPassword } 
                />

                <Route
                    exact 
                    path={"/reset-password/:token"} 
                    component= { ResetPassword } 
                />

                <Route
                    exact 
                    path={"/sign-up"} 
                    component= { SignUp } 
                />

                <Route
                    exact 
                    path={"/for-therapist"} 
                    component= { ForTherapist } 
                />

                <Route 
                    path={"/profile/:id"} 
                    component= { () => <TherapistPorfile /> } 
                />

                <Route
                    exact 
                    path={"/search-results"} 
                    component= { () => <SearchResults /> } 
                />

                <TherapistPrivateRoute 
                    exact 
                    path={"/therapist/dashboard"} 
                    component={ Therapist } 
                />

                <TherapistPrivateRoute 
                    exact 
                    path={"/therapist/listing/add"} 
                    component={ Therapist } 
                />

                <TherapistPrivateRoute 
                    exact 
                    path={"/therapist/listing/edit/:id"} 
                    component={ Therapist } 
                /> 

                <TherapistPrivateRoute  
                    exact 
                    path={"/therapist/connections/:id"} 
                    component={ Therapist }
                />

                <TherapistPrivateRoute  
                    exact 
                    path={"/therapist/transfer/:id"} 
                    component={ Therapist }
                />

                <TherapistPrivateRoute 
                    exact 
                    path={"/therapist/listings"} 
                    component={ Therapist } 
                />

                <TherapistPrivateRoute 
                    exact 
                    path={"/therapist/billing"} 
                    component={ Therapist } 
                />

                <TherapistPrivateRoute 
                    exact 
                    path={"/therapist/account"} 
                    component={ Therapist } 
                />

                <TherapistPrivateRoute 
                    exact 
                    path={"/therapist/help-center"} 
                    component={ Therapist } 
                />

                <TherapistPrivateRoute 
                    exact 
                    path={"/therapist/notifications"} 
                    component={ Therapist } 
                /> 

                <TherapistPrivateRoute  
                    exact 
                    path={"/therapist/renew-subscription/:id"} 
                    component={ Therapist }
                />

                <SuperAdminPrivateRoute
                    exact 
                    path={"/super-admin"} 
                    component= { SuperAdmin } 
                />

                <SuperAdminPrivateRoute
                    exact 
                    path={"/super-admin/applications"} 
                    component= { SuperAdmin } 
                />

                <SuperAdminPrivateRoute
                    exact 
                    path={"/super-admin/application/add"} 
                    component= { SuperAdmin } 
                />

                <SuperAdminPrivateRoute
                    exact 
                    path={"/super-admin/application/edit/:id"} 
                    component= { SuperAdmin } 
                />


                <SuperAdminPrivateRoute
                    exact 
                    path={"/super-admin/users"} 
                    component= { SuperAdmin } 
                />

                <SuperAdminPrivateRoute
                    exact 
                    path={"/super-admin/user/add"} 
                    component= { SuperAdmin } 
                />

                <SuperAdminPrivateRoute
                    exact 
                    path={"/super-admin/user/edit/:id"} 
                    component= { SuperAdmin } 
                />

                <Route   
                    exact
                    path={"/super-admin/login"} 
                    component={ SuperAdminLogin }
                />

                <AdminPrivateRoute
					exact 
					path={"/admin"} 
					component= { Admin } 
				/>

				<AdminPrivateRoute
					exact 
					path={"/admin/dashboard"} 
					component= { Admin } 
				/>

				<AdminPrivateRoute
					exact 
					path={"/admin/support-ticket"} 
					component= { Admin } 
				/>

				<AdminPrivateRoute
					exact 
					path={"/admin/accounts"} 
					component= { Admin } 
				/>

				<AdminPrivateRoute
					exact 
					path={"/admin/add-category"} 
					component= { Admin } 
				/>

				<AdminPrivateRoute
					exact 
					path={"/admin/edit-category/:id"} 
					component= { Admin } 
				/>

				<AdminPrivateRoute
					exact 
					path={"/admin/categories"} 
					component= { Admin } 
				/>
				

				<AdminPrivateRoute
					exact 
					path={"/admin/edit-question/:id"} 
					component= { Admin } 
				/>

				<AdminPrivateRoute 
					exact
					path={"/admin/add-question"}
					component= { Admin }
				/>

				<AdminPrivateRoute
					exact 
					path={"/admin/questions"} 
					component= { Admin } 
				/>

				<Route   
					exact
					path={"/admin/login"} 
					component={ AdminLogin }
				/>

            </Router>
        </Provider>
    );
}

export default App;