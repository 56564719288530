import React from 'react';
import {logouttherapist} from '../../store/actions/authActions';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Logo from '../../assets/logo.png';
import UserDp from '../../assets/dp-placeholder.png';
import { Link } from 'react-router-dom';

class Header extends React.Component {
    render(){
        return (
        <header>
            <div className="container-fluid">
                <div className="row m-0 justify-content-between align-items-center">
                    <div className="col-4 col-sm-3 col-lg-2">
                        <Link to={"/dashboard"} >
                            <img src={Logo} className="img-fluid" alt="LogoImg"/>
                        </Link>
                    </div>

                    <div className="col-8 col-sm-9 col-lg-3 text-right pr-lg-4">
                        <img src={UserDp} className="dp-placeholder" alt="user-dp"/>
                        <div className="dropdown d-inline">
                            <button className="btn dropdown-toggle text-white" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Tones of Therapy
                            </button>
                            <div className="dropdown-menu rounded-0" aria-labelledby="dropdownMenuButton">
                                <Link className="dropdown-item" to={"/therapist/dashboard"}>Dashboard</Link>
                                <Link className="dropdown-item" to={"/therapist/listing/add"}>New Listings</Link>
                                <Link className="dropdown-item" to={"/therapist/listings"}>My Listings</Link>
                                <Link className="dropdown-item" to={"/therapist/billing"}>Billing</Link>
                                <Link className="dropdown-item" to={"/therapist/account"}>Account</Link>
                                <Link className="dropdown-item" to={"/therapist/notifications"}>Notifications</Link>
                                <Link className="dropdown-item" to={"/therapist/help-center"}>Help</Link>
                                <Link className="dropdown-item border-bottom-0" to={"#"} onClick={ this.props.logouttherapist } >Log Out</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        );
    }
}

Header.propTypes = {
    logouttherapist: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
});


export default connect(mapStateToProps, {logouttherapist})(Header);