import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import { Link, withRouter} from 'react-router-dom';
import { getBillingData , editAddress, detachCard } from '../../../store/actions/therapistActions';


class Billing extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            billingData : {},
            billing : {
                address: '',
                city: '',
                state: '',
                postal_code : ''
            },
            shipping:{
                address: '',
                city: '',
                state: '',
                postal_code : ''
            }
        }
        
    }

    static getDerivedStateFromProps(props, state) {

        const { billing }  = props.therapist;

        let changedState = {};
        let stateChanged = false;

        if(billing && JSON.stringify(state.billingData) !== JSON.stringify(billing)){
            changedState.billingData = billing;
            if(billing.billing)
                changedState.billing = billing.billing;
            if(billing.shipping)
                changedState.shipping = billing.shipping;
            stateChanged = true;
        }

        if(stateChanged){
            return changedState;
        }

        return null;
    }

    componentDidMount(){
        this.props.getBillingData();
    }

    onChange = (objectName, e) => {

        this.setState({
            [objectName] : {
                ...this.state[objectName],
                [e.target.name] :  e.target.value
            }
        })
    }

    onSubmit= (e) =>{
        e.preventDefault();

        const { billing, shipping } = this.state;

        const data ={
            billing,
            shipping
        }

        this.props.editAddress(data)
    }
    
    render() {
        const { billingData, billing , shipping } = this.state;

        return(
            <div className="listing-container-content">
            <div className="my-listing-Container">
            
            <form className="address-form" onSubmit={this.onSubmit }> 
                <h1 className="pageTitle">Billing Address</h1>
                <div className="row listingFormFields">
                    <div className="col-md-12 col-lg-6 d-flex justify-content-between align-items-baseline">
                        <label htmlFor="address">Street Address</label>
                        <input 
                            type="text" 
                            className="form-control" 
                            placeholder="Street Address" 
                            name="address"
                            value={ billing.address }
                            onChange={(e)=> this.onChange('billing', e) }   
                            required
                        />
                    </div>
                    
                    <div className="col-md-12 col-lg-6 d-flex justify-content-between align-items-baseline">
                        <label htmlFor="city" >City</label>
                        <input 
                            type="text" 
                            className="form-control" 
                            placeholder="City" 
                            name="city"    
                            value={billing.city}
                            onChange={(e)=> this.onChange('billing', e) }
                            required
                        />
                    </div>
                </div>

                <div className="row listingFormFields">
                    <div className="col-md-12 col-lg-6 d-flex justify-content-between align-items-baseline">
                        <label htmlFor="state" >State</label>
                        <input 
                            type="text" 
                            className="form-control" 
                            placeholder="State" 
                            name="state"  
                            value={billing.state}
                            onChange={(e)=> this.onChange('billing', e) } 
                            required 
                        />
                    </div>
                    
                    <div className="col-md-12 col-lg-6 d-flex justify-content-between align-items-baseline">
                        <label htmlFor="postal_code">Zip Code</label>
                        <input 
                            type="text" 
                            className="form-control" 
                            placeholder="Postal Code" 
                            name="postal_code"
                            value={billing.postal_code}
                            onChange={(e)=> this.onChange('billing', e) }  
                            required 
                        />
                    </div>
                </div>

                <h1 className="pageTitle">Shipping Address</h1>
                <div className="row listingFormFields">
                    <div className="col-md-12 col-lg-6 d-flex justify-content-between align-items-baseline">
                        <label htmlFor="address">Street Address</label>
                        <input 
                            type="text" 
                            className="form-control" 
                            placeholder="Street Address" 
                            name="address"
                            value={ shipping.address }
                            onChange={(e)=> this.onChange('shipping', e) }
                            required   
                        />
                    </div>
                    
                    <div className="col-md-12 col-lg-6 d-flex justify-content-between align-items-baseline">
                        <label htmlFor="city" >City</label>
                        <input 
                            type="text" 
                            className="form-control" 
                            placeholder="City" 
                            name="city"    
                            value={shipping.city}
                            onChange={(e)=> this.onChange('shipping', e) }
                            required
                        />
                    </div>
                </div>

                <div className="row listingFormFields">
                    <div className="col-md-12 col-lg-6 d-flex justify-content-between align-items-baseline">
                        <label htmlFor="state" >State</label>
                        <input 
                            type="text" 
                            className="form-control" 
                            placeholder="State" 
                            name="state"  
                            value={shipping.state}
                            onChange={(e)=> this.onChange('shipping', e) }  
                            required
                        />
                    </div>
                    
                    <div className="col-md-12 col-lg-6 d-flex justify-content-between align-items-baseline">
                        <label htmlFor="postal_code">Postal Code</label>
                        <input 
                            type="text" 
                            className="form-control" 
                            placeholder="Postal Code" 
                            name="postal_code"
                            value={ shipping.postal_code }
                            onChange={(e)=> this.onChange('shipping', e) }   
                            required
                        />
                    </div>
                </div>

                <button type="submit" className="btn-primary ">Submit</button>
            </form>
            <h1 className="pageTitle">Payment Methods</h1>
            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th scope="col">id</th>
                        <th scope="col">Brand</th>
                        <th scope="col">Country</th>
                        <th scope="col">Last 4</th>
                        <th scope="col">Type</th>
                        <th scope="col" className="cancel-Coulumn">Detach Card</th>
                    </tr>
                </thead>
                <tbody>  
                    { billingData.cards ? billingData.cards.map(( card, index )=>(
                        <tr key={index}>
                            <td> { index+1 }</td>
                            <td >{ card.card.brand }</td>
                            <td >{ card.card.country }</td>
                            <td >{ card.card.last4 }</td>
                            <td >{ card.type }</td>

                            
                            <td>
                                <Link to="#" onClick={()=> this.props.detachCard(card.id) } className="btn btn-info">Detach Card</Link>
                            </td>
                            
                        </tr>
                    )): 
                        <tr>
                            <td colSpan="9" style={{ textAlign : 'center'}} > No Invoice found! </td>
                        </tr>}
                </tbody>
            </table>

            <h1 className="pageTitle">Invoices</h1>
            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th scope="col">id</th>
                        <th scope="col">Paid Ammount</th>
                        <th scope="col">Currency</th>
                        <th scope="col">Email</th>
                        <th scope="col">Period Start</th>
                        <th scope="col">Period End</th>
                        <th scope="col" className="edit-Coulumn">View Invoice</th>
                        <th scope="col" className="cancel-Coulumn">Download Invoice</th>
                    </tr>
                </thead>
                <tbody>  
                    { billingData.invoices ? billingData.invoices.map(( list, index )=>(
                        <tr key={index}>
                            <td> { index+1 }</td>
                            <td >{ list.amount_paid }</td>
                            <td >{ list.currency }</td>
                            <td >{ list.customer_email }</td>
                            <td>{ new Date(parseInt(list.period_start) * 1000).toDateString() } </td>
                            <td>{ new Date( parseInt(list.period_end) * 1000 ).toDateString() } </td>
                            <td >
                                <a href={`${list.hosted_invoice_url}`} rel="noopener noreferrer" target="_blank" className="btn btn-info">View Invoice</a>
                            </td>
                            
                            <td>
                                <a href={`${list.invoice_pdf}`} rel="noopener noreferrer" target="_blank" className="btn btn-info">Export Invoice</a>
                            </td>
                            
                        </tr>
                    )): 
                        <tr>
                            <td colSpan="9" style={{ textAlign : 'center'}} > No Invoice found! </td>
                        </tr>}
                </tbody>
            </table>
            </div>
        </div>
        );
    }
}

Billing.propTypes = {
    getBillingData : PropTypes.func.isRequired,
    editAddress: PropTypes.func.isRequired,
    therapist: PropTypes.object.isRequired,
    detachCard: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    therapist: state.therapist
});

export default connect(mapStateToProps,{ getBillingData, editAddress, detachCard })(withRouter(Billing));