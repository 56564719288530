import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { SuperAdminLogin } from '../../../store/actions/superAdminActions';
import Header from '../../common/Header';
import Footer from '../../common/Footer';
import Spinner from '../../common/Spinner';
import AlertMessage from '../../common/Alert';
import { redirectSuperAdminIfAlreadyLogin } from '../../common/lib';

class SuperAdmin extends React.Component{

    constructor(){
        super();
        this.state = {
            email : '',
            password : ''
        }
    }

    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    onSubmit = (e) =>{
        e.preventDefault();

        const data = {
            email : this.state.email,
            password : this.state.password
        }

        this.props.SuperAdminLogin(data, this.props.history);
    }


    componentDidMount() {
        redirectSuperAdminIfAlreadyLogin(this.props.superAdmin, this.props.history);
    }

    render(){
        const {email , password } = this.state;

        return (
        <div className="login-bg">
            <Spinner />
            <Header />
            <div className="bss-bg">
                <div className="container-fluid">
                    <div className="row bss-224-login">
                    
                       <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 bss-321">

                       <AlertMessage />  
                            <form onSubmit={this.onSubmit} method="POST" className="bss-323">
                                
                                <div className="bss-322">Super Admin Login</div>
                                
                                    <div className="form-group">
                                        <label htmlFor="email">Email</label>

                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            id="email"
                                            name="email"    
                                            value={email}
                                            onChange={this.onChange}
                                        />
                                    </div>
             
                                    <div className="form-group">

                                        <label htmlFor="password">Password</label>
                                        <input 
                                            type="password" 
                                            className="form-control" 
                                            id="password"
                                            name="password"
                                            value={password}
                                            onChange={this.onChange}    
                                        />
                                    </div>

                                    <button type="submit" 
                                    // style={{minWidth: "100%", height: 'auto', padding: '15px'}} 
                                    className="btn btn-primary bss-fm-bttn">Login</button>
                                <Link to={'#'} className="bss-325">Forget Password?</Link>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            
            <Footer />  
        </div>
        
        )
    }
}


SuperAdmin.propTypes = {
    SuperAdminLogin: PropTypes.func.isRequired,
    superAdminAuth : PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    superAdmin: state.superAdminAuth
});

    
export default connect(mapStateToProps, { SuperAdminLogin })(SuperAdmin);