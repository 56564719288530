import axios from 'axios';

import {
    SET_ERRORS,
    SET_CATEGORIES,
    SET_LISTING,
    PAGE_LOADED,
    PAGE_LOADING,
    SET_PROFILE,
    SET_KNOWLEDGEBASE,
    SET_SUCCESS
} from './types';

let backendServerURL = process.env.REACT_APP_API_URL;

export const setPageLoading = () => {
    return {
        type: PAGE_LOADING
    };
};

export const clearPageLoading = () => {
    return {
        type: PAGE_LOADED
    };
};




export const getFilters = () => dispatch => {
    dispatch(setPageLoading());

    const hostname = window.location.hostname

    axios
        .get(
            backendServerURL + '/get-filters/' + hostname
        )
        .then(res => {
            dispatch({ type: SET_CATEGORIES, payload: res.data });

        })
        .catch(err => {
            dispatch({ type: SET_ERRORS, payload: err.response.data });
        })
        .finally(() => dispatch(clearPageLoading()));
};

export const searchResults = (fetchData) => dispatch => {
    dispatch(setPageLoading());

    axios
        .post(
            backendServerURL + '/search-results',
            fetchData
        )
        .then(res => {
            dispatch({ type: SET_LISTING, payload: res.data })

        })
        .catch(err => dispatch({ type: SET_ERRORS, payload: err.response.data }))
        .finally(() => dispatch(clearPageLoading()));

};

export const getProfile = (id) => dispatch => {
    dispatch(setPageLoading());

    axios
        .get(
            backendServerURL + '/profile/' + id,
        )
        .then(res => {
            dispatch({ type: SET_PROFILE, payload: res.data })

        })
        .catch(err => dispatch({ type: SET_ERRORS, payload: err.response.data }))
        .finally(() => dispatch(clearPageLoading()));

};

export const clearProfile = () => dispatch => {
    dispatch({ type: SET_PROFILE, payload: {} })
}


export const contactUs = (data) => dispatch => {

    dispatch(setPageLoading());

    return axios
        .post(
            backendServerURL + '/contact-us',
            data
        )
        .then(res => {
            dispatch({ type: SET_PROFILE, payload: res.data })
            dispatch({ type: SET_SUCCESS, payload: 'Your Message Sent Successfully' })

            return true;
        })
        .catch(err => {
            dispatch({ type: SET_ERRORS, payload: err.response.data.message })
            return false;
        })
        .finally(() => dispatch(clearPageLoading()));
}

export const getKnowledgebase = (data) => dispatch => {

    dispatch(setPageLoading());
    axios
        .post(
            backendServerURL + '/Knowledgebase',
            data
        )
        .then(res => {
            dispatch({ type: SET_KNOWLEDGEBASE, payload: res.data })

        })
        .catch(err => dispatch({ type: SET_ERRORS, payload: err.response.data.message }))
        .finally(() => dispatch(clearPageLoading()));
}


export const sendEmail = (data) => dispatch => {

    dispatch(setPageLoading());
    return axios
        .post(
            backendServerURL + '/send-email',
            data
        )
        .then(res => {
            dispatch({ type: SET_SUCCESS, payload: 'EMAIL HAS SENT' })
        })
        .catch(err => dispatch({ type: SET_ERRORS, payload: err.response.data.message }))
        .finally(() => dispatch(clearPageLoading()));
}