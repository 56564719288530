import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Spinner from '../../common/Spinner';
import { getNotifications } from '../../../store/actions/therapistActions';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';



class Notifications extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            notifications: []
        }

    }

    static getDerivedStateFromProps(props, state) {

        let { loading, notifications } = props.therapist;

        let changedState = {};
        let stateChanged = false;

        if (notifications && JSON.stringify(state.notifications) !== JSON.stringify(notifications)) {
            changedState.notifications = notifications;
            stateChanged = true;
        }

        if (loading && JSON.stringify(state.loading) !== JSON.stringify(loading)) {
            changedState.loading = loading;
            stateChanged = true;
        }

        if (stateChanged) {
            return changedState;
        }

        return null;
    }

    componentDidMount() {
        this.props.getNotifications();
    }



    render() {
        const { loading, notifications } = this.state;
        let pageContent = '';
        if (loading) {
            pageContent = <Spinner />
        }
        else if (notifications.length) {
            pageContent = <div className="accordion" id="accordionExample">
                <Accordion>
                    {notifications.map((item, index) => (
                        <Card key={index}>
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey={`${index}`}>
                                    {item.header}
                                    <i className="fa fa-caret-down" aria-hidden="true"></i>
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={`${index}`}>
                                <Card.Body>{item.body}</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    ))}
                </Accordion>
            </div>
        }

        return (
            <div className="helpCenterFormContainer">
                <h1 className="pageTitle">Notifications</h1>

                { pageContent}

            </div>
        )
    }

}


Notifications.propTypes = {
    getNotifications: PropTypes.func.isRequired,
    therapist: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    therapist: state.therapist
});

export default connect(mapStateToProps, { getNotifications })(withRouter(Notifications));