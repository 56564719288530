const data = [
{
    id: "dashboards",
    icon: "iconsminds-shop-4",
    label: "Dashboards",
    to: "/admin/dashboard",
},
{
    id: "support-tickets",
    icon: "simple-icon-envelope-letter",
    label: "Support Tickets",
    to: "/admin/support-ticket",
},
{
    id: "accounts",
    icon: "simple-icon-people",
    label: "Accounts",
    to: "/admin/accounts",
},
{
    id: "categories",
    icon: "iconsminds-box-with-folders",
    label: "Categories",
    to: "/admin/categories",
},
{
    id: "questions",
    icon: "simple-icon-pin",
    label: "Questions",
    to: "/admin/questions",
}
];
export default data;