import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Nav, NavItem } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { withRouter } from 'react-router-dom';

import {
  addContainerClassname,
  changeDefaultClassnames,
  changeSelectedMenuHasSubItems
} from '../../store/actions/menuActions';

import menuItems from './menuItems';

class Sidebar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedParentMenu: '',
            viewingParentMenu: '',
            collapsedMenus: []
        };
    }

    setSelectedLiActive = callback => {
        const oldli = document.querySelector('.sub-menu  li.active');

        if (oldli != null) {
            oldli.classList.remove('active');
        }

        const oldliSub = document.querySelector('.third-level-menu  li.active');
        if (oldliSub != null) {
            oldliSub.classList.remove('active');
        }

        /* set selected parent menu */
        const selectedSublink = document.querySelector('.third-level-menu  a.active');
        if (selectedSublink != null) {
            selectedSublink.parentElement.classList.add('active');
        }

        const selectedlink = document.querySelector('.sub-menu  a.active');
        if (selectedlink != null) {
            selectedlink.parentElement.classList.add('active');
        this.setState(
            {
            selectedParentMenu: selectedlink.parentElement.parentElement.getAttribute(
                'data-parent'
            )
            },
            callback
        );
        } else {
        var selectedParentNoSubItem = document.querySelector(
            '.main-menu  li a.active'
        );
        if (selectedParentNoSubItem != null) {
            this.setState(
            {
                selectedParentMenu: selectedParentNoSubItem.getAttribute(
                'data-flag'
                )
            },
            callback
            );
        } else if (this.state.selectedParentMenu === '') {
            this.setState(
            {
                selectedParentMenu: menuItems[0].id
            },
            callback
            );
        }
        }
    };

    handleWindowResize = event => {
        if (event && !event.isTrusted) {
          return;
        }


        const { menuHiddenBreakpoint } = this.props;
        // let nextClasses = classes.split(' ').filter(x => x !== '');

        const windowWidth = window.innerWidth;
        if (windowWidth < menuHiddenBreakpoint) {
            this.props.closeMenu()
        } else {
            this.props.openMenu()
        }
    };

    componentDidUpdate(prevProps) {
        
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.setSelectedLiActive(this.setHasSubItemStatus);
            window.scrollTo(0, 0);
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleWindowResize);
        this.handleWindowResize();

        this.setSelectedLiActive(this.setHasSubItemStatus);
    }

    componentWillUnmount(){

        window.removeEventListener('resize', this.handleWindowResize);

    }

    render() {
        const {
            selectedParentMenu,
            viewingParentMenu,
        } = this.state;

        return (
        <div className="sidebar">
            <div className="main-menu">
            <div className="scroll">
                <PerfectScrollbar
                    options={{ suppressScrollX: true, wheelPropagation: false }}
                >

                <Nav vertical className="list-unstyled">
                    {menuItems &&
                    menuItems.map(item => {
                        return (
                        <NavItem
                            key={item.id}
                            className={classnames({
                            active:
                                (selectedParentMenu === item.id &&
                                viewingParentMenu === '') ||
                                viewingParentMenu === item.id
                            })}
                        >
                            <NavLink
                                to={item.to}
                                data-flag={item.id}
                            >
                                <i className={item.icon} />{' '}
                                {item.label}
                            </NavLink>
                        </NavItem>
                        );
                    })}
                </Nav>
                </PerfectScrollbar>
            </div>
            </div>
        </div>
        );
    }
}

const mapStateToProps = ({ menu }) => {
const {
    containerClassnames,
    subHiddenBreakpoint,
    menuHiddenBreakpoint,
    selectedMenuHasSubItems
} = menu;

return {
    containerClassnames,
    subHiddenBreakpoint,
    menuHiddenBreakpoint,
    selectedMenuHasSubItems
};
};

export default withRouter(
connect(
    mapStateToProps,
    {
        addContainerClassname,
        changeDefaultClassnames,
        changeSelectedMenuHasSubItems
    }
)(Sidebar)
);
