import publicReducer from './publicReducer';
import authReducer from './authReducer';
import therapistReducer from './therapistReducer';
import adminRuducer from './adminAuthReducer';
import adminPageRuducer from './adminReducer';
import notificationReducer from './notificationReducer';
import superAdminAuthReducer from './superAdminAuthReducer';
import { combineReducers } from 'redux';
import superAdminReducer from './superAdminReducer';
import menuReducer from './menuReducer';

export default combineReducers({
	public: publicReducer,
	auth: authReducer,
	therapist: therapistReducer,
	admin: adminRuducer,
	adminPage: adminPageRuducer,
	superAdminAuth: superAdminAuthReducer,
	superAdmin: superAdminReducer,
	notification: notificationReducer,
	menu: menuReducer
});