
import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import RoundImg from '../../../assets/roundTherapists.png';
import Checkmark from '../../../assets/checkmark.png';
import { CardElement, Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { Link } from 'react-router-dom';


class Payment extends React.Component {

    handleSubmit = async (event, stripe, elements) => {
        // Block native form submission.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        console.log('elements', elements)
        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardElement = elements.getElement(CardElement);

        // Use your card Element with other Stripe.js APIs
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });

        if (error) {
            console.log('[error]', error);
        } else {
            this.props.onSubmit(paymentMethod.id, "false")
            //createSubscription({ paymentMethodId: paymentMethod.id });
        }
    };

    useCard = (id) => {
        this.props.onSubmit(id, "true")
    }

    render() {
        const { onChange, paymentData, subScription, paymentId, togglePayment, interval, paymentLocation, changeLocation } = this.props;
        const stripePromise = loadStripe(paymentData.stripePublicKey);
        console.log('interval', interval)
        return (
            <>
                <div className="tierContainerParent">
                    {paymentData.prices && paymentData.prices.map(item => (

                        <div key={item.id} className="tierContainer">
                            <div className="tierContent">
                                <h2 className="tierTitle">Tier 1</h2>
                                {item.id === subScription && <i className="fa fa-check mb-0" aria-hidden="true"></i>}
                                <p className="tierPText">Placeholder Text</p>
                                <p className="tierPText">Placeholder Text</p>
                                <p className="tierPText">Placeholder Text</p>
                                <img alt="" src={RoundImg} className="w-100 roundImg" />
                            </div>
                            <div className="tierBodyContent text-center">
                                <div className="monthlyPrice text-center">
                                    ${item.unit_amount / 100} / Per {item.recurring.interval}
                                </div>
                                <div className="tierCheckText">
                                    <p className="tierExampleText">
                                        <img alt="" src={Checkmark} className="checkmarkImg" /> Line Example 1</p>
                                    <p className="tierExampleText">
                                        <img alt="" src={Checkmark} className="checkmarkImg" /> Line Example 1</p>
                                    <p className="tierExampleText">
                                        <img alt="" src={Checkmark} className="checkmarkImg" /> Line Example 1</p>
                                    <button
                                        type="button"
                                        className="freeTrialBtn text-white border-0 w-100"
                                        onClick={() => onChange(item.id, item.recurring.interval)}
                                    >Start Free Trail</button>
                                </div>
                            </div>
                        </div>

                    ))}
                </div>

                { subScription && !paymentId &&
                    <div style={{ float: 'right', width: '100%' }}>
                        <div className="listing-container-content">
                            <div className="my-listing-Container">
                                <h1 className="pageTitle">Payment Methods</h1>
                                {paymentData.cards.length ? <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th scope="col">id</th>
                                            <th scope="col">Brand</th>
                                            <th scope="col">Country</th>
                                            <th scope="col">Last 4</th>
                                            <th scope="col">Type</th>
                                            <th scope="col">Use Card</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {paymentData.cards ? paymentData.cards.map((card, index) => (
                                            <tr key={index}>
                                                <td> {index + 1}</td>
                                                <td >{card.card.brand}</td>
                                                <td >{card.card.country}</td>
                                                <td >{card.card.last4}</td>
                                                <td >{card.type}</td>
                                                <td>
                                                    <Link onClick={() => this.useCard(card.id)} to="#" className="btn btn-info">Use Card</Link>
                                                </td>
                                            </tr>
                                        )) :
                                            <tr>
                                                <td colSpan="9" style={{ textAlign: 'center' }} > No Invoice found! </td>
                                            </tr>}
                                    </tbody>
                                </table> : <h2>No previous payment is available</h2>}
                            </div>
                            <Link onClick={() => onChange(subScription, interval, true)} style={{ marginLeft: '8.5%', color: '#484a7d', fontWeight: 'bold' }}>Add New Card</Link>
                        </div>
                    </div>
                }

                { subScription && !paymentId && togglePayment &&
                    <div className="billingContainer">
                        <div className="form-group">

                            <Elements stripe={stripePromise}>
                                <ElementsConsumer>
                                    {({ stripe, elements }) => (

                                        <form id="payment-form" onSubmit={(event) => this.handleSubmit(event, stripe, elements)}>
                                            <h2 className="form-heading">Payment</h2>
                                            <div>
                                                <div className="row listingFormFields">
                                                    <div className="col-md-12 col-lg-6 d-flex justify-content-between align-items-baseline">
                                                        <label htmlFor={"address,"}>Street Address</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Street Address"
                                                            name={"address"}
                                                            value={paymentLocation.address}
                                                            onChange={changeLocation}
                                                            required
                                                        />
                                                    </div>

                                                    <div className="col-md-12 col-lg-6 d-flex justify-content-between align-items-baseline">
                                                        <label htmlFor={"city,"} >City</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="City"
                                                            name={"city"}
                                                            value={paymentLocation.city}
                                                            onChange={changeLocation}
                                                            required
                                                        />
                                                    </div>


                                                    <div className="row listingFormFields">
                                                        <div className="col-md-12 col-lg-6 d-flex justify-content-between align-items-baseline">
                                                            <label htmlFor={"state,"} >State</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="State"
                                                                name={"state"}
                                                                value={paymentLocation.state}
                                                                onChange={changeLocation}
                                                                required
                                                            />
                                                        </div>

                                                        <div className="col-md-12 col-lg-6 d-flex justify-content-between align-items-baseline">
                                                            <label htmlFor={"zipCode,"} >Zip Code</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Zip Code"
                                                                name={"zipCode"}
                                                                value={paymentLocation.zipCode}
                                                                onChange={changeLocation}
                                                                required
                                                            />
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="flex flex-wrap -mx-3 mb-3">
                                                <div className="w-full px-3 mb-0">
                                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                                        Card
                            </label>
                                                    <div
                                                        className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded-md py-3 px-2 leading-tight focus:outline-none focus:bg-white"
                                                        id="card-element"
                                                    >
                                                        <CardElement
                                                            options={{
                                                                style: {
                                                                    base: {
                                                                        fontSize: '16px',
                                                                        color: '#32325d',
                                                                        fontFamily:
                                                                            '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif',
                                                                        '::placeholder': {
                                                                            color: '#a0aec0',
                                                                        },
                                                                    },
                                                                    invalid: {
                                                                        color: '#9e2146',
                                                                    },
                                                                },
                                                            }}
                                                        />
                                                    </div>
                                                    <div
                                                        id="card-element-errors"
                                                        className="text-gray-700 text-base mt-2"
                                                        role="alert"
                                                    ></div>
                                                </div>
                                            </div>

                                            <button type="submit" className="NextBtn">Next</button>
                                        </form>
                                    )}
                                </ElementsConsumer>
                            </Elements>

                        </div>
                    </div>
                }



            </>
        )
    }
}

export default Payment;