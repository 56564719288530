import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import FindConnections from './FindConnections';
import MyConnections from './MyConnections';
import { withRouter } from 'react-router-dom';

class Connections extends React.Component {

    static getDerivedStateFromProps(props, state) {

        if (window.location.href.indexOf("/listings") > -1 && props.hist && props.match.path !== props.hist.path) {
            props.changematch();
            props.history.push(props.hist.path);
        }

        return null
    }

    render() {

        return (
            <div className="support-ticket-page connection-page">
                <h1 className="pageMainTitle">Connections</h1>
                <div className="support-ticket">

                    <Tabs
                        selectedTabClassName="active"
                    >
                        <TabList className="support-tabs nav nav-tabs">
                            <Tab className="nav-link">My Connections</Tab>
                            <Tab className="nav-link">Find Connection</Tab>
                        </TabList>

                        <TabPanel>
                            <MyConnections />
                        </TabPanel>

                        <TabPanel>
                            <FindConnections />
                        </TabPanel>

                    </Tabs>
                </div>
            </div>
        )
    }
}

export default withRouter(Connections);