import React from 'react';
import { withRouter } from 'react-router-dom';
import {connect} from 'react-redux';
import { getSingleKnowledgebaseData, updateKnowledgebaseDetail } from '../../../store/actions/adminActions';
import { Formik, Form, Field,  } from "formik";
import { Row, Card, CardBody, FormGroup, Label, Button } from "reactstrap";
import { Colxx, Separator } from "../../theme/CustomBootstrap";

class AdminEditQuestion extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            singleKnowledgebase: {},
            id: 0, 
            question: '',
            position : '',
            answer: ''
        };
    }

    static getDerivedStateFromProps(props, state) {

        if(window.location.href.indexOf("/questions") > -1 && props.hist && props.match.path !== props.hist.path  ){
            props.changematch();
            props.history.push(props.hist.path);
        }

        let singleKnowledgebase = props.singleKnowledgebase ? props.singleKnowledgebase : {};
        
        let stateChanged = false;
        let changedState = {};
        
        if(singleKnowledgebase && JSON.stringify(state.singleKnowledgebase) !== JSON.stringify(singleKnowledgebase)){
            changedState.singleKnowledgebase= singleKnowledgebase;
            changedState.question= singleKnowledgebase.question;
            changedState.position= singleKnowledgebase.position;
            changedState.answer= singleKnowledgebase.answer;
            stateChanged = true; 
        }

        if(stateChanged){
            return changedState;
        }
        
        
        return null;
    }
      

    componentDidMount() {
        const id = parseInt(this.props.match.params.id);
        this.setState({id: id});

        this.props.onGetSingleKnowledgebase(id);
    }


    onChangeHandler = ( e ) => {

        this.setState({
            [e.target.name]: e.target.value
        });     
    }

    handleSubmit = (e) => {
        // e.preventDefault();
        const {id, question, position, answer } = this.state;
        const formData = {
            id:id,
            question, 
            position:  parseInt(position),
            answer
        };
        this.props.onUpdateKnowledgebase(formData, this.props.history);
    }
      


    render() {
        const { question, position, answer } = this.state;

        return(
            <React.Fragment>
        <Row>
            <Colxx xxs="12">
                <h1 className="h1-theme">Edit Knowledgebase</h1>
                <Separator className="mb-5" />
            </Colxx>
        </Row>

        <Row className="mb-4">
            <Colxx lg="8" md="12">
            <Card>
                <CardBody>
                
                <Formik
                    initialValues={{
                        name: "",
                        email: ""
                    }}
                    onSubmit={this.handleSubmit}>

                    {({ errors, touched }) => (
                    <Form className="av-tooltip tooltip-label-right">
                        <Label className="label-theme">Question</Label>

                        <FormGroup>
                        
                        <Field
                            className="form-control-theme"
                            name="question"
                            value={question || ''}
                            onChange={ this.onChangeHandler }
                            placeholder="Enter Question" 
                            rows="2"
                            validate={this.validateName}
                        />

                        {errors.name && touched.name && (
                            <div className="invalid-feedback d-block">
                            {errors.name}
                            </div>
                        )}
                        </FormGroup>

                        <Label className="label-theme">Position</Label>

                        <FormGroup>
                        
                        <Field
                            className="form-control-theme"
                            name="position"
                            value={position || ''}
                            onChange={this.onChangeHandler }
                            placeholder="Enter Question"
                        />
                        
                        {errors.name && touched.name && (
                            <div className="invalid-feedback d-block">
                            {errors.name}
                            </div>
                        )}
                        </FormGroup>

                        
                        <Label className="label-theme">Answer</Label>
                        <FormGroup>
                        <Field
                            className="form-control-theme"
                            name="answer"
                            component="textarea"
                            validate={this.validateEmail}
                            onChange={this.onChangeHandler }
                            placeholder="Enter Answer" 
                            value={answer || ''}
                            rows="6"
                        />
                        {errors.email && touched.email && (
                            <div className="invalid-feedback d-block">
                            {errors.email}
                            </div>
                        )}
                        </FormGroup>

                        <Button color="primary" type="submit">
                            Submit
                        </Button>
                    </Form>
                    )}
                </Formik>
                </CardBody>
            </Card>
            </Colxx>
        </Row>
    </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
		singleKnowledgebase: state.adminPage.singleKnowledgebase,
});
  
const mapDispatchToProps = dispatch => {
	return {
        onGetSingleKnowledgebase: (id) => dispatch(getSingleKnowledgebaseData(id)),
        onUpdateKnowledgebase: (formData, history) => dispatch(updateKnowledgebaseDetail(formData, history)),
		
}
};
  
  
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AdminEditQuestion));




