import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {withRouter , Link} from 'react-router-dom';
import { login } from '../../store/actions/authActions';
import Header from '../common/Header';
import Footer from '../common/Footer';
import AlertMessage from '../common/Alert';
import { redirectDashboardIfAlreadyLogin } from '../common/lib';
import Spinner from '../common/Spinner';

class Login extends React.Component{

    constructor(){
        super();
        this.state = {
            email : '',
            password : ''
        }
    } 

    onChange = e => {
        this.setState({[e.target.name]: e.target.value});
    }

    componentDidMount() {
        redirectDashboardIfAlreadyLogin(this.props.auth, this.props.history);
    }

    onSubmit = (e) =>{
        e.preventDefault();

        const data = {
            email : this.state.email,
            password : this.state.password,
            hostName :  window.location.hostname
        }

        this.props.login(data, this.props.history);
    }

    render(){
        const { email, password } = this.state;

        return (
        <>
        
        <div className="login-bg">
            <Spinner />
            <Header />
            <div className="bss-bg">
                <div className="container-fluid">
                    <div className="row bss-224-login">
                    
                       <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 bss-321">
                                 
                            <AlertMessage />  

                            <form onSubmit={this.onSubmit} className="bss-323">
                                
                                <div className="bss-322">Login</div>
                                
                                    <div className="form-group">
                                        <label htmlFor="email">Email</label>

                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            id="email"
                                            name="email"    
                                            value={email}
                                            onChange={this.onChange}
                                        />
                                    </div>

                                    <div className="form-group">

                                        <label htmlFor="password">Password</label>
                                        <input 
                                            type="password" 
                                            className="form-control" 
                                            id="password"
                                            name="password"
                                            value={password}
                                            onChange={this.onChange}    
                                        />
                                    </div>

                                    <button type="submit" className="btn btn-primary bss-fm-bttn">Login</button>
                                    
                                    <Link to={'/forget-password'} className="bss-325">Forget Password?</Link>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            
            <Footer />  
        </div>
        
        </>
        )
    }
}


Login.propTypes = {
    login: PropTypes.func.isRequired,
    auth : PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps,{ login })(withRouter(Login));