import React from 'react';
import Header from './Header';
import NavBar from './NavBar';
import Routes from './Routes';
import Spinner from '../common/Spinner';
import AlertMessages from '../common/Alert';

class Index extends React.Component{
   
    render(){
        return (
        <div className="bg-color position-fixed overflow-auto">
            <Spinner />

            <Header />
            <NavBar />
            
            <AlertMessages />
            <Routes />

        </div>
    )
    } 
}

export default Index;