import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import { getKnowledgebase } from '../../../store/actions/publicActions';


class CommonQuestions extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            knowledgebase: []
        };
    } 

    componentDidMount() {

        const data ={
            offset : 0,
            limit : 10
        }

        this.props.getKnowledgebase(data);
    }


    render(){
        const { knowledgebase }  = this.props.public;

    return(
        <div className="container commonQuestionSection SelctBoxSection">
            <h1 className="questionTitle">Common Question</h1>
                
            <Accordion>
                { knowledgebase && knowledgebase.map( (item,index) => (
                    <Card key={index}>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey={`${index}`}>
                                { item.question }
                                <i className="fa fa-caret-down" aria-hidden="true"></i>

                            </Accordion.Toggle>
                        </Card.Header>
                        
                        <Accordion.Collapse eventKey={`${index}`}>
                            <Card.Body>{ item.answer }</Card.Body>
                        </Accordion.Collapse>
                    </Card>
                ))}
            </Accordion>
        </div>
    )
    }
}

CommonQuestions.propTypes = {
    getKnowledgebase: PropTypes.func.isRequired,
    public: PropTypes.object.isRequired
};


const mapStateToProps = state => ({
    public: state.public,
});

export default connect(mapStateToProps, { getKnowledgebase })(CommonQuestions);