import React, { Component, Fragment } from "react";
import {
    Pagination, PaginationItem, PaginationLink,
    UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem
} from "reactstrap";

export default class DataTablePagination extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: props.page,
            pageSize: this.props.defaultPageSize
        }

    }

    static getDerivedStateFromProps(props, state) {

        return { page: props.page };
    }

    getSafePage = (page) => {
        if (Number.isNaN(page)) {
            page = this.props.page;
        }

        return Math.min(Math.max(page, 0), this.props.pages - 1);
    }

    changePageSize = (size) => {
        this.props.onPageSizeChange(size);
        this.setState({ pageSize: size });
    }

    changePage = (page) => {
        // page = this.getSafePage(page);

        this.setState({ page });

        if (this.props.currentPage !== page) {
            this.props.onPageChange(page);
        }
    }

    applyPage = (e) => {
        if (e) {
            e.preventDefault();
        }

        const page = this.state.page;
        this.changePage(page === "" ? this.props.page : page);
    }

    pageClick = (pageIndex) => {
        this.changePage(pageIndex);
    }

    renderPages = () => {

        const { totalPages } = this.props;
        let endPage = this.props.totalPages;
        let currentPage = this.props.currentPage;
        let startPage = 0;
        let maxSize = this.props.paginationMaxSize;

        if (maxSize) {
            if (endPage > maxSize) {
                startPage = Math.max( currentPage + 1 - Math.floor(maxSize / 2), 1);

                endPage = startPage + maxSize - 1;

                if (endPage > totalPages) {
                    endPage = totalPages;
                    startPage = endPage - maxSize + 1;
                }

                startPage -= 1;
            }
        }

        let pageButtons = [];
        for (let i = startPage; i < endPage; i++) {
            const active = currentPage === i ? true : false;
            pageButtons.push(
                <PaginationItem key={i} active={active}>
                    <PaginationLink
                        onClick={() => this.pageClick(i)}
                    >
                        {i + 1}
                    </PaginationLink>
                </PaginationItem>
            );
        }

        return pageButtons;
    }

    renderPageJump = () => {
        let pages = this.props.totalPages;

        let pageNumbers = [];

        for (let i = 0; i < pages; i++) {
            pageNumbers.push(
                <DropdownItem
                    key={i}
                    onClick={() => this.changePage(i)}
                >
                    {i + 1}
                </DropdownItem>
            );
        }

        return pageNumbers;
    }

    render() {
        const {
            page,
            pages,
            currentPage,
            pageSizeOptions,
            showPageSizeOptions,
            showPageJump,
            totalPages
        } = this.props;

    const canPrevious = currentPage > 0;
    const canNext = totalPages > currentPage + 1;

    return (
    <Fragment>
    <div className="text-center">
        { showPageJump &&
        <div className="float-left pt-2"><span>Page </span>
            <UncontrolledDropdown className="d-inline-block">
                <DropdownToggle caret color="outline-primary" size="xs">
                    { currentPage + 1 }
                </DropdownToggle>

                <DropdownMenu direction="left" >
                    {this.renderPageJump()}
                </DropdownMenu>
            </UncontrolledDropdown>
            <span> of </span>
            {pages}
        </div>
        }

        <Pagination className="d-inline-block" size="sm" listClassName="justify-content-center" aria-label="Page navigation example">
            <PaginationItem className={`${!canPrevious && "disabled"}`}>
                <PaginationLink
                    className={"prev"}
                    onClick={() => {
                        if (!canPrevious) return;
                        this.changePage(page);
                        }
                    }

                    disabled={!canPrevious}>

                    <i className="simple-icon-arrow-left" />
                </PaginationLink>
            </PaginationItem>

            {this.renderPages()}

            <PaginationItem className={`${!canNext && "disabled"}`}>
                <PaginationLink
                    className="next"
                    onClick={() => {
                        if (!canNext) return;
                        this.changePage(page + 1);
                        }
                    }
                    disabled={!canNext}
                >
                    <i className="simple-icon-arrow-right" />
                </PaginationLink>
            </PaginationItem>
        </Pagination>

    { showPageSizeOptions &&
    <div className="float-right pt-2">
        <span className="text-muted text-small mr-1">Items </span>
        <UncontrolledDropdown className="d-inline-block">
            <DropdownToggle caret color="outline-primary" size="xs">
                {this.state.pageSize}
            </DropdownToggle>

            <DropdownMenu right>
                {pageSizeOptions.map((size, index) => {
                return (
                    <DropdownItem
                        key={index}
                        onClick={() => this.changePageSize(size)}
                    >
                        {size}
                    </DropdownItem>
                );
                })}
            </DropdownMenu>
        </UncontrolledDropdown>
        </div>
        }
    </div>
    </Fragment>
    );
    }
}
