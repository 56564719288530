import React from 'react';
import {BrowserRouter as Router , Route,  withRouter} from 'react-router-dom';
 
import Dashboard from './dashboard';
import MyListings from './myListings';
import NewListing from './newListing';
import RenewSubscription from './myListings/renewSubscription'
import Billing from './billing';
import Account from './account';
import HelpCenter from './helpCenter';
import EditListing from './editListing'
import Notification from './notifications';
import ListingConnections from './myListings/connections';
import TransferList from './myListings/transferList';

class Routes extends React.Component {
	constructor(props){
		super(props);
		
		this.state = {
			match : ''
		}
	}

	componentWillReceiveProps(nextProps) {
		this.setState({ match: nextProps.match } );	
	}

	changematch = () =>{
		this.setState({match: ''});
	}

	render(){
		const { match } = this.state;
		return (
			<Router >
				
				<Route  
					exact 
					path={"/therapist/dashboard"} 
					component={
						() => <Dashboard />
					}
				/>

                <Route  
					exact 
					path={"/therapist/listings"} 
					component={
						() => <MyListings />
					}
				/>


                <Route  
					exact 
					path={"/therapist/listing/add"} 
					component={
						() => <NewListing />
					}
				/>

				<Route  
					exact 
					path={"/therapist/listing/edit/:id"} 
					component={
						() => <EditListing 
							hist={ match }
							changematch={this.changematch} 
						/>
					}
				/>

				<Route  
					exact 
					path={"/therapist/connections/:id"} 
					component={
						() => <ListingConnections 
							hist={ match }
							changematch={this.changematch} 
						/>
					}
				/>

				<Route  
					exact 
					path={"/therapist/transfer/:id"} 
					component={
						() => <TransferList 
							hist={ match }
							changematch={this.changematch} 
						/>
					}
				/>

                <Route  
					exact 
					path={"/therapist/billing"} 
					component={
						() => <Billing />
					}
				/>

                <Route  
					exact 
					path={"/therapist/account"} 
					component={
						() => <Account />
					}
				/>

				<Route  
					exact 
					path={"/therapist/notifications"} 
					component={
						() => <Notification />
					}
				/>

				<Route  
					exact 
					path={"/therapist/help-center"} 
					component={
						() => <HelpCenter />
					}
				/>

				<Route  
					exact 
					path={"/therapist/renew-subscription/:id"} 
					component={
						() => <RenewSubscription />
					}
				/>


			</Router>
		);
	}
}

export default withRouter(Routes);