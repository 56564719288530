import React, { Component, Fragment } from 'react';
import { Row } from 'reactstrap';
import { Colxx, Separator } from '../../theme/CustomBootstrap';
import IconCard from "../../theme/cards/IconCard";
import {connect} from 'react-redux';
import { getDashboardData } from '../../../store/actions/adminActions';

class Admindashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dashbaordData: {}
        };
    }

    static getDerivedStateFromProps(props, state) {

        let dashbaordData = props.dashbaordData ? props.dashbaordData : {};

        let stateChanged = false;
        let changedState = {};
        
        
        if(dashbaordData && JSON.stringify(state.dashbaordData) !== JSON.stringify(dashbaordData)){
            changedState.dashbaordData= dashbaordData;
            stateChanged = true;
        }
      
      
        if(stateChanged){
            return changedState;
        }
      
        return null;
    }
      
    componentDidMount() {
        this.props.onGetDashboardData();
    }

    render() {
        const { activeListing , inActiveListing , accounts, openSupportTickets, closedSupportTickets } = this.state.dashbaordData;

    return (
        <Fragment>
        <Row>
            <Colxx xxs="12">
                <h1 className="h1-theme">Dashboard</h1>
                <Separator className="mb-5" />
            </Colxx>
        </Row>

        <Row>
            <Colxx xl="12">
                <div className="icon-cards-row">
                {/* <GlideComponent settings={
                    {
                    gap: 6,
                    perView: 6,
                    type: "carousel",
                    breakpoints: {
                        320: { perView: 1 },
                        576: { perView: 2 },
                        1600: { perView: 3 },
                        1800: { perView: 4 },
                        2000: { perView: 5 }
                    },
                    hideNav: true
                    }
                }> */}
                    
                <IconCard title="Active Listings" value={ activeListing } icon="iconsminds-monitor---phone" className="mb-4"/>
                <IconCard title="Inactive Listings" value={ inActiveListing } icon="iconsminds-loading-2" className="mb-4"/>
                <IconCard title="Accounts" value={ accounts } icon="simple-icon-people" className="mb-4"/>
                <IconCard title="YTD Revenue" value={ 1200 } icon="iconsminds-bank" className="mb-4"/>
                <IconCard title="Open Support Tickets" value={ openSupportTickets } icon="iconsminds-mail-with-cursors" className="mb-4"/>
                <IconCard title="Close Support Tickets" value={ closedSupportTickets } icon="iconsminds-mail-with-cursors" className="mb-4"/>
                
                {/* </GlideComponent> */}

                
                </div>
            </Colxx>
        </Row>
        
        </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        dashbaordData: state.adminPage.dashbaordData
    }
  };
  
  const mapDispatchToProps = dispatch => {
    return {
        onGetDashboardData: () => dispatch(getDashboardData()),

    }
};    

  
export default connect(mapStateToProps, mapDispatchToProps)(Admindashboard);
