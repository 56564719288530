import React from 'react';
// import { connect } from 'react-redux';
// import { withRouter } from 'react-router-dom';
import Header from './Header';
import Sidebar from './sidebar';
// import NavBar from './Navbar';
import Routes from './Routes';
import NotificationContainer from '../theme/react-notifications/NotificationContainer';

import Spinner from '../common/Spinner';
// import AlertMessage from '../common/Alert';


// import 'react-image-lightbox/style.css';
// import "video.js/dist/video-js.css";



class AdminDashboard extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = {
            menuOpen : true
        };
    }

    toggleMenu = () =>{

        this.setState((prevState)=>({
            menuOpen : !prevState.menuOpen
        }))
    }

    openMenu = () =>{
        this.setState({
            menuOpen : true
        })
    }

    closeMenu = () =>{
        this.setState({
            menuOpen : false
        })
    }

    render() {

        return(
            <div className="h-100">
                <div id="app-container" className={[!this.state.menuOpen && 'main-hidden sub-hidden ', ' menu-default sub-hidden']}>
                <Spinner />
                
                <Header toggleMenu={this.toggleMenu} />
                <Sidebar closeMenu={this.closeMenu} openMenu={this.openMenu} />

                <main>
                    <div className="container-fluid">
                        <Routes />
                    </div>
                </main>

                <NotificationContainer />

                {/* <NavBar history={this.props.history} />
                
                <AlertMessage />
                
                 */}

            </div>
            </div>
        )
    }

}
  
export default AdminDashboard;

// const mapStateToProps = ({ menu }) => {
//     const { containerClassnames } = menu;
//     return { containerClassnames };
// };
// const mapActionToProps = {}
  
// export default withRouter(connect(
//     mapStateToProps,
//     mapActionToProps
// )());
  