import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import { clearNotification } from '../../store/actions/authActions'
import Alert from 'react-bootstrap/Alert';

class AlertMessage extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            notification : {}
        }
    }

    static getDerivedStateFromProps(props, state) {
        const { notification } = props;

        if( JSON.stringify(state.notification) !== JSON.stringify(notification)){
            return {
                notification : notification
            } 
        }

        return null;
    }

    componentWillUnmount(){
        this.props.clearNotification();
    }

    render() {
        const { notification } = this.state
        return(
        <>
        { notification.message ? <Alert variant={notification.variant}>
            <Alert.Heading> 
                <i className={[ 'fa ' , notification.variant === 'success' ? ' fa-check-circle' : ' fa-exclamation-circle' ]} aria-hidden="true">
                </i>
                    { notification.message }
            </Alert.Heading>
        </Alert> : '' }
        </>
        )
    }  
}

AlertMessage.propTypes = {
    clearNotification: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    notification: state.notification
});

export default connect(mapStateToProps,{ clearNotification })(AlertMessage);