import React from 'react';
import {connect} from 'react-redux';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom'; 
import { getApplication, editApplication } from '../../../store/actions/superAdminActions';
import ApplicationForm from './ApplicationForm';

class EditApplication extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loaded : false,
            application_name: '',
            domain: '',
            stripe_publishable_key	: '',
            stripe_secret_key	: '',
            email_template_id: '',
        };
    }

    static getDerivedStateFromProps(props, state) {

        if(window.location.href.indexOf("/applications") > -1 && props.hist && props.match.path !== props.hist.path  ){
            props.changematch();
            props.history.push(props.hist.path);
        }

		let { application } = props.superAdmin;
	  
	  
		if(!state.loaded && application.application_id ){
            return { 
                ...application,
                loaded : true,
            }   
        }
        
        return null;
	  
    }
    
    onChange = ( e ) => {
        
        this.setState({
            [e.target.name]: e.target.value
        });     
    }

    componentDidMount(){
        this.props.getApplication(this.props.match.params.id)
    }

    onSubmitHandler = (e) => {
        e.preventDefault();
        const { application_id, application_name, domain, stripe_publishable_key,stripe_secret_key,
            ticket_templete, ticket_response_templete, forget_password_templete, therapist_email_templete } = this.state;

        const formData = {
            application_id,
            application_name,
            domain,
            stripe_publishable_key,
            stripe_secret_key,
            ticket_templete,
            ticket_response_templete,
            forget_password_templete,
            therapist_email_templete
        };


        this.props.editApplication(formData, this.props.history);
    } 
      


    render() {
        return(
        <div className="settings-page">
            <h1 className="pageMainTitle">Edit Application</h1>
            <div className="settings-page-content">
                <ApplicationForm  state={this.state} onSubmit={this.onSubmitHandler} onChange={this.onChange} />
            </div>
        </div>
        )
    }
}


EditApplication.propTypes = {
    getApplication: PropTypes.func.isRequired,
    editApplication: PropTypes.func.isRequired,
    superAdmin: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    superAdmin: state.superAdmin
});
  
export default connect(mapStateToProps, {  getApplication, editApplication  })(withRouter(EditApplication));
