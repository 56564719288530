import React from 'react';


function AccountList({ state, onChange, onSubmit }){
    return (
    <form className="accountDetailForm" onSubmit={onSubmit }>
        <div className="form-group">
            <div>
                <h2 className="form-heading" style={{ color: 'red' }}>No Account With This Email Found Create New</h2>
            </div>

            <div className="form-row">
                <div className="form-group col-md-12 col-lg-6 bss-f01">
                    <label htmlFor="firstName">First Name</label>
                    <input 
                        type="text" 
                        className="form-control"
                        id="firstName" 
                        name="firstName"
                        value={state.firstName}
                        onChange={onChange} 
                    />
                </div>
                <div className="form-group col-md-12 col-lg-6 bss-f02">
                    <label htmlFor="lastName">Last Name</label>
                    <input 
                        type="text" 
                        className="form-control" 
                        id="lastName"
                        name="lastName"
                        value={state.lastName}
                        onChange={onChange} 
                    />
                </div>
            </div>
            <div className="form-group">
                <label htmlFor="displayName">Display Name</label>
                <input 
                    type="text" 
                    className="form-control"
                    id="displayName"
                    name="displayName"
                    value={state.displayName}
                    onChange={onChange}    
                />
            </div>
            <div className="form-group">
                <label htmlFor="email">Email</label>
                <input 
                    type="email" 
                    className="form-control" 
                    id="email"
                    name="email"
                    value={state.email}
                    onChange={onChange}    
                />
            </div>
            <div className="form-group">
                <label htmlFor="password">Password</label>
                <input 
                    type="password" 
                    className="form-control" 
                    id="password"
                    name="password"
                    value={state.password}
                    onChange={onChange}    
                />
            </div>
  
            <button type="submit" className="btn btn-primary">Transfer</button>
        </div>
    </form>
    )
}

export default AccountList;