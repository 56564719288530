import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { getDashboard } from '../../../store/actions/therapistActions';


class dashboard extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            dashboard: {}
        }

    }


    static getDerivedStateFromProps(props, state) {

        let { loading, dashboard } = props.therapist;

        let changedState = {};
        let stateChanged = false;

        if (dashboard && JSON.stringify(state.dashboard) !== JSON.stringify(dashboard)) {
            changedState.dashboard = dashboard;
            stateChanged = true;
        }

        if (loading && JSON.stringify(state.loading) !== JSON.stringify(loading)) {
            changedState.loading = loading;
            stateChanged = true;
        }

        if (stateChanged) {
            return changedState;
        }

        return null;
    }

    componentDidMount() {
        this.props.getDashboard();
    }


    render() {
        const { dashboard } = this.state;

        return (
            <div className="container boxes-container">
                <div className="titleMessage">
                    <h1>Hello, AK Therapy!</h1>
                </div>
                <div className="row">
                    <div className="col-sm-6 col-md-6 col-lg-3">
                        <div className="boxes bx-001 d-flex justify-content-between">
                            <div>
                                <h2>{dashboard.activeListings} </h2>
                                <p>Active Listings</p>
                            </div>
                            <div>
                                <i className="fa fa-window-maximize" aria-hidden="true" />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-3">
                        <div className="boxes bx-003 d-flex justify-content-between">
                            <div>
                                <h2>{dashboard.inActiveListings}</h2>
                                <p>In-Active Listings</p>
                            </div>
                            <div>
                                <i className="fa fa-braille" aria-hidden="true" />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-3">
                        <div className="boxes bx-004 d-flex justify-content-between">
                            <div>
                                <h2>{dashboard.totalVisits}</h2>
                                <p>Total Visit</p>
                            </div>
                            <div>
                                <i className="fa fa-handshake-o" aria-hidden="true" />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-3">
                        <div className="boxes bx-005 d-flex justify-content-between">
                            <div>
                                <h2>
                                    {dashboard.upcomingDate ?
                                        moment(dashboard.upcomingDate).format("MM/DD/YY")
                                        : <span>-</span>
                                    }
                                </h2>
                                <p>Upcoming billing Date</p>
                            </div>
                            <div>
                                <i className="fa fa-credit-card" aria-hidden="true" />
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        )
    }
}

dashboard.propTypes = {
    getDashboard: PropTypes.func.isRequired,
    therapist: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    therapist: state.therapist
});

export default connect(mapStateToProps, { getDashboard })(withRouter(dashboard));