import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter} from 'react-router-dom';
import { findConnections , createConnection } from '../../../../store/actions/therapistActions';
import Spinner from '../../../common/Spinner';
import { Modal } from 'react-responsive-modal';
let backendServerURL = process.env.REACT_APP_API_URL;

class FindConnections extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            showModel : false,
            listId : '',
            find : '', 
            connections : {},
            endorsment: ''
        }
    }

    openModel = (id) =>{
        this.setState({
            listId : id,
            showModel : true
        })
    }

    closeModal = () =>{

        this.setState({
            listId : '',
            endorsment : '',
            showModel: false
        })
    }

    sendConnection = ()=>{

        const Data = {
            receiverId : this.state.listId,
            text : this.state.endorsment
        }

        this.props.createConnection(Data);

        this.setState({
            showModel : false,
            endorsment : '',
            listId : ''
        })
    }

    static getDerivedStateFromProps(props, state) {

        const {  findConnections , loading }  = props.therapist;

        let changedState = {};
        let stateChanged = false;

        if(JSON.stringify(state.loading) !==JSON.stringify(loading)){
            changedState.loading = loading;
            stateChanged = true;  
        }

        if(findConnections && JSON.stringify(state.connections) !== JSON.stringify(findConnections)){
            changedState.connections = findConnections;
            stateChanged = true;
        }

        if(stateChanged){
            return changedState;
        }

        return null;
    }

    componentDidMount(){
        
        this.props.findConnections('all');
    }

    onChange = e => {
        this.setState({[e.target.name]: e.target.value});
    }

    findResult = () =>{
        if(this.state.find !== '')
            this.props.findConnections(this.state.find);
        else
            this.props.findConnections('all');
    }

    render() {

        const { loading , connections } = this.state;
        
        let pageContent = '';

        if(loading){
            pageContent = <Spinner />
        }

        else if(connections.length){
            pageContent =<tbody>  
                { connections.map(( list, index )=>(
                    <tr key={index}>
                        <td >
                            { list.listing_id }
                        </td>
                        <td>
                        { list.image ?
                            <img src={ `${backendServerURL}/images/${list.image}` } height={40} width={40} alt="DPCircle" />
                            : <img src={ `${backendServerURL}/images/undefined.png` } height={40} width={40} alt="DPCircle" /> }
                        </td>
                        <td>{ list.practice_name }</td>
                        <td>
                            <button onClick={ ()=>this.openModel(list.listing_id) } type="button" className="btn btn-primary" >
                                Request
                            </button>
                        </td>        
                    </tr>
                ))}
            </tbody>
        }

    return (
    <div className="tab-content support-table" id="myTabContent"> 
        <div className="tab-pane fade show active" id="profile" role="tabpanel" aria-labelledby="profile-tab">
            <div className="input-group add-on">
                <input 
                    className="form-control" 
                    placeholder="Search" 
                    name="find" 
                    id="srch-term" 
                    type="text" 
                    onChange={ this.onChange}
                    value={this.state.find}
                />

                <div className="input-group-btn">
                    <button onClick={ this.findResult} className="btn btn-default" type="submit">
                        <i className="fa fa-search" aria-hidden="true"></i>
                    </button>
                </div>
            </div>
            
            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th scope="col" className="connection-page-id-column">ID</th>
                        <th scope="col">Image</th>
                        <th scope="col">Name</th>
                        <th scope="col">Actions</th> 
                    </tr>
                </thead>
                    { pageContent }
            </table>

            { this.state.showModel && <Modal open={ true } onClose={this.closeModal} center>
                
                    <div className="modal-dialog modal-dialog-scrollable" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalScrollableTitle">Endorsment</h5>
                            <button onClick={this.closeModal} type="button" className="close" >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <textarea
                                name="endorsment"
                                value={ this.state.endorsment }
                                onChange={this.onChange}

                            ></textarea>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={this.closeModal} >Close</button>
                            <button type="button" className="btn btn-primary" onClick={this.sendConnection}>Save changes</button>
                        </div>
                    </div>
                    </div>
                
            </Modal> }

        </div>
    </div>
    )
    }
}

FindConnections.propTypes = {
    findConnections: PropTypes.func.isRequired,
    createConnection: PropTypes.func.isRequired,
    therapist: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    therapist: state.therapist
});

export default connect(mapStateToProps,{ findConnections , createConnection })(withRouter(FindConnections));