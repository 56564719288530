import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { sendRecoveryEmail } from '../../store/actions/authActions';
import Header from '../common/Header';
import Footer from '../common/Footer';
import AlertMessage from '../common/Alert';
import { redirectDashboardIfAlreadyLogin } from '../common/lib';
import Spinner from '../common/Spinner';

class ForgetPassword extends React.Component{

    constructor(){
        super();
        this.state = {
            email : ''
        }
    } 

    onChange = e => {
        this.setState({[e.target.name]: e.target.value});
    }

    componentDidMount() {
        redirectDashboardIfAlreadyLogin(this.props.auth, this.props.history);
    }

    onSubmit = (e) =>{
        e.preventDefault();

        const data = {
            email : this.state.email
        }

        this.props.sendRecoveryEmail(data);
    }

    render(){
        const { email } = this.state;

        return (
        <>
        <div className="login-bg">
            <Spinner />
            <Header />
            <div className="bss-bg">
                <div className="container-fluid">
                    <div className="row bss-224-login">
                    
                       <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 bss-321">
                                 
                            <AlertMessage />  

                            <form onSubmit={this.onSubmit} className="bss-323">
                                
                                <div className="bss-322">Recovery Email</div>
                                    <div className="form-group">
                                        <label htmlFor="email">Email</label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            id="email"
                                            name="email"    
                                            value={email}
                                            onChange={this.onChange}
                                        />
                                    </div>

                                    <button type="submit" className="btn btn-primary bss-fm-bttn">Send Email</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            
            <Footer />  
        </div>
        </>
        )
    }
}


ForgetPassword.propTypes = {
    sendRecoveryEmail: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps,{ sendRecoveryEmail })(withRouter(ForgetPassword));