import { 
	SET_ERRORS,
	SET_SUCCESS, 
	CLEAR_NOTIFICATION 
} from '../actions/types';

const initialState = {};

export default function(state = initialState, action) {
	switch (action.type) {
		case SET_ERRORS:
			return {
				message : action.payload,
				variant : 'danger'
			}
			
		case SET_SUCCESS:
			return {
				message : action.payload,
				variant : 'success'
			}

		case CLEAR_NOTIFICATION:
			return {}

		default:
			return state;
	}
}
