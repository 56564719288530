import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { getApplications, applicationStatusHandler} from '../../../store/actions/superAdminActions';

class SuperAdminDashboard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            applications: {}
        };
    }

    static getDerivedStateFromProps(props, state) {

        if(window.location.href.indexOf("/applications") > -1 && props.hist && props.match.path !== props.hist.path  ){
            props.changematch();
            props.history.push(props.hist.path);
        }

        let { applications } = props.superAdmin;
        
        if(applications && JSON.stringify(state.applications) !== JSON.stringify(applications)){
            return{
                applications : applications
            }
        } 
        
        return null
    }
      
    componentDidMount() {
        this.props.getApplications();
    }
      
    applicationStatusHandler = ( id, status ) => {
        const formData = {
            application_id: id,
            is_active: !status
        }

        this.props.applicationStatusHandler( formData );
    }

    render() {

        const { applications } = this.state;

    return(
    <div className="listing-container-content">
        <div className="add-application-btnContainer">
            <Link to={"/super-admin/application/add"}>
                <button type="button" className="add-category-Btn">Add Application</button>
            </Link>
        </div>

        <div className="my-listing-Container super-admin-table-container">
            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th scope="col">id</th>
                        <th scope="col">Name</th>
                        <th scope="col">Domain</th>
                        <th scope="col">Stripe Publish Key</th>
                        <th scope="col" style={{width: 245, minWidth: 235 }}>Actions</th>
                    </tr>
                </thead>
                <tbody>  
                    { applications.length ? applications.map(( app, index )=>(
                        <tr key={index}>
                            <td> { app.application_id }</td>
                            <td >{ app.application_name }</td>
                            <td >{ app.domain }</td>
                            <td >{ app.stripe_publishable_key }</td>
                            <td>
                                <button type="button" onClick={() => this.applicationStatusHandler( app.application_id, app.is_active ) }  className={"btn btn-primary enable-disable-btn-info "+ ( app.is_active === false ? "enable" : "disable" ) }> {app.is_active === false ? "Enable" : "Disable"}  </button>
                                <Link to={`/super-admin/application/edit/${app.application_id}`}className="btn btn-info edit-btn-info">EDIT</Link>
                            </td>
                        </tr>
                    )): 
                        <tr>
                            <td colSpan="6" style={{ textAlign : 'center'}} > No data found! </td>
                        </tr>}
                </tbody>
            </table>
        </div>
    </div>
    )
    }
}


SuperAdminDashboard.propTypes = {
    getApplications: PropTypes.func.isRequired,
    applicationStatusHandler: PropTypes.func.isRequired,
    superAdmin: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    superAdmin: state.superAdmin
});

  
export default connect(mapStateToProps, { getApplications, applicationStatusHandler })(withRouter(SuperAdminDashboard));
