
import { 
	SET_SUPER_ADMIN_APPLICATIONS,
	SET_SUPER_ADMIN_APPLICATION,
	EDIT_SUPER_ADMIN_APPLICATION,
	CLEAR_APPLICTION,
	SET_SUPER_ADMIN_USER,
	SET_SUPER_ADMIN_USERS,
	SUPER_ADMIN_DELETE_USER
 } from '../actions/types';

const initialState = {
	applications : [],
	application : {},
	users : [],
	user : {}
};

export default function(state = initialState, action) {
	switch (action.type) {
		
		case SET_SUPER_ADMIN_APPLICATIONS:
			return {
				...state,
				applications : action.payload
			};

		case SET_SUPER_ADMIN_APPLICATION:
			return {
				...state,
				application : action.payload
			};

		case CLEAR_APPLICTION:
			return{
				...state,
				application: {}
			}
		
		case EDIT_SUPER_ADMIN_APPLICATION:
		{
			return {
				...state,
				applications : state.applications.map((item) => (
                    item.application_id === action.payload.application_id ? action.payload : item
                ))
			};
		}
		
		case SET_SUPER_ADMIN_USERS:
			return {
				...state,
				users : action.payload
			};

		case SET_SUPER_ADMIN_USER:
			return {
				...state,
				user : action.payload
			};
		case SUPER_ADMIN_DELETE_USER:
			return {
				...state,
				users : state.users.filter( item => item.application_login_id !== action.payload)
			}
		default:
			return state;
	}
}