import React from 'react';
import {connect} from 'react-redux';
import { withRouter } from 'react-router-dom'; 
import { addApplication } from '../../../store/actions/superAdminActions';

class SuperDashboardSettings extends React.Component {

    render() {
        const { application_name, domain, 
            stripe_publishable_key, stripe_secret_key,
            ticket_templete, ticket_response_templete, forget_password_templete, therapist_email_templete
        } = this.props.state;
        const { onChange,  onSubmit} = this.props;

        console.log('this.porps', this.props);

        return(
        <form onSubmit={ onSubmit } >
            <div className="form-group">
                <label htmlFor="application_name">Application Name</label>
                <input 
                    type="text" 
                    className="form-control" 
                    id="application_name" 
                    name='application_name'
                    value={ application_name }
                    onChange={onChange }
                    placeholder="Enter Application name"
                    required
                />
            </div>

            <div className="form-group">
                <label htmlFor="domain">Application main domain</label>
                <input 
                    type="text" 
                    className="form-control" 
                    id="domain" 
                    name='domain'
                    value={ domain }
                    onChange={onChange }
                    placeholder="Enter Application domain name"  
                    aria-describedby="Applicationmaindomain" 
                    required
                />
            </div>

            <div className="form-group">
                <label htmlFor="stripe_publishable_key">Stripe Publish keys</label>
                <input 
                    type="text" 
                    className="form-control" 
                    id="stripe_publishable_key" 
                    name='stripe_publishable_key'
                    value={ stripe_publishable_key }
                    onChange={onChange}
                    placeholder="Enter Stripe keys"
                    aria-describedby="Stripekeys" 
                    required
                />
            </div>

            <div className="form-group">
                <label htmlFor="stripe_secret_key">Stripe Secret keys</label>
                <input 
                    type="text" 
                    className="form-control" 
                    id="stripe_secret_key" 
                    name='stripe_secret_key'
                    value={ stripe_secret_key }
                    onChange={onChange}
                    placeholder="Enter Stripe keys"
                    aria-describedby="Stripekeys" 
                    required
                />
            </div>

            <div className="form-group">
                <label htmlFor="ticket_templete">Postmark Ticket Template Id</label>
                <input 
                    type="text" 
                    className="form-control" 
                    id="ticket_templete" 
                    name='ticket_templete'
                    value={ticket_templete}
                    onChange={ onChange }
                    placeholder="Enter template_id"
                    required 
                />
            </div>

            <div className="form-group">
                <label htmlFor="ticket_response_templete">Postmark Ticket Response Template Id</label>
                <input 
                    type="text" 
                    className="form-control" 
                    id="ticket_response_templete" 
                    name='ticket_response_templete'
                    value={ticket_response_templete}
                    onChange={ onChange }
                    placeholder="Enter Ticket Response Template Id"
                    required 
                />
            </div>

            <div className="form-group">
                <label htmlFor="forget_password_templete">Postmark Forget Password Template Id</label>
                <input 
                    type="text" 
                    className="form-control" 
                    id="forget_password_templete" 
                    name='forget_password_templete'
                    value={forget_password_templete}
                    onChange={ onChange }
                    placeholder="Enter Forget Password Template Id"
                    required 
                />
            </div>

            <div className="form-group">
                <label htmlFor="therapist_email_templete">Postmark Therapist Email Template Id</label>
                <input 
                    type="text" 
                    className="form-control" 
                    id="therapist_email_templete" 
                    name='therapist_email_templete'
                    value={therapist_email_templete}
                    onChange={ onChange }
                    placeholder="Enter Therapist Email Template Id"
                    required 
                />
            </div>

            <button type="submit" className="btn btn-primary">Submit</button>
        </form>
        )
    }
}
  
export default connect(null, {  addApplication })(withRouter(SuperDashboardSettings));