import React from 'react';
import Header from './Header';
import Routes from './Routes';
import Spinner from '../common/Spinner';
import AlertMessage from '../common/Alert';


class SuperAdminDashboard extends React.Component {

    render() {
        
        return(
            <div className="bg-color overflow-auto">
                <Spinner />
    
                <Header />
                <AlertMessage />
                <Routes />
                
            </div>
        )
    }

}
  
export default SuperAdminDashboard;

