
export const redirectDashboardIfAlreadyLogin = (auth, history) =>{
    if (auth && auth.isAuthenticated ) {
        history.push(`/therapist/dashboard`);
        return true;
    }
    return false;
}

export const redirectSuperAdminIfAlreadyLogin  = (superAdmin, history) =>{
    if (superAdmin && superAdmin.isAuthenticated ) {
        history.push(`/super-admin/`);
        return true;
    }
    
    return false;
}