import React,{ Fragment } from 'react';
import DataTablePagination from "../../theme/DatatablePagination";
import {connect} from 'react-redux';
import { getSupportTickets, saveTicketResponse } from '../../../store/actions/adminActions';
import {
	Row,
	Card,
	CardBody,
	Nav,
	NavItem,
	Modal,
	ModalBody,
	ModalFooter,
	TabContent,
	TabPane,
	Button
} from "reactstrap";
import {
	AvGroup,
	AvInput,
	AvFeedback,
	AvForm
} from "availity-reactstrap-validation";

import { NavLink } from "react-router-dom";
import classnames from "classnames";
import { Colxx , Separator } from "../../theme/CustomBootstrap";
import ReactTable from "react-table";

class SuperDashboardSupportTickets extends React.Component {
	constructor(props) {
        super(props);
        this.state = {
			contactTickets: {},
			accountTickets: {},
			limit: 10,
			offset1: 0,
			offset2: 0,
			tabIndex: 0,
			response: '',
			ticketId : 0,
			openModel : false
		};
		
		this.tab1Columns = [
			{
                Header: "Name",
                accessor: "name",
                Cell: props => <p className="p-theme text-muted">{props.value}</p>
            },{
                Header: "Email",
                accessor: "email",
                Cell: props => <p className="p-theme text-muted">{props.value}</p>
            },{
                Header: "Comment",
                accessor: "comment",
                Cell: props => <p className="p-theme text-muted">{props.value}</p>
            },{
                Header: "Created At",
                accessor: "created_date",
                Cell: props => <p className="p-theme text-muted">{props.value}</p>
			},{
                Header: "Closed At",
                accessor: "closed_date",
                Cell: props => <p className="p-theme text-muted">{props.value}</p>
			},{
                Header: "Reply",
				Cell: ({ original }) => 
					<Button color="primary" disabled={original.closed_date ? true : false} onClick={()=> this.ModelHandler(true, original.comment, original.support_tickets_id  ) }>
						Reply
					</Button>
			}
		]

		this.tab2Columns = [
			{
                Header: "Name",
                accessor: "account",
                Cell: props => <>
					<p className="p-theme text-muted">{ props.value.display_name }</p>
				</>
            },{
                Header: "Email",
                accessor: "email",
                Cell: props => <p className="p-theme text-muted">{props.value}</p>
            },{
                Header: "Comment",
                accessor: "comment",
                Cell: props => <p className="p-theme text-muted">{props.value}</p>
            },{
                Header: "Created At",
                accessor: "created_date",
                Cell: props => <p className="p-theme text-muted">{props.value}</p>
			},{
                Header: "Closed At",
                accessor: "closed_date",
                Cell: props => <p className="p-theme text-muted">{props.value}</p>
			},{
                Header: "Reply",
                Cell: ({ original }) => 
					<Button color="primary" disabled={original.closed_date ? true : false } onClick={()=> this.ModelHandler(true, original.comment, original.support_tickets_id  ) } >
						Reply
					</Button>
			}
		]
	}

	ModelHandler = (openModel, question, ticketId  ) =>{

		this.setState({
			openModel,
			question,
			ticketId, 

		})
	}
	
	static getDerivedStateFromProps(props, state) {

		let contactTickets = props.contactTickets ? props.contactTickets : {};
		let accountTickets = props.accountTickets ? props.accountTickets : {};
	  
	  
		let stateChanged = false;
		let changedState = {};
	  
		if(contactTickets && JSON.stringify(state.contactTickets) !== JSON.stringify(contactTickets)){
			changedState.contactTickets= contactTickets;
			stateChanged = true; 
		}
	  
		if(accountTickets && JSON.stringify(state.accountTickets) !== JSON.stringify(accountTickets)){
			changedState.accountTickets= accountTickets;
			stateChanged = true;    
		}

		if(stateChanged){
			return changedState;
		}
	  
	  
		return null;
	  }
	  
	  componentDidMount() {
		  const {offset1, limit} = this.state;
		  const formData = {
			  offset: offset1,
			  limit: limit,
			  type: "contact"
		}
		this.props.onGetSupportTickets(formData);
	}


	pagnationHandler = (offset, limit) => {

		if(this.state.tabIndex === 0){

			this.setState({
				offset1: offset
			},() => {
				  const formData = {
					  offset:offset,
					  limit: limit,
					  type: 'contact'
				};
				this.props.onGetSupportTickets(formData);
			}
			);
		}else{
			this.setState({
				offset2: offset
			  },() => {
				const formData = {
					offset:offset,
					limit: limit,
					type: 'account'
				};
				this.props.onGetSupportTickets(formData);
			}
			);
		}
		
	  }

	  tabHandler = (index) => {
			this.setState({
				tabIndex: index
			});
			const { offset1, offset2, limit} = this.state;
			const formData = {
				offset: index === 0 ? offset1 : offset2,
				limit: limit,
				type: index === 0 ? 'contact' : 'account'
			};
			this.props.onGetSupportTickets(formData);
	}

	onChangeHandler = (e) => {
		const targetValue = e.target.value;
		this.setState({
			response: targetValue
		});
	}

	onSaveHandler = () => {
		const { response, ticketId, tabIndex } = this.state;

		const formData = {
			Id: ticketId,
			response: response
		};

		const type = tabIndex === 0 ? 'contact' :  'account';

		this.setState({
			response: '',
			ticketId : 0,
			openModel : false
		}, () => this.props.onSaveResponse(formData, type) );
		
	} 
	  
	render(){
		const { tabIndex, contactTickets, accountTickets, offset1, offset2, limit, openModel , question} = this.state;

		let totalPages;

		if(tabIndex === 0 && contactTickets && contactTickets.count){
			totalPages = Math.ceil(contactTickets.count/limit);
		}
		else if(tabIndex === 1 && accountTickets && accountTickets.count){
			totalPages = Math.ceil(accountTickets.count/limit);
		}

	return(
		<Fragment>
            <Row>

            <Colxx xxs="12">
                <h1 className="h1-theme">Support Tickets</h1>
                <Separator className="mb-5" />
            </Colxx>
            </Row>

			<Row>
			
            <Colxx xxs="12">

            <Card className="mb-4">
					
                <CardBody>
				<Nav tabs className="separator-tabs ml-0 mb-5">
						<NavItem>
							<NavLink className={classnames({ active: tabIndex === 0, "nav-link": true })}
							onClick={() => { this.tabHandler(0) }} to="#" location={{}}>
								Contact Form
							</NavLink>
						</NavItem>

						<NavItem>
							<NavLink
							className={classnames({ active: tabIndex === 1 , "nav-link": true })}
							onClick={() => { this.tabHandler(1); }}
							to="#" location={{}}>
								Account Ticket
							</NavLink>
						</NavItem>
					</Nav>
				<TabContent activeTab={tabIndex}>
              
					<TabPane tabId={0} >
						<ReactTable
							data={ contactTickets.rows && [...contactTickets.rows]}
							columns={ this.tab1Columns }
							paginationMaxSize={3}
							defaultPageSize={ limit }
							showPageJump={true}
							showPageSizeOptions={true}
							totalPages={totalPages}
							PaginationComponent={ DataTablePagination }
							className={"react-table-fixed-height"}
							currentPage={ offset1 }
							onPageChange={this.pagnationHandler}
						/>
					</TabPane>

					<TabPane tabId={1} >
						<ReactTable
							data={accountTickets.rows && [...accountTickets.rows]}
							columns={this.tab2Columns}
							paginationMaxSize={3}
							defaultPageSize={ limit }
							showPageJump={true}
							showPageSizeOptions={true}
							totalPages={totalPages}
							PaginationComponent={DataTablePagination}
							className={"react-table-fixed-height"}
							currentPage={ offset2 }
							onPageChange={ this.pagnationHandler }
						/>
					</TabPane>

				</TabContent>
                    
                </CardBody>
			</Card>
            </Colxx>
            
            </Row>

			<Modal isOpen={ openModel } toggle={ this.toggle }>
				<ModalBody>
					<h5 className="modal-title" id="exampleModalScrollableTitle2">Question: {question} ?</h5>
					
					<AvForm
						className="av-tooltip tooltip-label-right mt-4"
						onSubmit={this.handleSubmit}
					>
					<AvGroup>
						{/* <Label style={{ paddingLeft : 0 }}>Details</Label> */}
						<AvInput type="textarea" name="details" id="details" onChange={ this.onChangeHandler} required />
						<AvFeedback>This field is required!</AvFeedback>
					</AvGroup>
					</AvForm>
					

				</ModalBody>
				<ModalFooter>
					
					<Button color="secondary" onClick={()=> this.ModelHandler(false, '', 0 ) }>
						Cancel
					</Button>{" "}
					<Button color="success" onClick={ this.onSaveHandler } >
						Submit
					</Button>
				</ModalFooter>
			</Modal>

        </Fragment>

		)



	}

    // render() {
	// 	const { tabIndex, contactTickets, accountTickets, offset1, offset2, limit } = this.state;
	// 	let totalPages = 0;

	// 	// console.log("Checking this.state: ", this.state);

	// 	if(tabIndex === 0 && contactTickets && contactTickets.count){
	// 		totalPages = Math.ceil(contactTickets.count/limit);
	// 	}
	// 	if(tabIndex === 1 && accountTickets && accountTickets.count){
	// 		totalPages = Math.ceil(accountTickets.count/limit);
	// 	}
	// 	// console.log("totalPages: ", totalPages);

	// 	let pagination=[];
	// 	for(let i=0; i<totalPages; i++){
	// 		pagination.push(<Link 
	// 			to={"#"} 
	// 			key={i} 
	// 			onClick={()=> this.pagnationHandler(i, limit)} 
	// 			className={tabIndex === 0 ? offset1 === i ? "activePage" : "" : 
	// 			tabIndex === 1 ? offset2 === i ? "activePage" : "" : "" }>{i+1}
	// 			</Link>);
	// 	}
		
  

    //     return(
 
	// 		<div className="support-ticket-page">
	// 	<h1 className="pageMainTitle">Support Tickets</h1>
	// 	<div className="support-ticket">

	// 		<ul className="support-tabs nav nav-tabs" id="myTab" role="tablist">
	// 			<li className="nav-item" onClick={() => this.tabHandler(0)}>
	// 				<div style={{cursor: 'anchor'}} className={"nav-link "+(tabIndex === 0 ? "active" : "")}>Contact Form</div>
	// 			</li>
	// 			<li className="nav-item" onClick={() => this.tabHandler(1)}>
	// 				<div style={{cursor: 'anchor'}} className={"nav-link "+(tabIndex === 1 ? "active" : "")}>Account Support</div>
	// 			</li>
	// 		</ul>
		
	// 		<div className="tab-content support-table">
				
	// 			<Tab1 
	// 			onSaveHandler={(id) => this.onSaveHandler(id)}
	// 			onChangeHandler={this.onChangeHandler} 
	// 			contactTickets={contactTickets} 
	// 			show={tabIndex === 0 ? true : false} 
	// 			/>
	// 			<Tab2 
	// 			onSaveHandler={(id) => this.onSaveHandler(id)}
	// 			onChangeHandler={this.onChangeHandler} 
	// 			accountTickets={accountTickets} 
	// 			show={tabIndex === 1 ? true : false} 
	// 			/>
	// 		</div>
			
	// 		<div className="paginationContent">
	// 			{tabIndex === 0  &&
	// 			<div>
	// 				{ offset1 > 0 ?
	// 					<i className={"fa fa-long-arrow-left custom-pagination "} onClick={()=> this.pagnationHandler(offset1-1, limit)} aria-hidden="true"></i>
	// 					:
	// 					<i className={"fa fa-long-arrow-left custom-pagination custom-disable"} aria-hidden="true"></i>

	// 				}
	// 			</div> 
	// 			}

	// 		{tabIndex === 1  &&
	// 			<div>
	// 				{ offset2 > 0 ?
	// 					<i className={"fa fa-long-arrow-left custom-pagination"} onClick={()=> this.pagnationHandler(offset2-1, limit)} aria-hidden="true"></i>
	// 					:
	// 					<i className={"fa fa-long-arrow-left custom-pagination custom-disable"} aria-hidden="true"></i>

	// 				}
	// 			</div> 
	// 			}
				

	// 			<div className="paginationNumbers">
	// 				{pagination}
	// 				{/* <Link to={"#"}>1</Link>
	// 				<Link to={"#"}>2</Link>
	// 				<Link to={"#"} className="activePage">3</Link>
	// 				<Link to={"#"}>4</Link>
	// 				<Link to={"#"}>5</Link>
	// 				<Link to={"#"}>6</Link>
	// 				<Link to={"#"}>7</Link> */}
	// 			</div>
				
	// 			{tabIndex === 0  &&
	// 			<div>
	// 				{totalPages > offset1+1 ? 
	// 				<i className={"fa fa-long-arrow-right custom-pagination "+ ( totalPages > offset1+1 ? "" : "custom-disable")} onClick={()=> this.pagnationHandler(offset1+1, limit)} aria-hidden="true"></i>
	// 				:
	// 				<i className={"fa fa-long-arrow-right custom-pagination custom-disable"}  aria-hidden="true"></i>
	// 			}
	// 			</div>
	// 			}

	// 			{tabIndex === 1  &&
	// 			<div>
	// 				{totalPages > offset2+1 ? 
	// 				<i className={"fa fa-long-arrow-right "+ ( totalPages > offset1+1 ? "" : "custom-disable")} onClick={()=> this.pagnationHandler(offset2+1, limit)} aria-hidden="true"></i>
	// 				:
	// 				<i className={"fa fa-long-arrow-right custom-disable"}  aria-hidden="true"></i>
	// 			}
	// 			</div>
	// 			}

	// 		</div>
		
	// 	</div>
	// </div>
		
    //     )
    // }
}

const mapStateToProps = state => {
	return {
		contactTickets: state.adminPage.contactTickets,
		accountTickets: state.adminPage.accountTickets,
	}
  };
  
  const mapDispatchToProps = dispatch => {
	return {
		onGetSupportTickets: (formData) => dispatch(getSupportTickets(formData)),
		onSaveResponse: (formData, type) => dispatch(saveTicketResponse(formData, type)),
	}
  };
  
  
  export default connect(mapStateToProps, mapDispatchToProps)(SuperDashboardSupportTickets);

