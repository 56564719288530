import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { getAccounts, transferAccount } from '../../../../store/actions/therapistActions';
import Spinner from '../../../common/Spinner';
import CreateNewAccount from './createNewAccount';
import AccountList from './AccountList';

class TransferList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            listId: '',
            find: '',
            account: {},
            firstName: '',
            lastName: '',
            displayName: '',
            email: '',
            password: '',
            loaded: false
        }
    }

    static getDerivedStateFromProps(props, state) {

        if (window.location.href.indexOf("/listings") > -1 && props.hist && props.match.path !== props.hist.path) {
            props.changematch();
            props.history.push(props.hist.path);
        }

        const { findConnections, loading } = props.therapist;

        let changedState = {};
        let stateChanged = false;

        if (JSON.stringify(state.loading) !== JSON.stringify(loading)) {
            changedState.loading = loading;
            stateChanged = true;
        }

        if (findConnections && JSON.stringify(state.accounts) !== JSON.stringify(findConnections)) {
            changedState.accounts = findConnections;
            changedState.email = state.find;
            changedState.find = '';
            stateChanged = true;
        }

        if (stateChanged) {
            return changedState;
        }

        return null;
    }

    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    findResult = () => {
        if (this.state.find !== '') {
            this.props.getAccounts(this.state.find);
            this.setState({
                loaded: true
            })
        }
    }

    transferAccount = (accountId) => {

        const data = {
            accountId,
            listingId: this.props.match.params.id,
            newAccount: false
        }

        this.props.transferAccount(data, this.props.history);
    }

    createAccount = (e) => {
        e.preventDefault();

        const data = {
            listingId: this.props.match.params.id,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            displayName: this.state.displayName,
            email: this.state.email,
            password: this.state.password,
            newAccount: true
        }

        this.props.transferAccount(data, this.props.history);
    }

    render() {

        const { loading, accounts, loaded } = this.state;

        let pageContent = '';
        console.log('accounts', accounts.length, 'loaded', loaded)
        if (loading) {
            pageContent = <Spinner />
        }
        else if (accounts.length) {
            pageContent = <AccountList accounts={accounts} transferAccount={this.transferAccount} />
        }
        else if (loaded) {
            pageContent = <CreateNewAccount state={this.state} onChange={this.onChange} onSubmit={this.createAccount} />
        }

        return (
            <div className="support-ticket-page connection-page">
                <h1 className="pageMainTitle">Transfer Listing</h1>
                <div className="support-ticket">
                    <div className="tab-content support-table" id="myTabContent">
                        <div className="tab-pane fade show active" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                            <div className="input-group add-on">
                                <input
                                    className="form-control"
                                    placeholder="Search"
                                    name="find"
                                    id="srch-term"
                                    type="text"
                                    onChange={this.onChange}
                                    value={this.state.find}
                                />

                                <div className="input-group-btn">
                                    <button onClick={this.findResult} className="btn btn-default" type="submit">
                                        <i className="fa fa-search" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>
                            {pageContent}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

TransferList.propTypes = {
    getAccounts: PropTypes.func.isRequired,
    transferAccount: PropTypes.func.isRequired,
    therapist: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    therapist: state.therapist
});

export default connect(mapStateToProps, { getAccounts, transferAccount })(withRouter(TransferList));