import {
    therapist_LOADING,
    therapist_LOADED,
    SET_DASHBOARD,
    SET_LISTINGS,
    SET_ACCOUNT_DETAILS,
    SET_LIST_CONNECTIONS,
    SET_FIND_CONNECTIONS,
    UPDATE_CONNECTION,
    DELETE_CONNECTION,
    UPDATE_LIST_SUBSCRIPTION,
    SET_BILLING,
    DELETE_PAYMENT_METHOD,
    SET_PAYMENT_DATA,
    SET_NOTIFICATION
} from '../actions/types';

const initialState = {
    loading: false,
    dashboard: {},
    listings: [],
    accountDetails: {},
    connections: [],
    findConnections: [],
    billing: {},
    paymentData: {},
    addNewListings: [],
    notifications: []
};

export default function (state = initialState, action) {
    switch (action.type) {

        case therapist_LOADING:
            return {
                ...state,
                loading: true
            };

        case therapist_LOADED:
            return {
                ...state,
                loading: false
            };

        case SET_DASHBOARD:
            return {
                ...state,
                dashboard: action.payload
            };

        case SET_LISTINGS:
            return {
                ...state,
                listings: action.payload
            };

        case SET_ACCOUNT_DETAILS:
            return {
                ...state,
                accountDetails: action.payload
            }

        case SET_LIST_CONNECTIONS:
            return {
                ...state,
                connections: action.payload
            }

        case SET_FIND_CONNECTIONS:
            return {
                ...state,
                findConnections: action.payload
            }

        case UPDATE_CONNECTION: {
            return {
                ...state,
                connections: state.connections.map((item) => (
                    item.network_connection_id === action.payload.connectionId ? { ...item, is_confirmed: action.payload.value } : item
                ))
            }
        }

        case UPDATE_LIST_SUBSCRIPTION: {
            return {
                ...state,
                listings: state.listings.map((item) => (
                    item.listing_id === action.payload.listing_id ? action.payload : item
                ))
            }
        }

        case DELETE_CONNECTION: {

            return {
                ...state,
                connections: state.connections.filter(item => item.network_connection_id !== action.payload)
            }
        }

        case SET_BILLING: {
            return {
                ...state,
                billing: action.payload
            }
        }

        case DELETE_PAYMENT_METHOD: {
            return {
                ...state,
                billing: {
                    ...state.billing,
                    cards: state.billing.cards.filter(item => item.id !== action.payload)
                }
            }
        }

        case SET_PAYMENT_DATA: {
            return {
                ...state,
                paymentData: action.payload
            }
        }

        case SET_NOTIFICATION: {
            return {
                ...state,
                notifications: action.payload
            }
        }

        default:
            return state;
    }
}