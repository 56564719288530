import React from 'react';
import ReactDOM from 'react-dom';

import './css/admin-theme.min.css'
import './css/bootstrap.min.css';
import './css/common.css';
import './css/contact-us.css';
import './css/for-therapist.css';
import './css/home.css';
import './css/login.css';
import './css/search-results.css';
import './css/signup.css';
import './css/therapist-profile.css';
import './css/account-detail.css';
import './css/custom.css';
import './css/help-center.css';
import './css/listing-location.css';
import './css/my-listing.css';
import './css/new-listing-general.css';
import './css/payment.css';
import './css/practice-details.css';
import './css/review.css';
import './css/add-category.css';
import './css/categories.css';
import './css/account.css';
import './css/support-ticket.css';
import './css/questions.css';
import './css/edit-question.css';
import './css/add-question.css';
// import './css/settings.css';
import "./css/react-table.css";



import "react-circular-progressbar/dist/styles.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import './scss/index.scss'

import App from './App';
import * as serviceWorker from './serviceWorker';



ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
