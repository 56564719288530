import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../../assets/logo.png';

class Header extends React.Component{

    render(){

    return (
    <header className="container full-width p-0">
        <nav className="navbar navbar-expand-md p-0">
            <div className="col-md-4 col-lg-3 header-logo">
                <Link to={"/"} className="navbar-brand">
                    <img src={ Logo } className="LogoImg" alt="LogoImg" />
                </Link>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                    <i className="fa fa-bars" aria-hidden="true"></i>
                </button>
            </div>

            <div className="col-md-8 col-lg-9 custom-padding-r nav-menus-container">
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ml-auto search-menu">
                        <li className="nav-item firstNav">
                            <Link to={"/search-results"} className="nav-link" >Find a Therapist</Link>
                        </li>
                        <li className="nav-item firstNav">
                            <Link to={"/for-therapist"} className="nav-link" >For Therapists</Link>
                        </li>
                        <li className="nav-item">
                            <Link to={"/contact-us"} className="nav-link" >Contact Us</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </header>
    )}
}

export default Header;