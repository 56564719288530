// ERRORS ACTIONS
export const SET_ERRORS = 'SET_ERRORS';
export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION';
export const SET_SUCCESS = 'SET_SUCCESS';
export const SET_NOTIFICATION = 'SET_NOTIFICATION'

//PUBLIC ACTIONS
export const SET_CATEGORIES = 'SET_CATEGORIES';
export const SET_LISTING = "SET_LISTING";
export const PAGE_LOADING = 'PAGE_LOADING';
export const PAGE_LOADED = 'PAGE_LOADED';
export const SET_PROFILE = 'SET_PROFILE';
export const SET_KNOWLEDGEBASE = 'SET_KNOWLEDGEBASE';

//Auth TYPES
export const SET_THERAPIST = 'SET_THERAPIST';
export const CLEAR_THERAPIST = 'CLEAR_THERAPIST';

//therapist Types
export const therapist_LOADING = 'therapist_LOADING';
export const therapist_LOADED = 'therapist_LOADED';
export const SET_DASHBOARD = 'SET_DASHBOARD';
export const SET_LISTINGS = 'SET_LISTINGS';
export const SET_ACCOUNT_DETAILS = 'SET_ACCOUNT_DETAILS';
export const SET_FIND_CONNECTIONS = 'SET_FIND_CONNECTIONS';
export const UPDATE_CONNECTION = 'UPDATE_CONNECTION';
export const DELETE_CONNECTION = 'DELETE_CONNECTION';
export const UPDATE_LIST_SUBSCRIPTION = 'UPDATE_LIST_SUBSCRIPTION';
export const SET_BILLING = 'SET_BILLING';
export const DELETE_PAYMENT_METHOD = 'DELETE_PAYMENT_METHOD';
export const SET_PAYMENT_DATA = 'SET_PAYMENT_DATA';

//SUPER ADMIN SECTION START
export const SET_SUPER_ADMIN = 'SET_SUPER_ADMIN';
export const CLEAR_SUPER_ADMIN = 'CLEAR_SUPER_ADMIN';
export const SET_SUPER_ADMIN_APPLICATIONS = 'SET_SUPER_ADMIN_APPLICATIONS';
export const SET_SUPER_ADMIN_APPLICATION = 'SET_SUPER_ADMIN_APPLICATION';
export const CLEAR_APPLICTION = 'CLEAR_APPLICTION';
export const EDIT_SUPER_ADMIN_APPLICATION = 'EDIT_SUPER_ADMIN_APPLICATION';
export const SET_SUPER_ADMIN_USER = 'SET_SUPER_ADMIN_USER';
export const SET_SUPER_ADMIN_USERS = 'SET_SUPER_ADMIN_USERS';
export const SUPER_ADMIN_DELETE_USER = 'SUPER_ADMIN_DELETE_USER';


//SUPER ADMIN AUTH SECTION START
export const SET_ADMIN = 'SET_ADMIN';
export const CLEAR_ADMIN = 'CLEAR_ADMIN';


//ADMIN PAGES SECTION START
export const SET_ADMIN_DASHBOARD_DATA = 'SET_ADMIN_DASHBOARD_DATA';
export const SET_ADMIN_CONTACT_TICKET_DATA = 'SET_ADMIN_CONTACT_TICKET_DATA';
export const SET_ADMIN_ACCOUNT_TICKET_DATA = 'SET_ADMIN_ACCOUNT_TICKET_DATA';
export const SET_ADMIN_SAVE_CONTACT_TICKET_RESPONSE = 'SET_ADMIN_SAVE_CONTACT_TICKET_RESPONSE';
export const SET_ADMIN_SAVE_ACCOUNT_TICKET_RESPONSE = 'SET_ADMIN_SAVE_ACCOUNT_TICKET_RESPONSE';

export const SET_ADMIN_ACCOUNTS_DATA = 'SET_ADMIN_ACCOUNTS_DATA';
export const SET_ADMIN_ACCOUNTS_STATUS_HANDLER = 'SET_ADMIN_ACCOUNTS_STATUS_HANDLER';

export const SET_ADMIN_SINGLE_CATEGORY = 'SET_ADMIN_SINGLE_CATEGORY';
export const SET_ADMIN_DELETE_CATEGORY = 'SET_ADMIN_DELETE_CATEGORY';
export const SET_ADMIN_CREATE_CATEGORY = 'SET_ADMIN_CREATE_CATEGORY';

export const SET_ADMIN_KNOWLEDGEBASES_DATA = 'SET_ADMIN_KNOWLEDGEBASES_DATA';
export const SET_ADMIN_SINGLE_KNOWLEDGEBASE = 'SET_ADMIN_SINGLE_KNOWLEDGEBASE';
export const SET_ADMIN_DELETE_KNOWLEDGEBASE = 'SET_ADMIN_DELETE_KNOWLEDGEBASE';

export const SET_ADMIN_SETTING = 'SET_ADMIN_SETTING';
export const SET_LIST_CONNECTIONS = 'SET_LIST_CONNECTIONS';

/* MENU */
export const MENU_SET_CLASSNAMES = "MENU_SET_CLASSNAMES";
export const MENU_CONTAINER_ADD_CLASSNAME = "MENU_CONTAINER_ADD_CLASSNAME";
export const MENU_CLICK_MOBILE_MENU = "MENU_CLICK_MOBILE_MENU";
export const MENU_CHANGE_DEFAULT_CLASSES = "MENU_CHANGE_DEFAULT_CLASSES";
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS = "MENU_CHANGE_HAS_SUB_ITEM_STATUS";