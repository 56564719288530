import axios from 'axios';
import setAuthToken from '../../utils/setAuthToken';
import jwt_decode from 'jwt-decode';

import {
    PAGE_LOADING,
    PAGE_LOADED,
    SET_ADMIN,
    CLEAR_ADMIN,
    SET_ERRORS,
} from './types';
 
let backendServerURL = process.env.REACT_APP_API_URL;
 let route = '/admin';     

export const setPageLoading = () => {
	return {
		type: PAGE_LOADING
	};
};

export const clearPageLoading = () => {
	return {
		type: PAGE_LOADED
	};
};


// Set logged in Superadmin (Verified)
export const setCurrentAdmin = decoded => {
    return {
        type: SET_ADMIN,
        payload: decoded
    };
};


export const setError = (msg) => {
	return {
        type: SET_ERRORS,
        payload: msg
    };
    
};

export const clearCurrentAdmin = () => {
    localStorage.removeItem('adminJwtToken');
    return {
		type: CLEAR_ADMIN
	};
};


// Log Super Admin out (Verified)
export const logoutAdmin = history => dispatch => {
    setAuthToken(false);
    dispatch(clearCurrentAdmin());
};

export const adminLogin = (data, history) => dispatch => {

    dispatch(setPageLoading());

    axios
    .post(
        backendServerURL+'/auth/admin/login',
        data
    )
    .then(res => {
        const {token} = res.data;  
        localStorage.setItem('adminJwtToken', token);
        setAuthToken(token);
        const decoded = jwt_decode(token);
        dispatch(setCurrentAdmin(decoded));
        history.push(`${route}/`)
    })
    .catch(err => {
        dispatch(setError( err.response.data && err.response.data.message ));
    }) 
    .finally(() => dispatch(clearPageLoading()));
}