import axios from 'axios';

import {
    PAGE_LOADING,
    PAGE_LOADED,
    SET_ERRORS,
	SET_SUCCESS,
    SET_ADMIN_DASHBOARD_DATA,
    SET_ADMIN_CONTACT_TICKET_DATA,
    SET_ADMIN_ACCOUNT_TICKET_DATA,
    SET_ADMIN_SAVE_CONTACT_TICKET_RESPONSE,
	SET_ADMIN_SAVE_ACCOUNT_TICKET_RESPONSE,
    SET_ADMIN_ACCOUNTS_DATA,
    SET_ADMIN_ACCOUNTS_STATUS_HANDLER,
    SET_ADMIN_SINGLE_CATEGORY,
    SET_ADMIN_DELETE_CATEGORY,
    SET_ADMIN_KNOWLEDGEBASES_DATA,
    SET_ADMIN_SINGLE_KNOWLEDGEBASE,
    SET_ADMIN_DELETE_KNOWLEDGEBASE,
    SET_ADMIN_SETTING,
} from './types';

import { NotificationManager } from "../../components/theme/react-notifications";

let backendServerURL = process.env.REACT_APP_API_URL+'/admin';

export const setPageLoading = () => {
	return {
		type: PAGE_LOADING
	};
};

export const clearPageLoading = () => {
	return {
		type: PAGE_LOADED
	};
};

export const clearCategory = () =>{
    return {
        type: SET_ADMIN_SINGLE_CATEGORY,
        payload: {}
    };
}

export const successNotification = (msg) => {
	return {
        type: SET_SUCCESS,
        payload: msg
    };
};

export const setError = (msg) => {
	return {
        type: SET_ERRORS,
        payload: msg
    };
    
};


//Get ADMIN dashboard analytics data
export const getDashboardData = () => dispatch => {

    dispatch(setPageLoading());

    axios
    .get( backendServerURL+'/dashboard' )
    .then(res => {
        dispatch({ type:SET_ADMIN_DASHBOARD_DATA, payload: res.data});
    })
    .catch(err => {
        dispatch(setError( err.response.data && err.response.data.message ));
    }) 
    .finally(() => dispatch(clearPageLoading()));
}


//Get ADMIN contact tickets data
export const getSupportTickets = (formData) => dispatch => {

    dispatch(setPageLoading());

    axios
    .post( backendServerURL+'/support-tickets', formData )
    .then(res => {

        if(formData.type === 'contact'){
        dispatch({
            type:SET_ADMIN_CONTACT_TICKET_DATA, 
            payload: res.data});
        }
        else{
            dispatch({
                type:SET_ADMIN_ACCOUNT_TICKET_DATA, 
                payload: res.data});
        }
    })
    .catch(err => {
        dispatch(setError( err.response.data && err.response.data.message ));
    }) 
    .finally(() => dispatch(clearPageLoading()));
}


//saving ADMIN response to ticket
export const saveTicketResponse = (formData, type) => dispatch => {

    dispatch(setPageLoading());

    axios
    .post( backendServerURL+'/save-ticekt-response', formData )
    .then(res => {

        if(type === 'contact'){
            dispatch({
                type: SET_ADMIN_SAVE_CONTACT_TICKET_RESPONSE, 
                payload: res.data
            });
        }
        else {
            dispatch({
                type: SET_ADMIN_SAVE_ACCOUNT_TICKET_RESPONSE, 
                payload: res.data});
        }

        NotificationManager.success(
            "Successfully sent response!"
        );

    })
    .catch(err => {
        dispatch(setError( err.response.data && err.response.data.message ));
    }) 
    .finally(() => dispatch(clearPageLoading()));
}


//Get ADMIN accounts data
export const getAccounts = (formData) => dispatch => {

    dispatch(setPageLoading());

    axios
    .post( backendServerURL+'/accounts', formData )
    .then(res => {

        dispatch({
            type:SET_ADMIN_ACCOUNTS_DATA, 
            payload: res.data 
        });

    })
    .catch(err => {
        dispatch(setError( err.response.data && err.response.data.message ));
    }) 
    .finally(() => dispatch(clearPageLoading()));
}


//ADMIN accounts status active handler
export const accountActive = (formData) => dispatch => {

    dispatch(setPageLoading());
 
    axios
    .put( backendServerURL+`/account-active`, formData)
    .then(res => {
        dispatch({
            type: SET_ADMIN_ACCOUNTS_STATUS_HANDLER, 
            payload: res.data 
        });

        NotificationManager.success(
            `Successfully ${res.data.is_active ? 'enabled' : 'disabled'} account! `
        );
        
    })
    .catch(err => {
        
    }) 
    .finally(() => dispatch(clearPageLoading()));
}


//Delete ADMIN Group data
export const deleteGroup = (id) => dispatch => {

    dispatch(setPageLoading());

    axios
    .delete( backendServerURL+`/categories/delete?id=${id}`)
    .then(res => {

        dispatch({
            type:SET_ADMIN_DELETE_CATEGORY, 
            payload: res.data 
        });

        NotificationManager.success(
            `Successfully delete category!`
        );

    })
    .catch(err => {
        dispatch(setError( err.response.data && err.response.data.message ));
    }) 
    .finally(() => dispatch(clearPageLoading()));
}


//get ADMIN single group data
export const getCategoryDetail = (id) => dispatch => {

    dispatch(setPageLoading());

    axios
    .get( backendServerURL+`/categories/${id}`)
    .then(res => {

        dispatch({
            type:SET_ADMIN_SINGLE_CATEGORY, 
            payload: res.data 
        });
    })
    .catch(err => {
        dispatch(setError( err.response.data && err.response.data.message ));

    }) 
    .finally(() => dispatch(clearPageLoading()));
}

//update ADMIN group data
export const updateCategoryDetail = (formData, history) => dispatch => {

    dispatch(setPageLoading());

    axios
    .put( backendServerURL+`/categories/update`, formData)
    .then(res => {
        
        NotificationManager.success(
            `Successfully updated category!`
        );

        history.push(`/admin/categories`);
    })
    .catch(err => {
        dispatch(setError( err.response.data && err.response.data.message ));
    }) 
    .finally(() => dispatch(clearPageLoading()));
}

//create ADMIN group data
export const addNewCategory = (formData, history) => dispatch => {

    dispatch(setPageLoading());

    axios
    .post( backendServerURL+`/categories/create`, formData)
    .then(res => {

        NotificationManager.success(
            ` Successfully created new group!"`
        );

        history.push(`/admin/categories`);
    })
    .catch(err => {
        dispatch(setError( err.response.data && err.response.data.message ));
    }) 
    .finally(() => dispatch(clearPageLoading()));
}

/*  ******KnowledgeBases Section Start******  */
//Get ADMIN knowledgebase data
export const getKnowledgebases = () => dispatch => {

    dispatch(setPageLoading());

    axios
    .get( backendServerURL+'/knowledgebase' )
    .then(res => {

        dispatch({ type:SET_ADMIN_KNOWLEDGEBASES_DATA, payload: res.data});
        
    })
    .catch(err => {
        dispatch(setError( err.response.data && err.response.data.message ));
    }) 
    .finally(() => dispatch(clearPageLoading()));
}

//Delete ADMIN knowledgebase data
export const deleteKnowledgebase = (id) => dispatch => {


    dispatch(setPageLoading());

    axios
    .delete( backendServerURL+`/knowledgebase/delete?id=${id}`)
    .then(res => {

        dispatch({
            type:SET_ADMIN_DELETE_KNOWLEDGEBASE, 
            payload: res.data 
        });

        NotificationManager.success(
            ` Successfully deleted knowledgebase!`
        );

    })
    .catch(err => {
        dispatch(setError( err.response.data && err.response.data.message ));
    }) 
    .finally(() => dispatch(clearPageLoading()));
}


//get ADMIN single knowledgebase data
export const getSingleKnowledgebaseData = (id) => dispatch => {

    dispatch(setPageLoading());

    axios
    .get( backendServerURL+`/knowledgebase/${id}`)
    .then(res => {

        dispatch({
            type:SET_ADMIN_SINGLE_KNOWLEDGEBASE, 
            payload: res.data 
        });
    })
    .catch(err => {
        dispatch(setError( err.response.data && err.response.data.message ));
    }) 
    .finally(() => dispatch(clearPageLoading()));
}

//update ADMIN knowledgebase data
export const updateKnowledgebaseDetail = (formData, history) => dispatch => {

    dispatch(setPageLoading());

    axios
    .put( backendServerURL+`/knowledgebase/update`, formData)
    .then(res => {

        NotificationManager.success(
            `Successfully updated knowledgebase!`
        );

        history.push(`/admin/questions`);
    })
    .catch(err => {
        dispatch(setError( err.response.data && err.response.data.message ));
    }) 
    .finally(() => dispatch(clearPageLoading()));
}

//create ADMIN knowledgebase data
export const createKnowledgebaseDetail = (formData, history) => dispatch => {

    dispatch(setPageLoading());

    axios
    .post( backendServerURL+`/knowledgebase/create`, formData)
    .then(res => {

        NotificationManager.success(
            ` Successfully created new knowledgebase!`
        );

        history.push(`/admin/questions`);
    })
    .catch(err => {
        dispatch(setError( err.response.data && err.response.data.message ));
    }) 
    .finally(() => dispatch(clearPageLoading()));
}



/*  ****** Settings Section Start******  */

//Get ADMIN Settings data
export const getAdminSetting = () => dispatch => {

    dispatch(setPageLoading());

    axios
    .get( backendServerURL+'/setting' )
    .then(res => {
        dispatch({
            type:SET_ADMIN_SETTING, 
            payload: res.data });
    })
    .catch(err => {
        dispatch(setError( err.response.data && err.response.data.message ));
    }) 
    .finally(() => dispatch(clearPageLoading()));
}


//update ADMIN Settings data
export const updateSettingsDetail = (formData) => dispatch => {

    dispatch(setPageLoading());

    axios
    .put( backendServerURL+`/update-settings`, formData)
    .then(res => {
        dispatch(getAdminSetting());
        dispatch(successNotification(" Successfully updated settings! " ));
    })
    .catch(err => {
        dispatch(setError( err.response.data && err.response.data.message ));
    }) 
    .finally(() => dispatch(clearPageLoading()));
}

