import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import AlertMessage from '../../common/Alert';
import { sendEmail } from '../../../store/actions/publicActions';

class EmailModel extends React.Component{

    constructor(props){
        super(props);

        this.state= {
            from : '',
            to : props.therapist,
            subject : '',
            text : ''
        }
    }

    onChange = (e) =>{

        this.setState({
            [e.target.name] : e.target.value
        })
    }

    onSubmit =(e) =>{
        e.preventDefault()

        const Data = {
            from : this.state.from,
            subject : this.state.subject,
            text : this.state.text,
            to: this.state.to
        }
        console.log('Data', Data)

        this.props.sendEmail(Data)
        
        this.setState({
            email : '',
            subject : '',
            text : ''
        })
        
    }


    render(){
        console.log('state', this.state)

        const { from, subject, text } = this.state;
    return(
    <div className="modal fade" id="messageModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Write Your Email Here</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <AlertMessage />
                    
                    <form onSubmit={ this.onSubmit }>
                        <div className="form-group row">
                            <label htmlFor="from" className="col-sm-2 col-form-label">From</label>
                            <div className="col-sm-10">
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    name='from'    
                                    placeholder="Enter your Email here"
                                    onChange={ this.onChange }
                                    value={from}
                                    required
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="subject" className="col-sm-2 col-form-label">Subject</label>
                            <div className="col-sm-10">
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    name="subject"    
                                    placeholder="Enter Subject"
                                    onChange={ this.onChange }
                                    value={subject}
                                    required
                                />
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="textareaLabel" htmlFor="text" style={{ float: 'left', paddingLeft: 0 }}>Email</label>
                            <textarea 
                                className="form-control" 
                                rows="6"
                                name="text"
                                placeholder="Email Body"
                                onChange={ this.onChange }
                                value={text}
                                required
                                
                                ></textarea>
                        </div>

                        <div className="modal-footer">
                            <button type="submit" className="btn btn-primary">Send</button>
                        </div>

                    </form>
                </div>
                
            </div>
        </div>
    </div>

    )
    }
}

EmailModel.propTypes = {
    sendEmail: PropTypes.func.isRequired
};

const mapStateToProps = state => ({

});

export default connect(mapStateToProps,{ sendEmail })(EmailModel);