import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import Header from '../../common/Header';
import Footer from '../../common/Footer';
import { getProfile, clearProfile } from '../../../store/actions/publicActions';
import SearchResults from '../../../assets/SearchResults.png';
import MapPng from '../../../assets/map.png';
import Envelope from '../../../assets/envelope.png';
import Phone from '../../../assets/phone-profile.png';
import License from '../../../assets/license.png';
import LicenseNumber from '../../../assets/license-number.png';
import RemoteTherapy from '../../../assets/remote-therapy.png';
import Clients from '../../../assets/clients.png'
import Medicine from '../../../assets/medicine.png';
import Slider from '../../../assets/slider.png';
import { Collapse } from 'react-collapse';
import Spinner from '../../common/Spinner';
import EmailModel from './emailModel';
import CallModel from './callModel';


let backendServerURL = process.env.REACT_APP_API_URL;

class TherapistProfile extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            loading : false,
            collapse : [0],
            profile : {}
        }
    }

    componentDidMount(){
        this.props.getProfile(this.props.match.params.id);
    }

    componentWillUnmount(){
        this.props.clearProfile();
    }

    static getDerivedStateFromProps(props, state) {
    
        let {  profile } = props.public;
    
        if(profile && JSON.stringify(state.profile) !== JSON.stringify(profile)){
            return {
                profile: profile
            } 
        }
    
        return null;
    }

    handleCollapse = id =>{
        
        let index = this.state.collapse.indexOf(id);

        if(index === -1){
            this.setState( (state) => ({
                collapse : [...state.collapse, id]
            }))
        }
        else {
            var newCollapse = [...this.state.collapse];

            newCollapse.splice(index, 1);

            this.setState( {
                collapse : newCollapse
            })
        }
        
    } 


    render(){
        const { profile , collapse } = this.state;

        return (
        <div className="therapist-profile-bg">
            <Spinner />

            <div className="bg-top"></div>
            <Header />
            <div className="bss-112-bg">
                <div className="container-fluid">
                    <div className="row bss-050">
                        <div className="col-xl-3 col-lg-3 col-md-2 order-sm-2 order-md-1 order-2  col-sm-12 col-12 bss-118">
                            { profile['image'] ? 
                                <img className="searchImgX searchBss220Img" src={ `${backendServerURL}/images/${profile['image']}` } alt="user-profile" /> 
                            : <img className="searchImgX searchBss220Img" src={ `${backendServerURL}/images/undefined.png` } alt="user-profile" />}
                        </div>
                        <div className="col-xl-3 col-lg-5 col-md-6 order-sm-1 order-md-2 order-1 col-sm-12 col-12 bss-222">
                            <div className="bss-224">
                                <span>{ profile.practice_name } </span>
                            </div>
                            <div className="bss-226">
                                <span>{ profile.title } </span>
                                {/* <span>{ this.state.info } </span> */}
                                {/* { button } */}
                            </div>
                        </div>
                    <div className="col-xl-6 col-lg-4 col-md-4 order-md-3 bss-228 therapist-profile-social-icons">
                        <div className="bss-230">

                            <div className="bss-232">
                                <a target="_blank" rel="noopener noreferrer" href={`https://${profile.website}`} >
                                    <img src={ MapPng } alt="map" />
                                </a>
                            </div>

                            <div className="bss-232 messageModal">
                            { !profile.accepting_new_clients && <div className="profile-main-overlay"></div> }
                                <img src={Envelope} alt="envelope" data-toggle="modal" data-target="#messageModal" className="cursor-pointer"/>
                                {  profile.listing_id && <EmailModel therapist={profile.listing_id} /> }
                            </div>
                            
                            <div className="bss-232 phn-pd phone-modal ">
                            { !profile.accepting_new_clients && <div className="profile-main-overlay"></div> }
                                <img src={Phone} alt="phone" data-toggle="modal" data-backdrop="static" data-keyboard="false" data-target="#CallModal" className="cursor-pointer" />
                                {  profile.listing_id && <CallModel therapist={profile.listing_id} /> }
                            </div>

                            { !profile.accepting_new_clients && <p className="notAccepting-Text">Not accepting new Clients</p> }

                        </div>

                    </div>
                    </div>
                    
                    <div className="row bss-234-bg">
                    <div className="col-md-12 col-lg-8 order-md-1 order-lg-1 order-xl-1">
                        <p className="biographyPara">{ profile.description } </p>

                        <div className="verify">
                            <p>Verify your health insurance coverage you arrange your first visit</p>
                        </div>
                    </div>

                    <div className="col-md-12 col-lg-4 order-md-2 order-lg-2 order-xl-2 locationSection">
                        <div className="locationContainer">
                            <h4 className="bss-lctn">Location(s):</h4>
                            { profile.location && <p className="DBT-p">{ profile.location.address_1 } <br /> { profile.location.city } { profile.location.state_province } {profile.location.postal_code }</p> }  
                        </div>
                        
                        <div className="information">
                        <div className="addrss">
                            <div className="addressIcon"> 
                                <img src={ License } className="LicenseIcon" alt="LicenseIcon" />
                            </div>
                            <div className="bss-242">State of Licensure</div>
                            <div className="bss-244">{ profile.license_state }</div>
                        </div>

                        <div className="addrss">
                            <div className="addressIcon"> 
                                <img src={ LicenseNumber } className="LocationIcon"  alt="LicenseIcon" />
                            </div>
                            <span className="bss-242">License Number</span>
                            <span className="bss-244">{ profile.license_number }</span>
                        </div>

                        <div className="addrss">
                            <div className="addressIcon"> 
                            <img src={ RemoteTherapy } className="LocationIcon" alt="RemoteTherapyIcon" />
                            </div>
                            <span className="bss-242">Remote Therapy</span>
                            <span className="bss-244">{profile.remote_sessions ? 'Yes' : 'No'}</span>
                        </div>

                        <div className="addrss">
                            <div className="addressIcon"> 
                            <img src={ Clients } className="LocationIcon"  alt="ClientsIcon"/>
                            </div>
                            <span className="bss-242">Accepting New Patients</span>
                            <span className="bss-244">{profile.accepting_new_clients ? 'Yes' : 'No'}</span>
                        </div>

                        <div className="addrss">
                            <div className="addressIcon"> 
                            <img src={ Medicine } className="LocationIcon"  alt="MedicineIcon"/>
                            </div>
                            <span className="bss-242">Prescribe Medicine</span>
                            <span className="bss-244"> {profile.prescribe_medication ? 'Yes' : 'No'}</span>
                        </div>

                                    
                        <div className="addrss">
                            <div className="addressIcon"> 
                            <img src={ Slider } className="LocationIcon" alt="SliderIcon" />
                            </div>
                            <span className="bss-242">Slider Scale</span>
                            <span className="bss-244">{profile.sliding_scale ? 'Yes' : 'No'}</span>
                        </div>
                        </div>



                    </div>

                    <div className="col-md-12 col-lg-8 EndorsementSection order-3 order-sm-3 order-md-3 order-lg-3 order-xl-3">
                        <h1 className="endorsementTitle">Endorsements</h1>
                        <div className="row">
                            { profile.reciver && profile.reciver.filter(endor => endor.is_confirmed === true ).map(endorsement =>(
                                <div className="col-md-6 col-lg-4">
                                    <div className="endorsementSection">
                                        <img src={ SearchResults } className="endorsementSearchImg" alt="endorsementSearchImg" />
                                        <p>{ endorsement.endorsement }</p>
                                    </div>
                                </div>
                            ))}

                        </div>

                        <div className="tonesNetwork">
                            <h4 className="toneTitle">Tones Network</h4>
                            { profile.reciver && profile.reciver.map(endorsement =>(
                                 <div className="toneImg">
                                 <img src={ SearchResults } alt="toneImg" />
                                 <p>{endorsement.full_name }</p>
                             </div>
                            ))}

                        </div>
                    </div>

                    
                    <div className="col-md-12 col-lg-4 accordianSection order-4 order-sm-4 order-md-4 order-lg-4 order-xl-4">
                        <div onClick={() => this.handleCollapse(0) } className="accordianPanle">
                            Treatment Modalities
                        </div>
                        <Collapse isOpened={ collapse.indexOf(0) !== -1} >

                        <ul className="accordian-list">
                            {profile.listing_attributes && profile.listing_attributes.filter(attr=> attr.attribute.category_id.name === "Types of Therapy").map(ele => (
                                <li>{ele.attribute.name }</li>
                            )) }
                                
                        </ul>

                        </Collapse>

                        <div onClick={() => this.handleCollapse(1) } className="accordianPanle">
                            Modality
                        </div>
                        <Collapse isOpened={ collapse.indexOf(1) !== -1}>
                        <ul className="accordian-list">
                            {profile.listing_attributes && profile.listing_attributes.filter(attr=> attr.attribute.category === 5).map(ele => (
                                <li>{ele.attribute.name }</li>
                            )) }
                                
                        </ul>
                        </Collapse>
                        <div onClick={() => this.handleCollapse(2) } className="accordianPanle">
                            Insurance plans
                        </div>

                        <Collapse isOpened={ collapse.indexOf(2) !== -1} >
                        <ul className="accordian-list">
                            {profile.listing_attributes && profile.listing_attributes.filter(attr=> attr.attribute.category === 2).map(ele => (
                                <li>{ele.attribute.name }</li>
                            )) }
                                
                        </ul>
                        </Collapse>

                    </div>
                    
                    </div>

                </div>
            </div>

            <Footer />

        </div>
        )
    }
}

TherapistProfile.propTypes = {
    getProfile: PropTypes.func.isRequired,
    clearProfile: PropTypes.func.isRequired,
    public: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    public: state.public
});

export default connect(mapStateToProps,{ getProfile, clearProfile })(withRouter(TherapistProfile));