
import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {loadStripe} from '@stripe/stripe-js';
import RoundImg from '../../../assets/roundTherapists.png';
import Checkmark from '../../../assets/checkmark.png';
import {CardElement, Elements, ElementsConsumer} from '@stripe/react-stripe-js';
import { renewSubscription } from '../../../store/actions/therapistActions'; 
import { getPaymentData } from '../../../store/actions/therapistActions';

class RenewSubscription extends React.Component {
    constructor(){
        super();

        this.state ={
            paymentId : ''
        }
    }


    onChange = type =>{

        this.setState({
            subScription : type
        })
    }    

    handleSubmit = async (event,stripe, elements) => {
        // Block native form submission.
        event.preventDefault();
    
        if (!stripe || !elements) {
          // Stripe.js has not loaded yet. Make sure to disable
          // form submission until Stripe.js has loaded.
          return;
        }
    
        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardElement = elements.getElement(CardElement);
    
        // Use your card Element with other Stripe.js APIs
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
        });
    
        if (error) {
          console.log('[error]', error);
        } else {
            
            this.props.renewSubscription({
                paymentId : paymentMethod.id,
                subScription : this.state.subScription,
                listId : this.props.match.params.id
            },this.props.history)
        }
    };

    componentDidMount(){
        this.props.getPaymentData();
    }

    static getDerivedStateFromProps(props, state) {

        if(window.location.href.indexOf("/listings") > -1 && props.hist && props.match.path !== props.hist.path  ){
            props.changematch();
            props.history.push(props.hist.path);
        }

        const { paymentData }  = props.therapist;
        
        if(paymentData && JSON.stringify(state.paymentData) !== JSON.stringify(paymentData)){
            return {
                paymentData: paymentData
            }
        }

        return null;
    }

    render(){
        const { paymentData, subScription } = this.state;

        const stripePromise = loadStripe(paymentData.stripePublicKey);

    return(
    <>
    <div className="tierContainerParent">
        { paymentData.prices && paymentData.prices.map(item =>(
            
            <div key={item.id} className="tierContainer">
                <div className="tierContent">
                <h2 className="tierTitle">Tier 1</h2>
                <p className="tierPText">Placeholder Text</p>
                <p className="tierPText">Placeholder Text</p>
                <p className="tierPText">Placeholder Text</p>
                <img alt="" src={RoundImg} className="w-100 roundImg" />
                </div>
                <div className="tierBodyContent text-center">
                <div className="monthlyPrice text-center">
                    ${item.unit_amount/100} / Per {item.recurring.interval}
                </div>
                <div className="tierCheckText">
                    <p className="tierExampleText">
                    <img alt="" src={Checkmark} className="checkmarkImg" /> Line Example 1</p>
                    <p className="tierExampleText">
                    <img alt="" src={Checkmark} className="checkmarkImg" /> Line Example 1</p>
                    <p className="tierExampleText">
                    <img alt="" src={Checkmark} className="checkmarkImg" /> Line Example 1</p>
                    <button 
                        type="button" 
                        className="freeTrialBtn text-white border-0 w-100"
                        onClick={() => this.onChange(item.id)} 
                    >Start Free Trail</button>
                </div>
                </div>
            </div>

        ))}
    </div>

            { subScription && <div className="billingContainer">
                <div className="form-group">
                    <h2 className="form-heading">Payment</h2>
                    <Elements stripe={stripePromise}>
                    <ElementsConsumer>
                        {({stripe, elements}) => (
                        
                            <form id="payment-form" onSubmit={(event) => this.handleSubmit(event,stripe,elements)}>
                            <div className="flex flex-wrap -mx-3 mb-3">
                                <div className="w-full px-3 mb-0">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                    Card
                                </label>
                                <div
                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded-md py-3 px-2 leading-tight focus:outline-none focus:bg-white"
                                    id="card-element"
                                >
                                    <CardElement
                                    options={{
                                        style: {
                                        base: {
                                            fontSize: '16px',
                                            color: '#32325d',
                                            fontFamily:
                                            '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif',
                                            '::placeholder': {
                                            color: '#a0aec0',
                                            },
                                        },
                                        invalid: {
                                            color: '#9e2146',
                                        },
                                        },
                                    }}
                                    />
                                </div>
                                <div
                                    id="card-element-errors"
                                    className="text-gray-700 text-base mt-2"
                                    role="alert"
                                ></div>
                                </div>
                            </div>
                                <button type="submit" className="NextBtn">Next</button>
                            </form>
                                )}
                        </ElementsConsumer>
                    </Elements>
                </div>
            </div>
        }
            
    </>
    )
    }

}


RenewSubscription.propTypes = {
    renewSubscription: PropTypes.func.isRequired,
    getPaymentData: PropTypes.func.isRequired,
    therapist: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    therapist : state.therapist
});

export default connect( mapStateToProps,{ renewSubscription, getPaymentData })(withRouter(RenewSubscription));