import React from 'react';


function AccountList({ accounts, transferAccount }){
    return (
        <table className="table table-bordered">
        <thead>
            <tr>
                <th scope="col" className="connection-page-id-column">ID</th>
                <th scope="col">First Name</th>
                <th scope="col">Last Name</th>
                <th scope="col">Display Name</th>
                <th scope="col">Email</th>
                <th scope="col">Actions</th> 
            </tr>
        </thead>
        <tbody>  
            { accounts.map(( list, index )=>(
                <tr key={index}>
                    <td >{ list.account_id } </td>
                    <td> { list.first_name } </td>
                    <td>{ list.last_name }</td>
                    <td>{ list.display_name }</td>
                    <td>{list.email}</td>
                    <td>
                        <button onClick={ ()=> transferAccount(list.account_id) } type="button" className="btn btn-primary" >
                            Transfer
                        </button>
                    </td> 
                </tr>
            ))}
        </tbody>
    </table>
    )
}

export default AccountList;