import React from 'react';
import { Link } from 'react-router-dom';

function Location(props) {
    const { locations, onChange , onSubmit, addNewLocation, removeLocation } = props;

    return(
    <form className="listingLocationContent" onSubmit={ onSubmit } encType="multipart/form-data" >
    <h2 className="page-heading">Locations</h2>
        { locations.map((location,idx)=>(
            <React.Fragment key={idx}>
            <div className="row listingFormFields">
                <div className="col-md-12 col-lg-6 d-flex justify-content-between align-items-baseline">
                    <label htmlFor={"address,"+idx}>Street Address</label>
                    <input 
                        type="text" 
                        className="form-control" 
                        placeholder="Street Address" 
                        name={"address,"+idx}
                        value={location.address}
                        onChange={ onChange }   
                        required
                    />
                </div>
                
                <div className="col-md-12 col-lg-6 d-flex justify-content-between align-items-baseline">
                    <label htmlFor={"city,"+idx} >City</label>
                    <input 
                        type="text" 
                        className="form-control" 
                        placeholder="City" 
                        name={"city,"+idx}    
                        value={location.city}
                        onChange={ onChange }
                        required
                    />
                </div>
            </div>

            <div className="row listingFormFields">
                <div className="col-md-12 col-lg-6 d-flex justify-content-between align-items-baseline">
                    <label htmlFor={"state,"+idx} >State</label>
                    <input 
                        type="text" 
                        className="form-control" 
                        placeholder="State" 
                        name={"state,"+idx}  
                        value={location.state}
                        onChange={ onChange } 
                        required 
                    />
                </div>
                
                <div className="col-md-12 col-lg-6 d-flex justify-content-between align-items-baseline">
                    <label htmlFor={"zipCode,"+idx} >Zip Code</label>
                    <input 
                        type="text" 
                        className="form-control" 
                        placeholder="Zip Code" 
                        name={"zipCode,"+idx}
                        value={location.zipCode}
                        onChange={ onChange }   
                        required
                    />
                </div>
            </div>
            <div className="mt-5 md:mt-0 md:col-span-4 text-right border-solid border-t border-gray-300 pt-3">
                  
                <div className="sm:col-span-12">
                {idx === locations.length - 1 ? 
                    <Link to={"#"} onClick={()=> addNewLocation() }  className="font-14 font-medium hover:underline text-blue-500 hover:text-blue-500 focus:outline-none focus:underline transition ease-in-out duration-150 heading-font">
                        Add another location
                    </Link>
                    :
                    <Link to={"#"} onClick={()=> removeLocation(idx+1) }  className="font-14 font-medium hover:underline text-blue-500 hover:text-blue-500 focus:outline-none focus:underline transition ease-in-out duration-150 heading-font">
                        Remove
                    </Link>
                }
                    
                </div>

            </div>
        </React.Fragment>
        )) }
        <button type="submit" className="NextBtn">Next</button>
        
    </form>
    )
}

export default Location;