import React from 'react';


function General(props) {

    const { general, onChange, onClick, onSubmit, onChangeImage, editListing } = props;
    
    return(
    <form onSubmit={ onSubmit } encType="multipart/form-data">
    
    <div className="image-contents">
        <div onClick={()=> onClick('solo')} className="cat-card cursor-pointer" > 
            <div className={[ general.listingType === 'solo' && 'textWhite ' , 'ac-front-side face']}>
                <div className="hovering-c"> 
                    <span className="cat-icon"> 
                        <i className={[general.listingType === 'solo' && "iconTextBlue  " ," fa fa-plus"]} aria-hidden="true"></i>
                    </span> 
                    <span className="category-name">Solo</span>
                </div>
            </div>
        </div>
            
        <div onClick={()=> onClick('group')} className="cat-card cursor-pointer"> 
            <div className={[general.listingType === 'group' && 'textWhite ' , 'ac-front-side face']}>
                <div className="hovering-c"> 
                    <span className="cat-icon" style={{color: "#fff"}}> 
                    <i className={[general.listingType === 'group' && "iconTextBlue  " ," fa fa-plus"]} aria-hidden="true"></i>
                    </span> 
                    <span className="category-name">Group</span>
                </div>
            </div>
        </div>
    </div>
    { general.listingType !== '' &&
    <div className="listing-form general">
        <div className="listing-form-container-div">
            <h1 className="listingTitle">General</h1>
            <div className="new-general-listing-form">
                <div className="row listingFormFields">
                    <div className="col-md-12 col-lg-6 d-flex justify-content-between align-items-baseline">
                        <label htmlFor="Name">Practice Name</label>
                        <input 
                            type="text" 
                            className="form-control" 
                            placeholder="Name" 
                            name="name"
                            object="general"
                            value={general.name}
                            onChange={(e) => onChange(e, 'general') }
                            required
                        /> 
                    </div>
                    
                    <div className="col-md-12 col-lg-6 d-flex justify-content-between align-items-baseline">
                        <label htmlFor="Title">Title</label>
                        <input 
                            type="text" 
                            className="form-control" 
                            placeholder="Title" 
                            name="title"
                            value={general.title}
                            onChange={(e) => onChange(e, 'general') }
                            required
                        />
                    </div>
                </div>

                <div className="row listingFormFields">
                    <div className="col-md-12 col-lg-6 d-flex justify-content-between align-items-baseline">
                        <label htmlFor="WOrk Phone">Mobile Phone</label>
                        <input 
                            type="number" 
                            className="form-control" 
                            placeholder="Work Phone"
                            name="phone"
                            value={general.phone}
                            onChange={(e) => onChange(e, 'general') }
                            required
                        />
                    </div>
                    
                    <div className="col-md-12 col-lg-6 d-flex justify-content-between align-items-baseline">
                        <label htmlFor="newClients">Accepting Clients</label>
                        <select 
                            className='form-control'
                            style={{ padding : 0, paddingTop: 8, paddingBottom: 8, paddingLeft: 15}}
                            placeholder="Acception New Clients"
                            id="newClients"
                            name="newClients"
                            value={ general.newClients }
                            onChange={(e) => onChange(e, 'general') }
                            required
                            >
                            <option disabled value="">Acception New Clients</option>
                            <option value={true}>Yes</option>
                            <option value={false}>No</option>
                        </select>
                        
                    </div>
                </div>

                <div className="row listingFormFields">
                <div className="col-md-12 col-lg-6 d-flex justify-content-between align-items-baseline">
                        <label htmlFor="newClients">Remote Sessions</label>
                        <select 
                            className='form-control'
                            style={{ padding : 0, paddingTop: 8, paddingBottom: 8, paddingLeft: 15}}
                            placeholder="Remote Sessions"
                            id="remoteSessions"
                            name="remoteSessions"
                            value={ general.remoteSessions }
                            onChange={(e) => onChange(e, 'general') }
                            required
                        >
                            <option disabled value="">Remote Sessions</option>
                            <option value={true}>Yes</option>
                            <option value={false}>No</option>
                        </select>
                        
                    </div>
                    
                    <div className="col-md-12 col-lg-6 d-flex justify-content-between align-items-baseline">
                        <label htmlFor="newClients">Prescribe Medication</label>
                        <select 
                            className='form-control'
                            style={{ padding : 0, paddingTop: 8, paddingBottom: 8, paddingLeft: 15}}
                            placeholder="Prescribe Medication"
                            id="prescribeMedication"
                            name="prescribeMedication"
                            value={ general.prescribeMedication }
                            onChange={(e) => onChange(e, 'general') }
                            required
                            >
                            <option disabled value="">Prescribe Medication</option>
                            <option value={true}>Yes</option>
                            <option value={false}>No</option>
                        </select>
                        
                    </div>
                </div>

                <div className="row listingFormFields">
                    <div className="col-md-12 col-lg-6 d-flex justify-content-between align-items-baseline">
                        <label htmlFor="Email">Email</label>
                        <input  
                            type="email" 
                            className="form-control" 
                            placeholder="Email" 
                            name="email"
                            value={general.email}
                            onChange={(e) => onChange(e, 'general') }
                            required
                        />
                    </div>
                    
                    <div className="col-md-12 col-lg-6 d-flex justify-content-between align-items-baseline">
                        <label htmlFor="Website">Website</label>
                        <input 
                            type="text" 
                            className="form-control" 
                            placeholder="Website" 
                            name="website"
                            value={general.website}
                            onChange={(e) => onChange(e, 'general') }
                        />
                    </div>
                </div>

                <div className="row listingFormFields">
                    <div className="col-md-12 col-lg-6 d-flex justify-content-between align-items-baseline">
                        <label htmlFor="License-no">License Number</label>
                        <input  
                            type="number" 
                            className="form-control" 
                            placeholder="License Number"
                            name="license" 
                            value={general.license}
                            onChange={(e) => onChange(e, 'general') }  
                            required 
                        />
                    </div>
                    
                    <div className="col-md-12 col-lg-6 d-flex justify-content-between align-items-baseline">
                        <label htmlFor="License-state">License State</label>
                        <input 
                            type="text" 
                            className="form-control" 
                            placeholder="License State" 
                            name="licenseState"
                            value={general.licenseState}
                            onChange={(e) => onChange(e, 'general') }
                            required
                        />
                    </div>
                </div>

                <div className="row listingFormFields">
                    <div className="col-md-12 col-lg-6 d-flex justify-content-between align-items-baseline">
                        <label htmlFor="newClients">Slider Scale</label>
                        <select 
                            className='form-control'
                            style={{ padding : 0, paddingTop: 8, paddingBottom: 8, paddingLeft: 15}}
                            placeholder="Slider Scale"
                            id="slidingScale"
                            name="slidingScale"
                            value={ general.slidingScale }
                            onChange={(e) => onChange(e, 'general') }
                            required
                        >
                            <option disabled value="">Slider Scale</option>
                            <option value={true}>Yes</option>
                            <option value={false}>No</option>
                        </select>
                        
                    </div>
                </div>
                { editListing ? ' ' :  
                    <div className="row listingFormFields image-container">
                        <div className="col d-flex justify-content-between align-items-baseline">
                            <label htmlFor="Name">Image</label>
                            <input 
                                type="file"  
                                className="form-control"
                                name="image"
                                accept="image/*"
                                value={general.image.name}
                                onChange={onChangeImage}
                                required
                            />
                        </div>
                    </div>
                }

                <div className="row listingFormFields image-container">
                    <div className="col-md-12 col-lg-12 d-flex justify-content-between align-items-baseline">
                        <label htmlFor="Description">Description</label>
                        <textarea 
                            id="Description" 
                            className="form-control" 
                            placeholder="Description"
                            name="description"
                            value={general.description}
                            onChange={(e) => onChange(e, 'general') }
                            required
                            ></textarea>
                    </div>
                </div>

                <button type="submit" className="NextBtn">Next</button>
            </div>
        </div>
    </div>
    } 
    </form>
    )
}

export default General;