import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import { getFilters, clearProfile  } from '../../../store/actions/publicActions';
import { getListing, editListing } from '../../../store/actions/therapistActions';
import SideBar from './SideBar';
import General from '../newListing/General';
import Location from '../newListing/Location';
import PracticeDetails from '../newListing/PracticeDetails';
import Review from './Review';

class EditListing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activePage : 1,
            listing : {},
            selected : [],
            radioselected : [],
            attributes : [],
            loading : false,
            loaded : false
        };
    }

    componentDidMount(){
        if(window.location.href.indexOf("/listings") === -1){
            this.props.getFilters();
            this.props.getListing(this.props.match.params.id);
        }
    }

    componentWillUnmount(){
        this.props.clearProfile();
    }

    static getDerivedStateFromProps(props, state) {

        if(window.location.href.indexOf("/listings") > -1 && props.hist && props.match.path !== props.hist.path  ){
            props.changematch();
            props.history.push(props.hist.path);
        }


        const { Categories, profile , loading }  = props.public;

        let changedState = {};
        let stateChanged = false;

        if(JSON.stringify(state.loading) !==JSON.stringify(loading)){
            changedState.loading = loading;
            stateChanged = true;  
        }

        if(Categories && JSON.stringify(state.filters) !== JSON.stringify(Categories)){
            changedState.filters = Categories
            stateChanged = true;
        }

        if(!state.loaded && profile && JSON.stringify(state.listing) !== JSON.stringify(profile)){
            changedState.loaded = true;

            changedState.selected = [];

            profile.Listing_categories.map(element=>(
                changedState.selected.push({
                    category_id : element.category_id,
                    sub_category_id  : element.sub_category_id
                })
            ))
            

            changedState.general = {
                name : profile.practice_name,
                listingType : profile.listing_type,
                title : profile.title,
                phone : profile.work_phone,
                newClients : profile.accepting_new_clients,
                remoteSessions : profile.remote_sessions,
                prescribeMedication : profile.prescribe_medication,
                slidingScale : profile.sliding_scale,
                email : profile.email,
                website : profile.website,
                license : profile.license_number,
                licenseState : profile.license_state,
                description : profile.description
            }

            if(profile.locations ){
                changedState.locations = [];

                profile.locations.map(element=>(
                    changedState.locations.push({
                        address: element.address_1,
                        city: element.city,
                        state: element.state_province,
                        zipCode: element.postal_code 
                    })
                ))

            }

            changedState.listing = profile;

            stateChanged = true;
        }

        if(stateChanged){
            return changedState;
        }

        return null;
    }

    handleCheck = (category, sub_category ,value) => {

        const attribute = {
            category_id : category,
            sub_category_id : sub_category
        }

        if(value){

            this.setState( (state) => ({
                selected : [...state.selected, attribute]
            }))

        } else {
            var newSelected = [...this.state.selected];

            var index = this.state.selected.indexOf(item => item.sub_category_id === sub_category );

            newSelected.splice(index, 1);

            this.setState({
                selected : newSelected
            })
        }

    }

    radioCheck = e => {

        const attribute = {
            category : parseInt(e.target.name),
            value : parseInt(e.target.value)
        }

        var newRadioSelected = [...this.state.radioSelected];

        const index = this.state.radioSelected.findIndex(element => element.category === attribute.category);

        if(index !== -1 ){
            newRadioSelected.splice(index, 1);
        } 

        this.setState({
            radioSelected : [...newRadioSelected, attribute]
        })
    }

    onChangeImage = ( event) => {
        this.setState({
            image :event.target.files[0],
            general:{
                ...this.state.general,
                image: event.target.value
            }
        });
    } 

    locationDataChange = e => {
        const targetName = e.target.name;

        const Fieldname = targetName.split(',')[0];
        const FieldId = targetName.split(',')[1];
        
        const locations = [...this.state.locations];

        locations[FieldId] = {
            ...locations[FieldId],
            [Fieldname]: e.target.value
        }

        this.setState({ locations });
    }

    addNewLocation = () => {
        const newLocation ={
            address : '',
            city : '',
            state : '',
            zipCode : ''
        };

        this.setState({
            locations: [...this.state.locations, newLocation ]
        })
    }

    removeLocation = (position) =>{
        const oldLocations = [...this.state.locations];

        oldLocations.splice(position,1);

        this.setState({
            locations: oldLocations
        })
    }

    selectParacticeType = (type) => {
        this.setState({
            general: {
                ...this.state.general,
                listingType: type
            }
        });
    }

    onChange = (e, object) => {
        let value = e.target.value;

        if(value === "true"){
            value = true
        }
        else if(value === "false"){
            value = false
        }
        
        let newObject = {
            ...this.state[object],
            [e.target.name]: value
        }

        this.setState({
            [object] : newObject
        })
    }

    onGenreralSubmit = e => {
        e.preventDefault();

        this.setState({
            activePage : 2
        })
    }

    onLocationSubmit = e => {
        e.preventDefault();

        this.setState({
            activePage : 3
        })
    }

    onPracticeDetailsSubmit = (e) => {
        e.preventDefault();

        let attributes  = [...this.state.selected];

        this.setState({
            attributes : [...attributes],
            activePage : 4
        })
    }

    finalSubmit = (e) => {
        e.preventDefault();


        const data = {
            listing_id: this.props.match.params.id,
            ...this.state.general,
            locations:  [...this.state.locations],
            attributes: [...this.state.selected]
        }

        // let formData = new FormData();
        
        // formData.append('id', data.id);
        // formData.append('name', data.name);
        // formData.append('title', data.title);
        // formData.append('description', data.description);
        // formData.append('phone', data.phone);
        // formData.append('newClients', data.newClients);
        // formData.append('email', data.email);
        // formData.append('website', data.website);
        // formData.append('listingType', data.listingType);
        // formData.append('licenseNumber', data.licenseNumber);
        // formData.append('licenseState', data.licenseState);
        // formData.append('maxCost', data.maxCost);
        // formData.append('minCost', data.minCost);
        // formData.append('address', data.address);
        // formData.append('city', data.city);
        // formData.append('state', data.state);
        // formData.append('zipCode', data.zipCode);
        // formData.append('attributes', JSON.stringify(data.attributes));
        // formData.append("image", this.state.image );

        //this.props.history.push('/therapist/my-listings')
        this.props.editListing(data, this.props.history);

        

    }

    setActivePage = page =>{

        if(page < this.state.activePage){
            this.setState({
                activePage : page
            })
        }

    } 


    render() { 

        console.log('state 123', this.state);

        let pageContent = '';

        if(this.state.activePage === 1){
            pageContent = <General 
                onClick={this.selectParacticeType}
                onChange={ this.onChange }
                general={this.state.general}
                onChangeImage={this.onChangeImage}
                onSubmit={ this.onGenreralSubmit }
                editListing={true}
            />
        }
        else if(this.state.activePage === 2){
            pageContent = <Location 
                onChange={ this.locationDataChange }
                locations={ this.state.locations }
                onSubmit={ this.onLocationSubmit }
                addNewLocation={ this.addNewLocation } 
                removeLocation={ this.removeLocation }
            />
        }
        else if(this.state.activePage === 3){

            pageContent = <PracticeDetails 
                onChange={ this.onChange }
                filters={ this.state.filters }
                selected={ this.state.selected }
                radioSelected={ this.state.radioSelected }
                handleCheck={ this.handleCheck }
                radioCheck={ this.radioCheck }
                onSubmit={ this.onPracticeDetailsSubmit }
            />
        }
        else{
            pageContent =  <Review
                general={this.state.general}
                filters={ this.state.filters}
                selected={this.state.selected}
                locations={ this.state.locations }
                onSubmit={ this.finalSubmit }

            />
        }

        return (
        
        <div className="new-listing-Container">
            <SideBar 
                setActivePage={this.setActivePage}
                activePage={this.state.activePage}
            />

            { this.state.general && pageContent }
            
        </div>            
        );
    }
}

EditListing.propTypes = {
    getFilters: PropTypes.func.isRequired,
    editListing : PropTypes.func.isRequired,
    getListing : PropTypes.func.isRequired,
    public: PropTypes.object.isRequired,
    clearProfile: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
   public: state.public,
   errors: state.notification
});

export default connect(mapStateToProps,{getFilters, editListing, getListing, clearProfile})(withRouter(EditListing));