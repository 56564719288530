import React from 'react';
import Logo from '../../assets/logo.png';
import LogoVariant from '../../assets/logo-variant.png';
import { Link } from 'react-router-dom';


class Header extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            showMenu : false
        }
    }

    toggleMenu = () =>{
        this.setState((prev)=>({
            showMenu : !prev.showMenu
        }))
    }


    render(){
        const { showMenu } = this.state;

    return (
    <header className={[ showMenu ? "header " : "" , " container max-width"]}>
        <nav className={[ showMenu ? "navbar-pT " : "" , " navbar navbar-expand-md" ]}>
            <Link to={"/"} className="navbar-brand">
                <img src={Logo} className={[ showMenu ? "hide " : "" , " LogoImg" ]} alt="LogoImg" />
                <img src={LogoVariant} className={[ showMenu ? "" : "hide " , "LogoImg LogoVariant" ]} alt="LogoVariant" />
            </Link>

            <button  onClick={this.toggleMenu } className="navbar-toggler collapsed"  type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <svg className={[ showMenu ? "active " : " " , " ham hamRotate ham8"]} viewBox="0 0 100 100" width="50">
                    <path className={[ showMenu ? "stroke " : "" , " line top"]} d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20" />
                    <path className={[ showMenu ? "stroke " : "" , " line middle"]}  d="m 30,50 h 40" />
                    <path className={[ showMenu ? "stroke " : "" , " line bottom"]}  d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20" />
                </svg>
            </button>

            <div className={[ showMenu ? "main " : "" , " collapse navbar-collapse" ]} id="navbarSupportedContent">
                <ul className="navbar-nav ml-auto">
                    <li className="nav-item firstNav">
                        <Link to={"/search-results"} className="nav-link mob-nav-link" >Find a Therapist</Link>
                    </li>
                    <li className="nav-item firstNav">
                        <Link to={"/for-therapist"} className="nav-link mob-nav-link" >For Therapists</Link>
                    </li>
                    <li className="nav-item">
                        <Link to={"/contact-us"} className="nav-link mob-nav-link" >Contact Us</Link>
                    </li>
                </ul>
            </div>
        </nav>
    </header>
    )}
}

export default Header;