import React from 'react';
import { logouttherapist } from '../../store/actions/authActions';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

class NavBar extends React.Component {

    constructor() {
        super();

        this.state = {
            activePage: 0
        }

        if (window.location.href.indexOf("/dashboard") > -1) {
            this.state.activePage = 1;
        }

        else if (window.location.href.indexOf("/listing/add") > -1) {
            this.state.activePage = 2;
        }

        else if (window.location.href.indexOf("/listings") > -1) {
            this.state.activePage = 3;
        }
        else if (window.location.href.indexOf("/transfer") > -1) {
            this.state.activePage = 3;
        }

        else if (window.location.href.indexOf("/billing") > -1) {
            this.state.activePage = 4;
        }

        else if (window.location.href.indexOf("/account") > -1) {
            this.state.activePage = 5;
        }

        else if (window.location.href.indexOf("/notifications") > -1) {
            this.state.activePage = 6;
        }

        else if (window.location.href.indexOf("/help-center") > -1) {
            this.state.activePage = 7;
        }

    }


    render() {

        const { activePage } = this.state;
        return (
            <div className="body-section">
                <nav className="navbar navbar-expand-lg navbar-light bg-white">
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav">
                            <li className={[activePage === 1 && 'active-nav ', " nav-item "]}>
                                <Link className="nav-link" to={"/therapist/dashboard"}>Dashboard</Link>
                            </li>
                            <li className={[activePage === 2 && 'active-nav ', " nav-item "]}>
                                <Link className="nav-link" to={"/therapist/listing/add"}>New Listings</Link>
                            </li>
                            <li className={[activePage === 3 && 'active-nav ', " nav-item "]}>
                                <Link className="nav-link" to={"/therapist/listings"}>My Listings</Link>
                            </li>
                            <li className={[activePage === 4 && 'active-nav ', " nav-item "]}>
                                <Link className="nav-link" to={"/therapist/billing"}>Billing</Link>
                            </li>
                            <li className={[activePage === 5 && 'active-nav ', " nav-item "]}>
                                <Link className="nav-link" to={"/therapist/account"}>Account</Link>
                            </li>
                            <li className={[activePage === 6 && 'active-nav ', " nav-item "]}>
                                <Link className="nav-link" to={"/therapist/notifications"}>Notifications</Link>
                            </li>

                            <li className={[activePage === 7 && 'active-nav ', " nav-item "]}>
                                <Link className="nav-link" to={"/therapist/help-center"}>Help</Link>
                            </li>

                            <li className="nav-item">
                                <Link className="nav-link" to={"#"} onClick={this.props.logouttherapist} >Log Out</Link>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        )
    }
}

NavBar.propTypes = {
    logouttherapist: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
});


export default connect(mapStateToProps, { logouttherapist })(NavBar);