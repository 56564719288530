import React from 'react';
import Header from '../../common/Header';
import Footer from '../../common/Footer';
import ForTherapistBanner from '../../../assets/forTherapistBanner.png';
import RoundTherapists from '../../../assets/roundTherapists.png';
import Checkmark from '../../../assets/checkmark.png';
import { Link } from 'react-router-dom';

import CommonQuestions from './commonQuestions';


class ForTherapist extends React.Component{


    render(){

        return (
        <div className="for-therapist-bg">
            <Header />

            <div className="container-fluid topDistanceDesktop">
                <div className="row">
                    <div className="col-md-12 col-lg-6 p-0 order-2 order-sm-2 order-md-1 therapistBannerContainer">
                        <img src={ ForTherapistBanner } className="w-100 therapistBannerImg" alt="Therapist Banner " />
                    </div>

                    <div className="col-md-12 col-lg-6 order-1 order-sm-1 order-md-2">
                        <div className="therapistTitle">
                            <h1>Build a practice <br className="lineBreakDesktop" />that 
                                <span className="textOrange1"> impacts</span>
                            </h1>

                            <p className="therapistPlainText">Join our network of passionate, licensed <br className="lineBreakDesktop" /> therapists helping racial and ethic <br className="lineBreakDesktop" /> minorities get the professional support <br className="lineBreakDesktop" /> they deserve.</p>

                            <div className="therapistBannerBtn">
                                <Link to={"/sign-up"}>
                                    <button type="button" className="text-white">REGISTER</button>
                                </Link>
                                <Link to={"/login"}>
                                    <button type="button" style={{ marginLeft : 10 }}className="text-white">LOGIN</button>
                                </Link>

                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid whyJoinSection">
                <h1 className="joinSectionTitle">Why join?</h1>
                <div className="container joinSectionContent">
                    <div className="row">
                        <div className="col-md-6 col-lg-4">
                            <div className="joinSections">
                            <p>Tones of therapy is a directory of licensed therapists for racial and ethnic minorities aimed.</p>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-4">
                            <div className="joinSections">
                            <p>Tones of therapy is a directory of licensed therapists for racial and ethnic minorities aimed.</p>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-4">
                            <div className="joinSections">
                            <p>Tones of therapy is a directory of licensed therapists for racial and ethnic minorities aimed.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-1 col-md-3 col-lg-3">
                        
                        </div>
                    
                        <div className="col-12 col-md-6 col-lg-6 text-center">
                            <div className="can-toggle">
                            <input id="a" type="checkbox" />
                            <label htmlFor="a">
                                <div className="can-toggle__switch" data-checked="Annual Billing" data-unchecked="Monthly Billing"></div>
                                <div className="can-toggle__label-text"></div>
                            </label>
                            </div>
                        </div>

                        <div className="col-1 col-md-3 col-lg-3">
                            
                        </div>
                    </div>
                </div>

                <div className="container monthlySection">
                    <div className="row">
                        <div className="col-md-12 col-lg-4">
                            <h3 className="startTitle">Start with 2 month free</h3>
                            <p className="startPlainText">As little as $12.50 / month after trial. Cancel anytime.</p>
                        </div>

                        <div className="col-md-6 col-lg-4">
                            <div className="tierContainer">
                                <div className="tierContent">
                                    <h2 className="tierTitle">Tier 1</h2>
                                    <p className="tierPText">Placeholder Text</p>
                                    <p className="tierPText">Placeholder Text</p>
                                    <p className="tierPText">Placeholder Text</p>
                                    <img src={RoundTherapists} className="w-100 roundImg" alt="RoundTherapists" />
                                </div>

                                <div className="tierBodyContent text-center">
                                    <div className="monthlyPrice text-center">
                                        $15/ Per Month
                                    </div>

                                    <div className="tierCheckText">
                                        <p className="tierExampleText">
                                            <img src={Checkmark} className="checkmarkImg" alt="checkmarkImg" /> 
                                            Line Example 1
                                        </p>
                                        <p className="tierExampleText">
                                            <img src={Checkmark} className="checkmarkImg" alt="checkmarkImg" /> 
                                            Line Example 1
                                        </p>
                                        <p className="tierExampleText">
                                            <img src={Checkmark} className="checkmarkImg"alt="checkmarkImg"  /> 
                                            Line Example 1
                                        </p>

                                        <button type="button" className="freeTrialBtn text-white border-0 w-100">Start Free Trail</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-4">
                            <div className="tierContainer">
                                <div className="tierContent">
                                    <h2 className="tierTitle">Tier 1</h2>
                                    <p className="tierPText">Placeholder Text</p>
                                    <p className="tierPText">Placeholder Text</p>
                                    <p className="tierPText">Placeholder Text</p>
                                    <img src={RoundTherapists} className="w-100 roundImg" alt="Round Therapists" />
                                </div>

                                <div className="tierBodyContent text-center">
                                    <div className="monthlyPrice text-center">
                                        $15/ Per Month
                                    </div>

                                    <div className="tierCheckText">
                                        <p className="tierExampleText">
                                            <img src={Checkmark} className="checkmarkImg" alt="check mark" /> 
                                            Line Example 1
                                        </p>
                                        <p className="tierExampleText">
                                            <img src={Checkmark} className="checkmarkImg" alt="check mark" /> 
                                            Line Example 1
                                        </p>
                                        <p className="tierExampleText">
                                            <img src={Checkmark} className="checkmarkImg" alt="check mark" /> 
                                            Line Example 1
                                        </p>

                                        <button type="button" className="freeTrialBtn text-white border-0 w-100">Start Free Trail</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <CommonQuestions />
            </div>

            <Footer />

        </div>
        )
    }
}

export default ForTherapist;