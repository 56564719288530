import React from 'react';
import { withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import { createKnowledgebaseDetail } from '../../../store/actions/adminActions';
import { Formik, Form, Field,  } from "formik";

import { Row, Card, CardBody, FormGroup, Label, Button } from "reactstrap";
import { Colxx, Separator } from "../../theme/CustomBootstrap";

class AdminAddQuestion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            question: '',
            answer: ''
        };
    }

    static getDerivedStateFromProps(props, state) {

        if(window.location.href.indexOf("/questions") > -1 && props.hist && props.match.path !== props.hist.path  ){
            props.changematch();
            props.history.push(props.hist.path);
        }

        return null
    }

    onChangeHandler = ( e ) => {

        this.setState({
            [e.target.name]: e.target.value
        });     
    }

    handleSubmit = (e) => {
        // e.preventDefault();
        const { question, position, answer } = this.state;
        const formData = {
            question, 
            position : parseInt(position),
            answer
        };
        this.props.createKnowledgebaseDetail(formData, this.props.history);
    }
      

    render() {
        const { question, position, answer } = this.state;

    return(
    <React.Fragment>
        <Row>
            <Colxx xxs="12">
                <h1 className="h1-theme">Add Knowledgebase</h1>
                <Separator className="mb-5" />
            </Colxx>
        </Row>

        <Row className="mb-4">
            <Colxx lg="8" md="12">
            <Card>
                <CardBody>
                
                <Formik
                    initialValues={{
                        name: "",
                        email: ""
                    }}
                    onSubmit={this.handleSubmit}>

                    {({ errors, touched }) => (
                    <Form className="av-tooltip tooltip-label-right">
                        <Label className="label-theme">Question</Label>

                        <FormGroup>
                        
                        <Field
                            className="form-control-theme"
                            name="question"
                            value={question || ''}
                            onChange={ this.onChangeHandler }
                            placeholder="Enter Question" 
                            rows="2"
                            validate={this.validateName}
                        />
                        {errors.name && touched.name && (
                            <div className="invalid-feedback d-block">
                            {errors.name}
                            </div>
                        )}
                        </FormGroup>



                        <Label className="label-theme">Position</Label>

                        <FormGroup>
                        
                        <Field
                            className="form-control-theme"
                            name="position"
                            value={position || ''}
                            onChange={this.onChangeHandler }
                            placeholder="Enter Question"
                        />
                        
                        {errors.name && touched.name && (
                            <div className="invalid-feedback d-block">
                            {errors.name}
                            </div>
                        )}
                        </FormGroup>                      

                        <Label className="label-theme">Answer</Label>
                        
                        <FormGroup>
                        <Field
                            className="form-control-theme"
                            name="answer"
                            component="textarea"
                            validate={this.validateEmail}
                            onChange={ this.onChangeHandler }
                            placeholder="Enter Answer" 
                            value={answer || ''}
                            rows="6"
                        />
                        {errors.email && touched.email && (
                            <div className="invalid-feedback d-block">
                            {errors.email}
                            </div>
                        )}
                        </FormGroup>

                        <Button color="primary" type="submit">
                            Submit
                        </Button>
                    </Form>
                    )}
                </Formik>
                </CardBody>
            </Card>
            </Colxx>
        </Row>
    </React.Fragment>
        )
    }
}

  
export default connect(null, { createKnowledgebaseDetail })(withRouter(AdminAddQuestion));