import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Link, withRouter} from 'react-router-dom';
import { getListings, cancelSubscription, toggleLsiting } from '../../../store/actions/therapistActions';

class MyListings extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            listings : []
        }
        
    }

    static getDerivedStateFromProps(props, state) {

        let { listings }  = props.therapist;

        let changedState = {};
        let stateChanged = false;

        if(listings && JSON.stringify(state.listings) !== JSON.stringify(listings)){
            changedState.listings = listings;
            stateChanged = true;
        }

        if(stateChanged){
            return changedState;
        }

        return null;
    }

    componentDidMount(){
        this.props.getListings();
    }


    render() {
        const {  listings } = this.state;
        
    return (
    <div className="listing-container-content">
        <div className="my-listing-Container">
            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th scope="col">id</th>
                        <th scope="col">Name</th>
                        <th scope="col">Email</th>
                        <th scope="col" className="subs-Coulumn">Website</th>
                        <th scope="col">Visits</th>
                        <th scope="col" className="edit-Coulumn">Edit Listing</th>
                        <th scope="col" className="edit-Coulumn">Listing Actions</th>
                        <th scope="col" className="cancel-Coulumn">Subscription Actions</th>
                        <th scope="col" className="cancel-Coulumn">View Connections</th>
                        <th scope="col" className="cancel-Coulumn">Transfer listing</th>
                    </tr>
                </thead>
                <tbody>  
                    { listings.length ? listings.map(( list, index )=>(
                        <tr key={index}>
                            <td> { list.listing_id }</td>
                            <td >{ list.practice_name }</td>
                            <td >{ list.email }</td>
                            <td >{ list.website } </td>
                            <td >{ list.visits } </td>
                            <td >
                                <Link to={`/therapist/listing/edit/${list.listing_id}`}className="btn btn-info">Edit Listing</Link>
                            </td>
                            <td >
                                { list.is_active ? <button onClick={() => this.props.toggleLsiting(list.listing_id)} type="button" className="btn btn-danger">Deativate Lisitng</button>
                                : <button onClick={() => this.props.toggleLsiting(list.listing_id)} type="button" className="btn btn-success">Ativate Lisitng</button>
                                }
                            </td>
                            <td>
                                { list.stripe_subscription_id ? <button onClick={() => this.props.cancelSubscription(list.listing_id)} type="button" className="btn btn-danger">Cancel Subscription</button>
                                : <Link to={'/therapist/renew-subscription/'+list.listing_id } className="btn btn-success">Renew Subscription</Link>
                                }
                            </td>

                            <td>
                                <Link to={`/therapist/connections/${list.listing_id}`}className="btn btn-info">View Connections</Link>
                            </td>
                            <td>
                                <Link to={`/therapist/transfer/${list.listing_id}`}className="btn btn-info">Transfer listing</Link>
                            </td>
                            
                        </tr>
                    )): 
                        <tr>
                            <td colSpan="9" style={{ textAlign : 'center'}} > No data found! </td>
                        </tr>}
                </tbody>
            </table>
        </div>
    </div>
    )
    }
}

MyListings.propTypes = {
    getListings: PropTypes.func.isRequired,
    cancelSubscription: PropTypes.func.isRequired,
    toggleLsiting:  PropTypes.func.isRequired,
    therapist: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    therapist: state.therapist
});

export default connect(mapStateToProps,{ getListings , cancelSubscription, toggleLsiting })(withRouter(MyListings));