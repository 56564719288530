import React from 'react';
import { Link } from 'react-router-dom';


class Footer extends React.Component{

    render(){

    return (
    <footer className="f-bg"> 
        <div className="container-fluid">
            <div className="row brdr-dv">
                <div className="col-xl-7 col-lg-7 col-md-12 bss-333">
                    <span className="d-p-01">Need help finding the right therapist? Have questions about joining our network of therapists? Send us a note.
                        </span>
                </div>

                <div className="col-xl-2 col-lg-2 col-md-12 bss-002">
                    <Link to={"/contact-us"}>
                        <button type="button" className="cntct-bttn">
                            <i className="fa fa-telegram" aria-hidden="true"></i>
                            <span>CONTACT US</span>
                        </button>
                    </Link>
                </div>
            </div>

            <div className="row bss-440">
                <div className="col-xl-9 col-lg-8 col-md-12 col-sm-12 col-12 dvs-007">
                    <p className="bsp-110">Tones of therapy is a directory of licensed therapists for racial and ethnic minorities aimed at removing the burden of accessibility and stigma of mental health while building meaningful <br clear="bss-320" />client-therapist relationships.
                    </p>
                    <div className="icns-001">
                        <div>
                            <i className="fa fa-facebook " aria-hidden="true"></i>
                        </div>
                        <div>
                            <i className="fa fa-twitter" aria-hidden="true"></i>
                        </div>
                        <div>
                            <i className="fa fa-dribbble" aria-hidden="true"></i>
                        </div>
                        <div>
                            <i className="fa fa-pinterest" aria-hidden="true"></i>
                        </div>
                        <div>
                            <i className="fa fa-vimeo" aria-hidden="true"></i>
                        </div>
                    </div>
                </div>
                    
                <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12 col-12 dvs-008">
                    <div className="lnk-001">
                        Subscribe
                    </div>

                    <div className="sn-112">
                        <span>Stay up to date on all that's happening in our community</span>
                    </div>

                    <div className="bss-004">
                        <input className="bss-220" type="text" placeholder="Subscribe in Our Newsletter" />
                        <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
            <hr className="brdr-006" />

            <div className="row">
                <div className="col-lg-7 col-sm-12 bss-223"> 
                    <div className="ftr-txt"> 
                        <span>Copyrights </span>
                        <i className="fa fa-copyright" aria-hidden="true"></i>
                        <span> Tones of Therapy</span>
                    </div>
                </div>
    
                <div className="col-lg-5 col-md-12 col-sm-12 bss-225 ">
                    <div className="dv-022"> 
                        <Link to={"#"} >Terms of use</Link>
                    </div>

                    <div className="brdr-rght-01">|</div>
        
                    <div className="dv-022"> 
                        <Link to={"#"}>Privacy Policy</Link>
                    </div>

                    <div className="brdr-rght-02">|</div>
        
                    <div className="dv-022"> 
                        <Link to={"#"}>Cookies</Link>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    )}
}

export default Footer;