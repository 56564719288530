import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import SideBar from './SideBar';
import Header from './Header';
import Footer from '../../common/Footer';
import { searchResults } from '../../../store/actions/publicActions';
import SearchResult from './SearchResult';
import Spinner from '../../common/Spinner';

class SearchResults extends React.Component{

    constructor(props) {
        super(props);

        const search =new URLSearchParams(this.props.location.search).get('search') 

        this.state = {
            listing : [],
            find :  search ? search : '',
            index : 0,
            limit : 12,
            lastIndex : '',
            selected : []
        };
    }

    componentDidMount(){
        this.filterResults();
    }

    handleCheck = (id,value) => {

        if(value){
            this.setState( (state) => ({
                selected : [...state.selected, id]
            }),()=>{
                this.filterResults()
            })
        } else {
            var newSelected = [...this.state.selected];

            var index = this.state.selected.indexOf(id);

            newSelected.splice(index, 1);

            this.setState( {
                selected : newSelected
            },()=>{
                this.filterResults()
            })
        }

    }

    filterResults = () =>{

        const fetchData = {
            offset : this.state.index*this.state.limit ,
            limit : this.state.limit,
            sub_categories:  this.state.selected,
            find : this.state.find,
            hostname : window.location.hostname
        }

        this.props.searchResults(fetchData)
    }

    nextClick = () => {

        if( this.state.index !== this.state.lastIndex ){
            this.setState((prev) => ({
                index : prev.index + 1
            }),()=>{
                this.filterResults()
            })
        }
    }
 
    prevClick = () => {

        if(this.state.index !== 0){
            this.setState((prev) => ({
                index : prev.index-1
            }),()=>{
                this.filterResults()
            })
        }
    }

    showPage = (index) =>{
        this.setState({
            index : index
        },()=>{
            this.filterResults()
        })
    }


    static getDerivedStateFromProps(props, state) {

        let { listing }  = props.public;

        let changedState = {};
        let stateChanged = false;

        if(listing && JSON.stringify(state.listing) !== JSON.stringify(listing)){
            changedState.listing = listing;
            changedState.lastIndex = Math.floor((listing.length)/state.limit)
            stateChanged = true;
        }

        if(stateChanged){
            return changedState;
        }

        return null;
    }

    onChange = e => {

        this.setState({
            [e.target.name]: e.target.value
        },()=>{
            this.filterResults()
        })
    }

    render(){
        const { listing , lastIndex , index } = this.state;
    

        let pagination = '';

        if(lastIndex && lastIndex < 7 ){
             
            pagination = <div className="paginationContent">
            <div onClick={ this.prevClick }>
                <i className="fa fa-long-arrow-left" aria-hidden="true"></i>
            </div> 

            <div className="paginationNumbers">
                {[...Array(lastIndex)].map((x, index) =>
                    <div key={index} onClick={()=> {this.showPage( lastIndex-1) } }  className={x===lastIndex-1 && 'activepage' }>{x}</div>
                )}
            </div>

            <div onClick={this.nextClick}>
                <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
            </div>
        </div>
        }
        else if(lastIndex >= 7){
            pagination = <div className="paginationContent">
            <div onClick={ this.prevClick }>
                <i className="fa fa-long-arrow-left" aria-hidden="true"></i>
            </div> 

            <div className="paginationNumbers">
                <div onClick={()=> {this.showPage( 0 ) } }  className={0===index && 'activepage' }>1</div>
                <div onClick={()=> {this.showPage( 1 ) } }  className={1===index && 'activepage' }>2</div>
                <div onClick={()=> {this.showPage( 2 ) } }  className={2===index && 'activepage' }>3</div>
                    { index < 3 || index > lastIndex-3 ? <div>...</div> : <div>{index}</div> }
                <div onClick={()=> {this.showPage( lastIndex-2 ) } }  className={index===lastIndex-2 && 'activepage' }>{ lastIndex-2 }</div>
                <div onClick={()=> {this.showPage( lastIndex-1 ) } }  className={index===lastIndex-1 && 'activepage' }>{ lastIndex-1 }</div>
                <div onClick={()=> {this.showPage( lastIndex ) } }  className={index===lastIndex && 'activepage' }>{ lastIndex }</div>
            </div>

            <div onClick={this.nextClick}>
                <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
            </div>
        </div>

        }
    return (
    <div className="search-results-bg">
        <Spinner />
        <Header />   
        <div className="container full-width text-white searchResultMainContainer">
            <div className="row">
    
                <SideBar 
                    handleCheck={ this.handleCheck } 
                    selected={ this.state.selected}
                    onChange={ this.onChange  }  
                    find= { this.state.find }
                />

                <div className="col-md-8 col-lg-9 custom-padding-r">
                    <h2 className="search-result-title">Search Results</h2>
            
                    <div className="searchResultContainer">
                        <div className="row">  

                            { listing.length ? 
                                listing.map(list=>(
                                    <SearchResult key={list.listing_id} profile={list} />
                                ))
                                : ''
                            }
                            
                            {pagination}


                        </div>
                    </div>
                </div>
            </div>
        </div>
    <Footer />
    </div>
    )}
    
}

SearchResults.propTypes = {
    searchResults: PropTypes.func.isRequired,
    public: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    public: state.public
});

export default connect(mapStateToProps,{ searchResults })(withRouter(SearchResults));