import React from 'react';
import {BrowserRouter as Router , Route,  withRouter, Switch, Redirect} from 'react-router-dom';


// import Admindashboard from './home';
import Admindashboard from './dashboard';
import AdmindashboardSupportTickets from './supportTicket';
import AdmindashboardAccounts from './accounts';
import AdminCategories from './categories';
import AdminAddCategory from './categories/addCategory';
import AdminEditCategory from './categories/editCategory';
import AdminQuestions from './questions';
import AdminEditQuestion from './questions/editQuestion';
import AdminAddQuestion from './questions/addQuestion';
 

class AdminInnerRoutes extends React.Component {

    constructor(props){
		super(props);
		
		this.state = {
            prefix : "/admin",
			match : ''
		}
    }
    
    componentWillReceiveProps(nextProps) {
		this.setState({ match: nextProps.match } );	
	}

	changematch = () =>{
		this.setState({match: ''});
    }
    

	render(){
        const { prefix, match } = this.state;

		return (
			<Router >
            <Switch>
            <Route
                exact 
                path={prefix+"/dashboard"} 
                component= { Admindashboard } 
            />

            <Route
                exact 
                path={prefix+"/support-ticket"} 
                component= { AdmindashboardSupportTickets } 
            />

            <Route
                exact 
                path={prefix+"/accounts"} 
                component= { AdmindashboardAccounts } 
            />

            <Route
                exact 
                path={prefix+"/categories"} 
                component= {()=> <AdminCategories 
                            hist={ match }
                            changematch={this.changematch} 
                        />
                    } 
            />

           


            <Route
                exact 
                path={prefix+"/edit-question/:id"} 
                component= {() => <AdminEditQuestion 
                        hist={ match }
                        changematch={this.changematch} 
                    />
                } 
            />

            <Route 
                exact
                path={prefix+"/add-question"}
                component= {()=> <AdminAddQuestion 
                        hist={ match }
                        changematch={this.changematch} 
                    />
                }
            />

            <Route
                exact 
                path={prefix+"/questions"} 
                component= { AdminQuestions } 
            />

            <Route
                exact 
                path={prefix+"/add-category"} 
                component= {()=> <AdminAddCategory 
                        hist={ match }
                        changematch={this.changematch} 
                    />
                } 
                
            />

            <Route
                exact 
                path={prefix+"/edit-category/:id"} 
                component= {()=> <AdminEditCategory 
                        hist={ match }
                        changematch={this.changematch} 
                    />
                }
            />
            <Redirect to={ prefix+"/dashboard"} />
            </Switch>
			</Router>
		);
	}
}

export default withRouter(AdminInnerRoutes);