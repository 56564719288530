import React from 'react';

class PracticeDetails extends React.Component {

    render() { 
        const { filters, selected, onSubmit, handleCheck } = this.props;

    return (
    <form className="listingLocationContent" onSubmit={ onSubmit } encType="multipart/form-data">
        <h2 className="form-heading">Practice Details</h2>
        <div className="form-group">

        { filters.length ? filters.map((filter, index) => {
            return (
                <div key={index} className="filterResultTitle">
                    <h4 className="subheading">{ filter.category }</h4>

                    { filter.sub_categories.map( (attribute, index2) =>{

                        const value = selected.find(element => element.sub_category_id === attribute.sub_category_id ) ? true : false;  

                        return(
                            <div key={index2} className="form-check">
                                <input 
                                    className="form-check-input" 
                                    type="checkbox"
                                    id={ attribute.sub_category }
                                    checked={ value }
                                    onChange={(e) => handleCheck( attribute.category_id, attribute.sub_category_id ,e.target.checked)}
                                />
                                
                                <label className="form-check-label" htmlFor={ attribute.sub_category }>
                                    { attribute.sub_category } 
                                </label>
                            </div>
                        )
                        })
                    } 

                        {/* { filter.multipal ? 
                            filter.sub_categories.map( (attribute, index2) =>{

                                const value = selected.find(element => element === attribute.sub_category_id ) ? true : false;  
                                
                                return(
                                    <div key={index2} className="form-check">
                                        <input 
                                            className="form-check-input" 
                                            type="checkbox"
                                            id={ attribute.sub_category }
                                            checked={ value }
                                            onChange={(e) => handleCheck( attribute.sub_category_id ,e.target.checked)}
                                        />
                                        
                                        <label className="form-check-label" htmlFor={ attribute.sub_category }>
                                            { attribute.sub_category } 
                                        </label>
                                    </div>
                                )
                            }) 
                            :
                            <div onChange={radioCheck} >
                                { filter.sub_categories.map( (attribute,index2) =>(
                                        <div key={index2} className="form-check">
                                            <input 
                                                className="form-check-input" 
                                                type="radio"
                                                name={ filter.category_id }
                                                value={ attribute.sub_category_id }
                                                id={ attribute.sub_category_id }
                                                required
                                            />
                                            
                                            <label className="form-check-label" htmlFor={ attribute.sub_category_id }>
                                                { attribute.sub_category } 
                                            </label>
                                        </div>
                                    )
                                ) }
                            </div>
                        } */}
                    </div>

                ) } 
            ) : '' 
        } 
        
        <button type="submit" className="NextBtn">Next</button>

        </div>

    </form>) 
    }
}

export default PracticeDetails;