import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import { Link } from 'react-router-dom';
import { getUsers , deleteUser } from '../../../store/actions/superAdminActions';

class SuperAdminUsers extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            users: {}
        };
    }

    static getDerivedStateFromProps(props, state) {

        let { users } = props.superAdmin;
        
        if(users && JSON.stringify(state.users) !== JSON.stringify(users)){
            return{
                users : users
            }
        } 
    }
      
    componentDidMount() {
        this.props.getUsers();
    }
      
    applicationStatusHandler = ( id, status ) => {
        const formData = {
            application_id: id,
            is_active: !status
        }

        this.props.applicationStatusHandler( formData );
    }

    render() {

        const { users } = this.state;

    return(
    <div className="listing-container-content">
        <div className="add-application-btnContainer">
            <Link to={"/super-admin/user/add"}>
                <button type="button" className="add-category-Btn">Add User</button>
            </Link>
        </div>

        <div className="my-listing-Container">
            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th scope="col">id</th>
                        <th scope="col">Applicaiton</th>
                        <th scope="col">First Name</th>
                        <th scope="col">Last Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">Role</th>
                        <th scope="col">Ip White List</th>
                        <th scope="col" style={{width: 245 }}>Actions</th>
                    </tr>
                </thead>
                <tbody>  
                    { users.length ? users.map(( user, index )=>(
                        <tr key={index}>
                            <td> { user.application_login_id }</td>
                            <td> { user.application.application_name }</td>
                            <td >{ user.first_name }</td>
                            <td >{ user.last_name }</td>
                            <td >{ user.email }</td>
                            <td >{ user.role } </td>
                            <td >{ user.ip_whitelist } </td>
                            <td>
                                <button type="button" onClick={() => this.props.deleteUser({ userId : user.application_login_id }) }  className="btn btn-primary enable-disable-btn-info disable"> Delete </button>
                                <Link to={`/super-admin/user/edit/${user.application_id}`} className="btn btn-info edit-btn-info">EDIT</Link>
                            </td>
                        </tr>
                    )): 
                        <tr>
                            <td colSpan="6" style={{ textAlign : 'center'}} > No data found! </td>
                        </tr>}
                </tbody>
            </table>
        </div>
    </div>
    )
    }
}


SuperAdminUsers.propTypes = {
    getUsers: PropTypes.func.isRequired,
    deleteUser: PropTypes.func.isRequired,
    superAdmin: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    superAdmin: state.superAdmin
});

  
export default connect(mapStateToProps, { getUsers, deleteUser })(SuperAdminUsers);