import React from 'react';
import { Link } from 'react-router-dom';

import {connect} from 'react-redux';
import { getKnowledgebases, deleteKnowledgebase } from '../../../store/actions/adminActions';

import {
    Row,
    Collapse,
    Card,
    Button
} from "reactstrap";

import { Colxx , Separator } from "../../theme/CustomBootstrap";


class SupportTicketQuestions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            knowledgebases: [],
            accordion : 0
        };
    }

    static getDerivedStateFromProps(props, state) {

		let knowledgebases = props.knowledgebases ? props.knowledgebases : [];
	  
	  
		let stateChanged = false;
		let changedState = {};
	  
		if(knowledgebases && JSON.stringify(state.knowledgebases) !== JSON.stringify(knowledgebases)){
		  changedState.knowledgebases= knowledgebases;
		  stateChanged = true; 
		}

        if(stateChanged){
            return changedState;
        }
	  
	  
		return null;
    }
      
    deleteHandler = (id) => {
        this.props.onDeleteKnowledgebase(id);
    }
      

    componentDidMount() {
      this.props.onGetKnowledgebase();
    }

    toggleAccordion = tab => {

        this.setState({
            accordion: tab
        });
    };


    render() {
        const { knowledgebases } = this.state;
        
        return(
        <React.Fragment>
            <Row>
                <Colxx xxs="12">
                    {/* <Breadcrumb heading="Accounts" match={this.props.match} /> */}
                    <h1 className="h1-theme">Knowledgebase</h1>
                    <div className="text-zero top-right-button-container">
                    <Link to={"/admin/add-question"}>
                        <Button color="success">Add Question</Button>
                    </Link>

                    </div>
                    <Separator className="mb-5" />
                </Colxx>
            </Row>


            <Colxx xxs="12" className="mb-4">
                    {
                        knowledgebases.map((item, index) =>{
                            return (
                                <Card className="d-flex mb-3" key={index}>
                                    <div className="d-flex flex-grow-1 min-width-zero">
                                        <Button color="link" className="card-body  btn-empty btn-link list-item-heading text-left text-one"
                                            onClick={() => this.toggleAccordion(item.knowledgebase_id )}
                                            aria-expanded={this.state.accordion === item.knowledgebase_id }>
                                            {item.question}
                                        </Button>
                                        <div className="accordion-button">
                                            <Link to={`/admin/edit-question/${item.knowledgebase_id}`}>
                                                <Button color="secondary">Edit</Button>
                                            </Link>

                                            <Button color="danger" onClick={() => this.deleteHandler(item.knowledgebase_id)}>Delete</Button>
                                        </div>
                                    </div>
                                    <Collapse isOpen={this.state.accordion === item.knowledgebase_id }>
                                        <div className="card-body accordion-content pt-0" dangerouslySetInnerHTML={{__html: item.answer}}/>
                                    </Collapse>
                                </Card>
                            )
                        })
                    }
            </Colxx>

                {/* <div className="knowledge-btnContainer">
                    <Link to={"/admin/add-question"}>
                        <button type="button" className="knowledge-base-btn">Add Question</button>
                    </Link>
                </div> */}

                {/* <div className="knowledge-base-page">
                        <h1 className="pageMainTitle">Knowledgebase</h1>
                        <div className="knowledge-base-section">
                            <div className="padding">
                                <div className="accordion" id="accordionExample">

                                    {knowledgebases && knowledgebases.length ? knowledgebases.map( ( data, idx ) => (
                                        <div className="card" key={idx}>
                                        <div className="card-header" id="headingOne" data-toggle="collapse" data-target={"#collapse"+idx} aria-expanded="true" aria-controls="collapseOne">
                                            <h2 className="mb-0">
                                                <button className="btn btn-link" type="button">
                                                {data && data.question ? data.question : ''}
                                                </button>
                                            </h2>
                                            <div className="action-btn">
                                                <Link to={`/admin/edit-question/${data.knowledgebase_id}`}>
                                                    <button type="button" className="btn edit-btn">Edit</button>
                                                </Link>
                                                <button type="button" onClick={() => this.deleteHandler(data.knowledgebase_id)} className="btn delete-btn">Delete</button>
                                            </div>
                                        </div>

                                        <div id={"collapse"+idx} className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                                            <div className="card-body">
                                                {data && data.answer ? data.answer : ''}
                                            </div>
                                        </div>
                                    </div>
                                    
                                    )) : '' }
                                    
                                    
                                </div>
                            </div>
                            
                        </div>
                    </div> */}
            </React.Fragment>
        )
    }
}


const mapStateToProps = state => {
	return {
		knowledgebases: state.adminPage.knowledgebases,
	}
};
  
const mapDispatchToProps = dispatch => {
	return {
		onGetKnowledgebase: () => dispatch(getKnowledgebases()),
		onDeleteKnowledgebase: (id) => dispatch(deleteKnowledgebase(id)),
	}
};
  
  
  export default connect(mapStateToProps, mapDispatchToProps)(SupportTicketQuestions);
