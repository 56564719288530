import React from 'react';
import { Link , withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import { getAccountDetails } from '../../../store/actions/therapistActions';
import { editAccountDetails, closeAccount, setError } from '../../../store/actions/therapistActions';

class Account extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            accountDetails : {},
            first_name : '',
            last_name: '',
            display_name: '',
            email : '',
            oldPassword : '',
            newPassword : '',
            confirmPassword : ''

        }
        
    }


    static getDerivedStateFromProps(props, state) {

        let { accountDetails }  = props.therapist;

        let changedState = {};
        let stateChanged = false;

        if(accountDetails && JSON.stringify(state.accountDetails) !== JSON.stringify(accountDetails)){
            changedState.accountDetails = accountDetails;
            changedState.first_name = accountDetails.first_name;
            changedState.last_name = accountDetails.last_name;
            changedState.display_name = accountDetails.display_name;
            changedState.email = accountDetails.email;
            stateChanged = true;
        }

        if(stateChanged){
            return changedState;
        }

        return null;
    }

    componentDidMount(){
        this.props.getAccountDetails();
    }

    onSubmit = e => {
    	e.preventDefault();

        const data = {
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            display_name: this.state.display_name,
            email: this.state.email
        };

        if(this.state.oldPassword.length ){

            if(!this.state.newPassword.length || this.state.newPassword !== this.state.confirmPassword){
                this.props.setError('Password Not Match')
                return
            }
            
            else{
                data.oldPassword =  this.state.oldPassword;
                data.newPassword = this.state.newPassword;
            }
        }
        
        this.props.editAccountDetails(data);

  	}

  	onChange = e => {
    	this.setState({[e.target.name]: e.target.value});
    }
      
    render() {
        const { first_name, last_name, display_name, email , oldPassword, newPassword, confirmPassword } = this.state;

        return(
        <div className="accountDetailFormContainer">
            <form className="accountDetailForm" onSubmit={this.onSubmit }>
                <div className="form-group">
                    <div>
                        <div className="user-icon">
                            <i className="fa fa-user-circle-o" aria-hidden="true"></i>
                        </div>
                            
                        <h2 className="form-heading">Account Detail</h2>
                    </div>

                    <label htmlFor="exampleInputEmail1">First Name</label>
                    <input 
                        type="text" 
                        className="form-control"
                        name="first_name"
                        value={ first_name }
                        onChange={ this.onChange }
                        required
                    />

                    <label htmlFor="exampleInputEmail1">Last Name</label>
                    <input 
                        type="text" 
                        className="form-control"
                        name="last_name"
                        value={ last_name }
                        onChange={ this.onChange }
                        required
                    />

                    <label htmlFor="exampleInputEmail1">Display Name</label>
                    <input 
                        type="text" 
                        className="form-control"
                        name="display_name"
                        value={ display_name }
                        onChange={ this.onChange }
                        required
                    />

                    <label htmlFor="exampleInputEmail1">Email address</label>
                    <input 
                        type="email" 
                        className="form-control"
                        name="email"  
                        value={email }
                        onChange={ this.onChange }
                        required
                    />

                </div>
            
                <div className="form-group">
                    <h2 className="form-password">Password</h2>
                    <small id="emailHelp" className="form-text text-muted">Current Password (leave blank to leave unchanged)</small>
                    <input 
                        type="password" 
                        className="form-control" 
                        name="oldPassword"    
                        value={ oldPassword }
                        onChange={ this.onChange }  
                    />

                    <small id="emailHelp" className="form-text text-muted">New Password </small>
                    <input 
                        type="password" 
                        className="form-control"
                        name="newPassword"  
                        value={newPassword}
                        onChange={ this.onChange }  
                    />

                    <small id="emailHelp" className="form-text text-muted">Confirm New Password</small>
                    <input 
                        type="password" 
                        className="form-control"
                        name="confirmPassword"
                        value={confirmPassword}  
                        onChange={ this.onChange }  
                    />
                </div>
                <p>
                    <Link to={"#"} className="accnt-lnks">Transfer Account</Link>
                </p>
                <p>
                    <Link to={"#"} onClick={ this.props.closeAccount } className="accnt-lnks">Close Account</Link>
                </p>
                
                <button type="submit" className="btn btn-primary">Save</button>
            </form>
        </div>
        );
    }
}

Account.propTypes = {
    getAccountDetails: PropTypes.func.isRequired,
    editAccountDetails: PropTypes.func.isRequired,
    closeAccount: PropTypes.func.isRequired,
    setError: PropTypes.func.isRequired,
    therapist: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    therapist: state.therapist
});

export default connect(mapStateToProps,{ getAccountDetails , editAccountDetails, closeAccount, setError})(withRouter(Account));