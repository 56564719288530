import React, { Component } from "react";

import {
    UncontrolledDropdown,
    DropdownItem,
    DropdownToggle,
    DropdownMenu
} from "reactstrap";
import { Link } from 'react-router-dom';

import { NavLink } from "react-router-dom";
import { connect } from "react-redux";

import MenuIcon  from "./MenuIcon";

import {
    clickOnMobileMenu
} from "../../store/actions/menuActions";
import { logoutAdmin } from '../../store/actions/adminAuthActions';

import {
    menuHiddenBreakpoint
  } from "../../constants/defaultValues";

class TopNav extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isInFullScreen: false,
            searchKeyword: ""
        };
    }

    isInFullScreen = () => {
        return (
            (document.fullscreenElement && document.fullscreenElement !== null) ||
            (document.webkitFullscreenElement &&
            document.webkitFullscreenElement !== null) ||
            (document.mozFullScreenElement &&
            document.mozFullScreenElement !== null) ||
            (document.msFullscreenElement && document.msFullscreenElement !== null)
        );
    };

    handleSearchIconClick = e => {
        if (window.innerWidth < menuHiddenBreakpoint) {
            let elem = e.target;
            if (!e.target.classList.contains("search")) {
                if (e.target.parentElement.classList.contains("search")) {
                    elem = e.target.parentElement;
                } else if (
                    e.target.parentElement.parentElement.classList.contains("search")
                ) {
                    elem = e.target.parentElement.parentElement;
                }
            }

            if (elem.classList.contains("mobile-view")) {
                this.search();
                elem.classList.remove("mobile-view");

                this.removeEventsSearch();
            } else {
                elem.classList.add("mobile-view");
                this.addEventsSearch();
            }
        } else {
            this.search();
        }
    };

    addEventsSearch = () => {
        document.addEventListener("click", this.handleDocumentClickSearch, true);
    };

    removeEventsSearch = () => {
        document.removeEventListener("click", this.handleDocumentClickSearch, true);
    };

    handleDocumentClickSearch = e => {
    let isSearchClick = false;
    if (
    e.target &&
    e.target.classList &&
    (e.target.classList.contains("navbar navbar-theme") ||
    e.target.classList.contains("simple-icon-magnifier"))
    ) {
    isSearchClick = true;
    if (e.target.classList.contains("simple-icon-magnifier")) {
    this.search();
    }
    } else if (
    e.target.parentElement &&
    e.target.parentElement.classList &&
    e.target.parentElement.classList.contains("search")
    ) {
    isSearchClick = true;
    }

    if (!isSearchClick) {
    const input = document.querySelector(".mobile-view");
    if (input && input.classList) input.classList.remove("mobile-view");
    this.removeEventsSearch();
    this.setState({
    searchKeyword: ""
    });
    }
    };

    handleSearchInputChange = e => {
        this.setState({
            searchKeyword: e.target.value
        });
    };

    handleSearchInputKeyPress = e => {
        if (e.key === "Enter") {
            this.search();
        }
    };

    search = () => {
        // this.props.history.push(searchPath + "/" + this.state.searchKeyword);
        this.setState({
            searchKeyword: ""
        });
    };

    toggleFullScreen = () => {
        const isInFullScreen = this.isInFullScreen();

        var docElm = document.documentElement;
        if (!isInFullScreen) {
            if (docElm.requestFullscreen) {
                docElm.requestFullscreen();
            } else if (docElm.mozRequestFullScreen) {
                docElm.mozRequestFullScreen();
            } else if (docElm.webkitRequestFullScreen) {
                docElm.webkitRequestFullScreen();
            } else if (docElm.msRequestFullscreen) {
                docElm.msRequestFullscreen();
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }
        }
        this.setState({
            isInFullScreen: !isInFullScreen
        });
    };

    handleLogout = () => {
    // this.props.logoutUser(this.props.history);
    };

    menuButtonClick = (e, menuClickCount, containerClassnames) => {
        e.preventDefault();

        setTimeout(() => {
         var event = document.createEvent("HTMLEvents");
            event.initEvent("resize", false, false);
            window.dispatchEvent(event);
        }, 350);

        this.props.toggleMenu()
    };

    mobileMenuButtonClick = (e, containerClassnames) => {
        e.preventDefault();
        this.props.clickOnMobileMenu(containerClassnames);
    };

    render() {
        const { containerClassnames, menuClickCount } = this.props;
    return (
    <nav className="navbar navbar-theme fixed-top">
        <div className="d-flex align-items-center navbar-left">
            <NavLink
                to="#"
                location={{}}
                className="menu-button"
                onClick={e =>
                    this.menuButtonClick(e, menuClickCount, containerClassnames)
                }
            >
                <MenuIcon />
            </NavLink>

            {/* <NavLink
                to="#"
                location={{}}
                className="menu-button-mobile d-xs-block d-sm-block d-md-none"
                onClick={e => this.mobileMenuButtonClick(e, containerClassnames)}
            >
                <MobileMenuIcon />
            </NavLink> */}

            {/* <div className="search" data-search-path="/app/pages/search">
                <Input
                    name="searchKeyword"
                    id="searchKeyword"
                    placeholder="search"
                    value={this.state.searchKeyword}
                    onChange={e => this.handleSearchInputChange(e)}
                    onKeyPress={e => this.handleSearchInputKeyPress(e)}
                />
                <span
                    className="search-icon"
                    onClick={e => this.handleSearchIconClick(e)}
                >
                    <i className="simple-icon-magnifier" />
                </span>
            </div> */}

            
            
        </div>

        <Link className="navbar-logo" to="/admin/dashboard">
            <span className="logo d-none d-xs-block" />
            <span className="logo-mobile d-block d-xs-none" />
        </Link>

        <div className="navbar-right">
            <div className="header-icons d-inline-block align-middle">
                {/* <TopnavEasyAccess />
                <TopnavNotifications /> */}

                <button
                    className="header-icon btn btn-empty d-none d-sm-inline-block"
                    type="button"
                    id="fullScreenButton"
                    onClick={this.toggleFullScreen}
                >
                    {this.state.isInFullScreen ? (
                        <i className="simple-icon-size-actual d-block" />
                    ) : (
                        <i className="simple-icon-size-fullscreen d-block" />
                    )}
                </button>
                
            </div>
            <div className="user d-inline-block">
                <UncontrolledDropdown className="dropdown-menu-right">
                    <DropdownToggle className="p-0" color="empty">
                        <span className="name mr-1">Sarah Kortney</span>
                        <span>
                            <img alt="Profile" src="/assets/img/profile-pic-l.jpg" />
                        </span>
                    </DropdownToggle>

                    <DropdownMenu className="mt-3" right>
                        <DropdownItem divider />
                        <DropdownItem onClick={() => this.props.logoutAdmin()}>
                            Sign out
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
                </div>
        </div>
    </nav>
    );
    }
}

const mapStateToProps = ({ menu }) => {
    const { containerClassnames, menuClickCount, selectedMenuHasSubItems } = menu;

    return {
        containerClassnames,
        menuClickCount,
        selectedMenuHasSubItems,
    };
};

export default connect(
    mapStateToProps,
    { clickOnMobileMenu, logoutAdmin }
)(TopNav)