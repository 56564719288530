import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import { contactUs } from '../../../store/actions/publicActions';
import Header from '../../common/Header';
import Footer from '../../common/Footer';
import AlertMessage from '../../common/Alert';
import Spinner from '../../common/Spinner';
import ReCAPTCHA from 'react-google-recaptcha'

class ContactUs extends React.Component{

    constructor(){
        super();

        this.state = {
            name : '',
            email : '',
            message: '',
            loading : false,
        }

        this.recaptchaRef = React.createRef();
    }

    static getDerivedStateFromProps(props, state) {
        const { loading } = props.public;
      
        let stateChanged = false;
        let changedState = {};
    
        if(JSON.stringify(state.loading) !== JSON.stringify(loading)){
            changedState.loading = loading;
            stateChanged = true;            
        }
        
        if(stateChanged){
            return changedState;
        }
    
        return null;
    }

    onChange = e => {
        this.setState({[e.target.name]: e.target.value});
    }

    onSubmit = (e) =>{
        e.preventDefault();

        const recaptchaValue = this.recaptchaRef.current.getValue();

        const data = {
            recaptcha: recaptchaValue,
            name : this.state.name,
            email : this.state.email,
            query : this.state.message,
            hostname : window.location.hostname
        }

        this.props.contactUs(data)
        .then( res => { 
            if(res){
                this.setState({
                    name : '',
                    email : '',
                    message : ''
                })
                this.recaptchaRef.current.reset();
            }
        })
    }
    
    render(){

            const { name , email , message } = this.state;

        return (
        <div className="contactus-bg">
            <Spinner />
            <Header />
            <div className="bss-bg">
                <div className="container-fluid">
                    <div className="row bss-224">
                        <div className="col-xl-6 col-lg-6 col-md-6 bss-001">
                            <h1 className="bss-011">Questions?Concerns?Don't hesitate to reach out.</h1>
                            <h1 className="bss-014">info@tonesoftherapy.com</h1>
                        </div>

                        <div className="col-sm-12 col-12 text-center bss-334">Contact Us</div>
            
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 bss-321">
                            <AlertMessage />
                            <div className="bss-322">Contact Us</div>

                            <form className="bss-323" onSubmit={this.onSubmit}>
                                <div className="form-group bss-f01">
                                    <label htmlFor="name">Name</label>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        id="name" 
                                        name="name"
                                        value={name}
                                        onChange={this.onChange}
                                        required
                                    />
                                </div>
                                
                                <div className="form-group">
                                    <label htmlFor="email">Email</label>
                                    <input 
                                        type="email" 
                                        className="form-control" 
                                        id="email"
                                        name="email"
                                        value={email}
                                        onChange={this.onChange}  
                                        required                     
                                    />

                                </div>
            
                                <div className="form-group">
                                    <label htmlFor="message">Message</label>
                                    <textarea 
                                        className="form-control" 
                                        id="message" 
                                        rows="3"
                                        name="message"
                                        value={message}
                                        onChange={this.onChange}
                                        required
                                        
                                        > </textarea>
                                </div>

                                <ReCAPTCHA
                                    ref={this.recaptchaRef}
                                    size="normal"
                                    sitekey={ process.env.REACT_APP_RECAPTCHA }
                                />
                                
                                <button type="submit" className="btn btn-primary bss-fm-bttn">Submit</button>
            
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            
            <Footer />
        </div>
        )
    }
}

contactUs.propTypes = {
    contactUs: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    public : state.public
});

export default connect(mapStateToProps,{ contactUs })(withRouter(ContactUs));