import {
    SET_CATEGORIES,
    SET_LISTING,
    PAGE_LOADING,
    PAGE_LOADED,
    SET_PROFILE,
    SET_KNOWLEDGEBASE,
    //ADMIN Seciton Filters Works
    SET_ADMIN_DELETE_CATEGORY,
    SET_ADMIN_CREATE_CATEGORY

} from '../actions/types';

const initialState = {
    loading : false,
    Categories : [],
    listing : [],
    profile : {},
    knowledgebase : []
};

export default function (state = initialState, action) {
	switch (action.type) {

        case PAGE_LOADING:
			return {
				...state,
				loading: true
			};

		case PAGE_LOADED:
			return {
				...state,
				loading: false
            };
            
        case SET_CATEGORIES:
            return {
                ...state,
                Categories : action.payload
            }

        case SET_ADMIN_DELETE_CATEGORY:
            {
                const oldData = [...state.Categories];
                const updatedData = oldData.filter( r =>  r.category_id !== parseInt(action.payload.id) );
                return {
                    ...state,
                    Categories : [...updatedData]
                }
            }

            case SET_ADMIN_CREATE_CATEGORY:
                {
                    const oldData = [...state.Categories];
                   oldData.push(action.payload);
                    return {
                        ...state,
                        Categories : [...oldData]
                    }
                }
           
        case SET_LISTING:
            return{
                ...state,
                listing : action.payload
            }

        case SET_PROFILE:
            return{
                ...state,
                profile : action.payload
            }

        case SET_KNOWLEDGEBASE:
            return {
                ...state,
                knowledgebase : action.payload
            }
            
		default:
			return state;
	}
}