import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { getKnowledgebase } from '../../../store/actions/publicActions';
import { sendTicket } from '../../../store/actions/therapistActions';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { Modal } from 'react-responsive-modal';

class HelpCenter extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            offset: 0,
            limit: 10,
            search: '',
            knowledgebase: [],
            showModel: false,
            query: ''
        }
    }

    componentDidMount() {
        this.searchResults();
    }

    onSubmit = () => {

        this.setState({
            offset: 0
        }, () => {
            this.searchResults();
        })
    }

    searchResults = () => {

        const data = {
            offset: this.state.offset,
            limit: this.state.limit,
            search: this.state.search
        }

        this.props.getKnowledgebase(data);

    }

    onChange = (e) => {

        this.setState({
            [e.target.name]: e.target.value
        })
    }

    openModel = () => {
        this.setState({
            showModel: true
        })
    }

    closeModal = () => {

        this.setState({
            showModel: false
        })
    }

    sendTicket = () => {

        this.closeModal()

        this.props.sendTicket({ query: this.state.query })
    }

    render() {
        const { knowledgebase } = this.props.public;

        return (
            <div className="helpCenterFormContainer">
                <h1 className="pageTitle">Help Center</h1>
                <div className="input-group add-on">
                    <input
                        className="form-control"
                        placeholder="Search"
                        id="Search"
                        type="text"
                        name="search"
                        onChange={this.onChange}
                        value={this.state.search}
                    />
                    <div className="input-group-btn">
                        <button onClick={this.onSubmit} className="btn btn-default" type="submit"><i className="fa fa-search" aria-hidden="true"></i></button>
                    </div>

                </div>

                <div style={{ marginTop: 20 }} className="accordion" id="accordionExample">
                    <Accordion>
                        {knowledgebase && knowledgebase.map((item, index) => (
                            <Card key={index}>
                                <Card.Header>
                                    <Accordion.Toggle as={Button} variant="link" eventKey={`${index}`}>
                                        {item.question}
                                        <i className="fa fa-caret-down" aria-hidden="true"></i>

                                    </Accordion.Toggle>
                                </Card.Header>

                                <Accordion.Collapse eventKey={`${index}`}>
                                    <Card.Body>{item.answer}</Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        ))}

                    </Accordion>

                </div>

                <div className="messageModal">
                    <button className="btn btn-default query-btn-help" onClick={this.openModel} >
                        Add your query
                </button>
                    {/* <EmailModel therapist={1} /> */}
                </div>
                {/* <SupportTicket /> */}

                {  this.state.showModel && <Modal open={true} onClose={this.closeModal} center>

                    <div className="modal-dialog modal-dialog-scrollable" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalScrollableTitle">Question</h5>
                                <button onClick={this.closeModal} type="button" className="close" >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <textarea
                                    name="query"
                                    value={this.state.query}
                                    onChange={this.onChange}

                                ></textarea>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={this.closeModal} >Close</button>
                                <button type="button" className="btn btn-primary" onClick={this.sendTicket}>Save changes</button>
                            </div>
                        </div>
                    </div>

                </Modal>}

            </div>
        )
    }

}


HelpCenter.propTypes = {
    getKnowledgebase: PropTypes.func.isRequired,
    sendTicket: PropTypes.func.isRequired,
    public: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    public: state.public
});

export default connect(mapStateToProps, { getKnowledgebase, sendTicket })(withRouter(HelpCenter));