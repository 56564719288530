import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import { withRouter } from 'react-router-dom'; 
import { editUser, getUser, getApplications } from '../../../store/actions/superAdminActions';

class EditUser extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loaded : false,
            application_id: '',
            first_name: '',
            last_name: '',
            email: '',
            password: '',
            role: '',
            ip_whitelist : '',
            applications: []
        };
    }

    onChange = ( e ) => {
        
        this.setState({
            [e.target.name]: e.target.value
        });     
    }

    onSubmitHandler = (e) => {
        e.preventDefault();

        const formData = {...this.state };
        delete formData.applications;
        delete formData.loaded;

        this.props.editUser(formData, this.props.history);
    } 
    
    static getDerivedStateFromProps(props, state) {
        if(window.location.href.indexOf("/users") > -1 && props.hist && props.match.path !== props.hist.path  ){
            props.changematch();
            props.history.push(props.hist.path);
        }

        let { applications, user } = props.superAdmin;
        
        if(applications && JSON.stringify(state.applications) !== JSON.stringify(applications)){
            return{
                applications : applications
            }
        } 

        if(!state.loaded && user.application_login_id ){
            delete user.password;

            return { 
                ...user,
                loaded : true,
            }   
        }

        return null
    }
    
    componentDidMount() {

        this.props.getApplications();
        this.props.getUser(this.props.match.params.id);
    }


    render() {
        const { application_id, applications, first_name, last_name, email, password, role, ip_whitelist }= this.state;
        
        return(
        <div className="settings-page">
            <h1 className="pageMainTitle">Edit User</h1>
            <div className="settings-page-content">
                <form onSubmit={this.onSubmitHandler} >
                    <div className="form-group">
                        <label htmlFor="application_id">Application</label>
                        <select 
                            type="text" 
                            className="form-control" 
                            id="application_id" 
                            name='application_id'
                            value={ application_id }
                            onChange={this.onChange }
                            placeholder="Select Role"
                            required
                        >
                            <option value=""></option>
                            { applications.length && applications.map( (app, index) =>(
                                <option key={index} value={ app.application_id }>{ app.application_name } </option>
                            ))}
                        </select>
                    </div>

                    <div className="form-group">
                        <label htmlFor="first_name">First Name</label>
                        <input 
                            type="text" 
                            className="form-control" 
                            id="first_name" 
                            name='first_name'
                            value={ first_name }
                            onChange={this.onChange }
                            placeholder="Enter First Name"
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="last_name">Last Name</label>
                        <input 
                            type="text" 
                            className="form-control" 
                            id="last_name" 
                            name='last_name'
                            value={ last_name }
                            onChange={this.onChange }
                            placeholder="Enter Last Name"
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input 
                            type="text" 
                            className="form-control" 
                            id="email" 
                            name='email'
                            value={ email }
                            onChange={this.onChange }
                            placeholder="Enter Email Address"
                            required
                        />
                    </div>


                    <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <input 
                            type="text" 
                            className="form-control" 
                            id="password" 
                            name='password'
                            value={ password }
                            onChange={this.onChange }
                            placeholder="Enter Password"
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="role">role</label>
                        <select 
                            type="text" 
                            className="form-control" 
                            id="role" 
                            name='role'
                            value={ role }
                            onChange={this.onChange }
                            placeholder="Select Role"
                            required
                        >
                            <option value=""></option>
                            <option value="admin">Admin</option>
                            <option value="support">Support</option>
                        </select>
                    </div>

                    <div className="form-group">
                        <label htmlFor="ip_whitelist">IP WhiteList</label>
                        <input 
                            type="text" 
                            className="form-control" 
                            id="ip_whitelist" 
                            name='ip_whitelist'
                            value={ ip_whitelist }
                            onChange={this.onChange }
                            placeholder="Enter White List IP"
                            required
                        />
                    </div>

                    <button type="submit" className="btn btn-primary">Submit</button>
                </form>
            </div>
        </div>
        )
    }
}

EditUser.propTypes = {
    getApplications: PropTypes.func.isRequired,
    getUser: PropTypes.func.isRequired,
    editUser: PropTypes.func.isRequired,
    superAdmin: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    superAdmin: state.superAdmin
});

export default connect(mapStateToProps, {  getUser, editUser, getApplications })(withRouter(EditUser));