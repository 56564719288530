import React from 'react';
import { Device } from 'twilio-client';
import axios from 'axios';

let backendServerURL = process.env.REACT_APP_API_URL;

class CallModel extends React.Component{

    constructor(props){
        super(props);

        this.state= {
            number: '',
            callStatus : ''
        }

        this.device = new Device();

    }

    onChange = (e) =>{

        this.setState({
            [e.target.name] : e.target.value
        })
    }


    makeCall = (e) =>{
        e.preventDefault();

        var self = this;

        var params = {
            "list_id": this.props.therapist,
            "from": this.state.number 
        };

        console.log('params',params)

        axios
        .get(
            backendServerURL+'/call'
        )
        .then(res => {
            this.device.setup(res.data,{
                enableRingingState : true,
                audioConstraints: {
                    mandatory: { 
                        googAutoGainControl: false 
                    } 
                } 
            });

            
            this.device.on('ready',function (device) {

                console.log("Ready",device);

                console.log('params', params)

                var audioConstraints = {
                    optional: [{ sourceId: 'default' }]
                };

                let connection = device.connect(params, audioConstraints);

                self.setState({
                    connection : connection
                })

                connection.on('accept', function (connection){
                    self.setState({
                        callStatus : 'connected' 
                    })
                })

                connection.on('cancel', function (connection){
                    self.setState({
                        callStatus : 'Call Canceled' 
                    })
                })

                connection.on('disconnect', function (connection){

                    self.setState({
                        callStatus : 'The Call Has Ended' 
                    })

                })
            });
            
            this.device.error(function (error) {
                console.log("ERROR: " + error.message);
            });


        })
        .catch(err => console.log('error', err))

    }

    hangup = () =>{

        this.state.connection.disconnect();
    }
    

    render(){

    return(
        <div className="modal fade" id="CallModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Enter Your Number</h5>
                
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>

                </div>
                <div className="modal-body">
                { this.state.connection ?
                <div className="call-logs">
                    <p className="call-log-dynamic-text">{ this.state.callStatus }</p>
                    { this.state.connection.isMuted() ? 
                        <i className="fa fa-microphone cursor-pointer" aria-hidden="true" onClick={()=> this.state.connection.mute(false)}></i>
                    : <i className="fa fa-microphone-slash cursor-pointer" aria-hidden="true" onClick={()=> this.state.connection.mute(true)}></i> }

                    <i className="fa fa-phone-square cursor-pointer" aria-hidden="true" onClick={ this.hangup }></i>
                </div>
                :
                <form onSubmit={ this.makeCall  }>
                    <div className="form-group row phoneNumberGroup">
                    <label htmlFor="number" className="col-sm-2 col-form-label">Number</label>
                    <div className="col-sm-10">
                        <input 
                            type="text" 
                            className="form-control" 
                            placeholder="Enter Your Number Here"
                            name="number"
                            onChange={this.onChange}
                            required
                        />

                    </div>
                    </div>

                    <div className="modal-footer">
                        <button type="submit" className="btn btn-primary upNextBtn">Call</button>
                    </div>


                </form>

                }
            
                </div>
                
                
            </div>
            </div>
        </div>
    )
    }
}

export default CallModel;