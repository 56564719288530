import React from 'react';
import arrayMove from 'array-move';
import { Link , withRouter} from 'react-router-dom';
import { Formik, Form, Field } from "formik";
import { Row, Card, CardBody, FormGroup, Label, Button } from "reactstrap";
import { Colxx, Separator } from "../../theme/CustomBootstrap";
import {connect} from 'react-redux';
import { addNewCategory } from '../../../store/actions/adminActions';
import { List } from './Sortable';

class AddCategory extends React.Component {

    constructor(props) {
        super(props);
        this.state = { 
            name: '',
            position: '',
            attributes: [{
                sub_category: ''
            }]
        };
    }

    onSortEnd = ({oldIndex, newIndex}) => {

        this.setState(({attributes}) => ({
            attributes: arrayMove(attributes, oldIndex, newIndex),
        }));
    };

    static getDerivedStateFromProps(props, state) {

        if(window.location.href.indexOf("/categories") > -1 && props.hist && props.match.path !== props.hist.path  ){
            props.changematch();
            props.history.push(props.hist.path);
    
        }

        return null;
    }

    addNewAttribute = () =>{
        const newAttribute = {
            sub_category : ''
        };
  
        this.setState({
            attributes: [...this.state.attributes, newAttribute ]
        })
    }
  
    removeAttibute = (index) =>{

        const oldAttributes = this.state.attributes;
  
        oldAttributes.splice(index, 1);
        
        this.setState({
            attributes: [...oldAttributes]
        })
    }
  
    onChangeHandler = ( targetName, e ) => {
        if(targetName === 'attribute'){
            let targetId = e.target.id;
    

            let attributes = this.state.attributes;
    
                attributes[targetId] = {
                    ...attributes[targetId],
                    sub_category: e.target.value    
                }
            this.setState({attributes : attributes});
        }
        
        else{
            this.setState({
                [targetName]: e.target.value
            });
        }  
    }

    handleSubmit = (e) => {
        // e.preventDefault();
        const { name, position, attributes } = this.state;

        const formData = {
            name,
            position: parseInt(position),
            attributes
        };

        this.props.addNewCategory(formData, this.props.history);
    }

    render() {

        const { name, position, attributes } = this.state;

        return(
        <React.Fragment>
            <Row>
                <Colxx xxs="12">
                    <h1 className="h1-theme">Add Category</h1>
                    <Separator className="mb-5" />
                </Colxx>
            </Row>

        <Row className="mb-4">
            <Colxx lg="8" md="12">
            <Card>
                <CardBody>
                <Formik
                    initialValues={{
                        name: "",
                        email: ""
                    }}
                    onSubmit={this.handleSubmit}>
                    {({ errors, touched }) => (
                    <Form className="av-tooltip tooltip-label-right">
                        
                        <Label className="label-theme"> Category </Label>
                        <FormGroup>
                        <Field
                            className="form-control-theme"
                            name="name"
                            validate={this.validateName}
                            onChange={(e) =>  this.onChangeHandler('name', e) }
                            value={name || ''}
                            placeholder="Add Category Name"
                                
                        />

                        {errors.name && touched.name && (
                            <div className="invalid-feedback d-block">
                            {errors.name}
                            </div>
                        )}
                        </FormGroup>

                        <Label className="label-theme"> Position </Label>
                        <FormGroup>
                        <Field
                            className="form-control-theme"
                            name="position"
                            onChange={(e) =>  this.onChangeHandler('position', e) }
                            value={position || ''}
                            placeholder="Enter Categoty Positon"
                                
                        />

                        {errors.name && touched.name && (
                            <div className="invalid-feedback d-block">
                            {errors.name}
                            </div>
                        )}
                        </FormGroup>

                        <Label className="label-theme">Sub categories </Label>
                        { attributes.length && 
                            <List items={attributes} onChange={ this.onChangeHandler } removeAttibute={ this.removeAttibute } onSortEnd={this.onSortEnd} />
                        }

                        <div className="wrapper-addmore-link">
                            <Link to={"#"}   onClick={this.addNewAttribute} className="more-category add-more-link"> Add more sub-category </Link>
                        </div>

                        <Button color="primary" type="submit">
                            Submit
                        </Button>
                    </Form>
                    )}
                </Formik>
                </CardBody>
            </Card>
            </Colxx>
        </Row>
        </React.Fragment>
        )
    }
}

  
export default connect(null, {addNewCategory})(withRouter(AddCategory));