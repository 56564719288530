import React from 'react';
import {BrowserRouter as Router , Route,  withRouter} from 'react-router-dom';

import Applications from './applications';
import AddApplication from './applications/AddApplication';
import EditApplication from './applications/editApplication';

import Users from './users';
import AddUser from './users/AddUser';
import EditUser from './users/EditUser';

class SuperAdminInnerRoutes extends React.Component {
	constructor(props){
		super(props);
		
		this.state = {
			prefix: '/super-admin',
			match : ''
		}
    }
    
    componentWillReceiveProps(nextProps) {
		this.setState({ match: nextProps.match } );	
	}

	changematch = () =>{
		this.setState({match: ''});
	}
	
	render(){

		const { match, prefix }= this.state;
		
		return (
			<Router >
				<Route
					exact 
					path={prefix+"/"} 
					component= { Applications } 
				/>

				<Route
					exact 
					path={prefix+"/applications"} 
					component= { Applications } 
				/>

				<Route
					exact 
					path={prefix+"/application/edit/:id"} 
					component= {()=> <EditApplication 
						hist={ match }
						changematch={this.changematch} 
					/> } 
				/>

				<Route
					exact 
					path={prefix+"/application/add"} 
					component= {()=> <AddApplication 
						hist={ match }
						changematch={this.changematch} 
					/>
					}
				/>

				<Route
					exact 
					path={prefix+"/users"} 
					component= { Users } 
				/>

				<Route
					exact 
					path={prefix+"/user/edit/:id"} 
					component= {()=> <EditUser 
						hist={ match }
						changematch={this.changematch} 
					/>
					}
				/>

				<Route
					exact 
					path={prefix+"/user/add"} 
					component= {()=> <AddUser 
						hist={ match }
						changematch={this.changematch} 
					/>
					}
				/>

			</Router>
		);
	}
}

export default withRouter(SuperAdminInnerRoutes);