import { 
	SET_SUPER_ADMIN,
	CLEAR_SUPER_ADMIN,
 } from '../actions/types';

const initialState = {
	isAuthenticated: false,
	superAdmin: {}
};

export default function(state = initialState, action) {
	switch (action.type) {

		case SET_SUPER_ADMIN:
			return {
				...state,
				isAuthenticated: true,
				superAdmin: action.payload
			};

		case CLEAR_SUPER_ADMIN:
			return {
				...state,
				isAuthenticated: false,
				superAdmin : {}
			};

		default:
			return state;
	}
}