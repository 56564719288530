import React from 'react';
import spinner from './spinner.gif';
import {connect} from 'react-redux';

class Spinner extends React.Component {

	render() {
	return (

		<>
		{this.props.public.loading &&
			<>
			<div style={{position: "fixed", top: "50%", left: "50%", zIndex:"9999"}}>
				<img
					src={spinner}
					style={{ width: '70px', margin: 'auto', display: 'block', zIndex: '999999999999' }}
					alt="Loading..."
				/>
			</div>
			<div className="overlaySpinner"></div>
			</>
		}
		</>
	)}
}

const mapStateToProps = state => ({
    public: state.public
});

export default connect(mapStateToProps,{ })(Spinner);
