import axios from 'axios';
import setAuthToken from '../../utils/setAuthToken';
import jwt_decode from 'jwt-decode';

import {
    PAGE_LOADING,
    PAGE_LOADED,
    SET_SUPER_ADMIN,
    CLEAR_SUPER_ADMIN,
    SET_SUPER_ADMIN_APPLICATIONS,
    SET_SUPER_ADMIN_APPLICATION,
    SET_ERRORS,
    CLEAR_APPLICTION,
    EDIT_SUPER_ADMIN_APPLICATION,
    SET_SUCCESS,
    SET_SUPER_ADMIN_USER,
    SET_SUPER_ADMIN_USERS,
    SUPER_ADMIN_DELETE_USER
} from './types';
 
let backendServerURL = process.env.REACT_APP_API_URL;
let route = '/super-admin';     


export const successNotification = (msg) => {
	return {
        type: SET_SUCCESS,
        payload: msg
    };
    
};

export const setPageLoading = () => {
	return {
		type: PAGE_LOADING
	};
};

export const clearPageLoading = () => {
	return {
		type: PAGE_LOADED
	};
};


// Set logged in Superadmin (Verified)
export const setCurrentSuperAdmin = decoded => {
    return {
        type: SET_SUPER_ADMIN,
        payload: decoded
    };
};


export const setError = (msg) => {
	return {
        type: SET_ERRORS,
        payload: msg
    };
    
};

export const clearCurrentAdmin = () => {
    localStorage.removeItem('superJwtToken');
    return {
		type: CLEAR_SUPER_ADMIN
	};
};


// Log Super Admin out (Verified)
export const logoutSuperAdmin = history => dispatch => {
    setAuthToken(false);
    dispatch(clearCurrentAdmin());
};

export const SuperAdminLogin = (data, history) => dispatch => {

    dispatch(setPageLoading());

    axios
    .post(
        backendServerURL+'/auth/superadmin/login',
        data
    )
    .then(res => {
        const {token} = res.data;  
        localStorage.setItem('superJwtToken', token);
        setAuthToken(token);
        const decoded = jwt_decode(token);
        dispatch(setCurrentSuperAdmin(decoded));
        history.push(`${route}/`)
    })
    .catch(err => {
        dispatch(setError( err.response.data && err.response.data.message ));
    }) 
    .finally(() => dispatch(clearPageLoading()));
}


export const getApplications = () => dispatch => {

    dispatch(setPageLoading());

    axios
    .get(
        backendServerURL+'/superadmin/application'
    )
    .then(res => {
        dispatch({type : SET_SUPER_ADMIN_APPLICATIONS, payload : res.data})

    })
    .catch(err => {
        dispatch(setError( err.response.data && err.response.data.message ));
    })
    .finally(() => dispatch(clearPageLoading()));
}

export const getApplication = (id) => dispatch => {

    dispatch(setPageLoading());

    axios
    .get(
        backendServerURL+'/superadmin/application/'+id
    )
    .then(res => {

        dispatch({type : SET_SUPER_ADMIN_APPLICATION, payload : res.data})

    })
    .catch(err => {
        dispatch(setError( err.response.data && err.response.data.message ));
    })
    .finally(() => dispatch(clearPageLoading()));
}


export const addApplication = (formData, history) => dispatch =>{

    dispatch(setPageLoading());

    axios
    .post(
        backendServerURL+'/superadmin/application/add',
        formData
    )
    .then(res => {

        dispatch( successNotification( `Application! Successfully Added` ));

        history.push(`${route}/`)
    })
    .catch(err => {
        dispatch(setError( err.response.data && err.response.data.message ));
    })
    .finally(() => dispatch(clearPageLoading()));
}

export const editApplication = (formData, history) => dispatch =>{

    dispatch(setPageLoading());

    axios
    .post(
        backendServerURL+'/superadmin/application/edit',
        formData
    )
    .then(res => {

        dispatch({type: CLEAR_APPLICTION })
        dispatch( successNotification( `Application! Successfully Updated` ));

        history.push(`${route}/`)
    })
    .catch(err => {
        dispatch(setError( err.response.data && err.response.data.message ));
    })
    .finally(() => dispatch(clearPageLoading()));

}

export const applicationStatusHandler =(formData) => dispatch =>{

    dispatch(setPageLoading());

    axios
    .post(
        backendServerURL+'/superadmin/application/update-status',
        formData
    )
    .then(res => {

        dispatch({ type: EDIT_SUPER_ADMIN_APPLICATION , payload : res.data })

        dispatch( successNotification( ` Successfully ${res.data.is_active ? 'enabled' : 'disabled'} Application! ` ));
    })
    .catch(err => {
        dispatch(setError( err.response.data && err.response.data.message ));
    })
    .finally(() => dispatch(clearPageLoading()));
}

export const getUsers =() => dispatch =>{

    dispatch(setPageLoading());

    axios
    .get(
        backendServerURL+'/superadmin/user'
    )
    .then(res => {

        dispatch({ type: SET_SUPER_ADMIN_USERS, payload : res.data })
    })
    .catch(err => {
        dispatch(setError( err.response.data && err.response.data.message ));
    })
    .finally(() => dispatch(clearPageLoading()));

}

export const getUser = (userId) => dispatch =>{

    dispatch(setPageLoading());

    axios
    .get(
        backendServerURL+'/superadmin/user/'+userId
    )
    .then(res => {

        dispatch({ type: SET_SUPER_ADMIN_USER, payload : res.data })
    })
    .catch(err => {
        dispatch(setError( err.response.data && err.response.data.message ));
    })
    .finally(() => dispatch(clearPageLoading()));

}


export const addUser = (formData, history) => dispatch =>{

    dispatch(setPageLoading());

    axios
    .post(
        backendServerURL+'/superadmin/user/add',
        formData
    )
    .then(res => {

        dispatch( successNotification( `User! Successfully Added` ));

        history.push(`${route}/users`)
    })
    .catch(err => {
        dispatch(setError( err.response.data && err.response.data.message ));
    })
    .finally(() => dispatch(clearPageLoading()));
}

export const editUser = (formData, history) => dispatch =>{

    dispatch(setPageLoading());

    axios
    .post(
        backendServerURL+'/superadmin/user/edit',
        formData
    )
    .then(res => {

        dispatch( successNotification( `User! Successfully Updated` ));

        history.push(`${route}/users`)
    })
    .catch(err => {
        dispatch(setError( err.response.data && err.response.data.message ));
    })
    .finally(() => dispatch(clearPageLoading()));
}

export const deleteUser = (formData, history) => dispatch =>{

    dispatch(setPageLoading());

    axios
    .post(
        backendServerURL+'/superadmin/user/delete',
        formData
    )
    .then(res => {

        dispatch( successNotification( `User! Successfully Deleted` ));

        dispatch({ type: SUPER_ADMIN_DELETE_USER, payload : formData.userId })
    })
    .catch(err => {
        dispatch(setError( err.response.data && err.response.data.message ));
    })
    .finally(() => dispatch(clearPageLoading()));
}