import React from 'react';
import arrayMove from 'array-move';
import { Link , withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import { getCategoryDetail, updateCategoryDetail, clearCategory } from '../../../store/actions/adminActions';
import { Formik, Form, Field } from "formik";
import { Row, Card, CardBody, FormGroup, Label, Button } from "reactstrap";
import { Colxx, Separator } from "../../theme/CustomBootstrap";
import { List } from './Sortable';

class EditCategory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            id: 0, 
            category: ''
        };
    }   

    onSortEnd = ({oldIndex, newIndex}) => {
        this.setState(({sub_categories}) => ({
            sub_categories: arrayMove(sub_categories, oldIndex, newIndex),
        }));
    };

    static getDerivedStateFromProps(props, state) {

        if(window.location.href.indexOf("/categories") > -1 && props.hist && props.match.path !== props.hist.path  ){
            props.changematch();
            props.history.push(props.hist.path);
    
        }

        let category = props.category;

        let stateChanged = false;
        let changedState = {};

        if(!state.loaded && category.category ){
            changedState.category = category.category
            changedState.position = category.position
            changedState.sub_categories = category &&  category.sub_categories;
            changedState.loaded = true;
            stateChanged = true; 
        }

        if(stateChanged){
            return changedState;
        }

        return null;
    }


    componentDidMount() {
        const id = parseInt(this.props.match.params.id);

        this.setState({id: id});
        this.props.getCategoryDetail(id);
    }

    componentWillUnmount(){
        this.props.clearCategory();
    }

    addNewAttribute = () =>{
        const newAttribute = {
            sub_category : ''
        };

        this.setState({
            sub_categories: [...this.state.sub_categories, newAttribute ]
        })
    }

    removeAttibute = (index) =>{
        const oldsub_categories = this.state.sub_categories;

        oldsub_categories.splice(index,1);

        this.setState({
            sub_categories: oldsub_categories
        })
    }

    onChangeHandler = ( targetName, e ) => {
        if(targetName === 'attribute'){
            let targetId = e.target.id;

            let sub_categories = this.state.sub_categories;

            sub_categories[targetId] = {
                ...sub_categories[targetId],
                sub_category: e.target.value    
            }
            this.setState({sub_categories : sub_categories});
        }
        else{

            this.setState({
                [targetName]: e.target.value
            });
        }
    }


    handleSubmit = (e) => {
        // e.preventDefault();
        const {id, category, position, sub_categories } = this.state;
        const formData = {
            category_id : id,
            category,
            position: parseInt(position),
            sub_categories
        };

        this.props.updateCategoryDetail(formData, this.props.history);
    }

    render() {
        const { category, position, sub_categories } = this.state;

    return(
        <React.Fragment>
        <Row>
            <Colxx xxs="12">
                <h1 className="h1-theme">Add Category</h1>
                <Separator className="mb-5" />
            </Colxx>
        </Row>

    <Row className="mb-4">
        <Colxx lg="8" md="12">
        <Card>
            <CardBody>
            <Formik
                initialValues={{
                    name: "",
                    email: ""
                }}
                onSubmit={this.handleSubmit}>

                {({ errors, touched }) => (
                <Form className="av-tooltip tooltip-label-right">
                    <Label className="label-theme"> Category </Label>

                    <FormGroup>
                    
                    <Field
                        className="form-control-theme"
                        name="name"
                        validate={this.validateName}
                        onChange={(e) =>  this.onChangeHandler('name', e) }
                        value={category || ''}
                        placeholder="Add Category Name"
                            
                    />

                    {errors.name && touched.name && (
                        <div className="invalid-feedback d-block">
                        {errors.name}
                        </div>
                    )}


                    </FormGroup>

                    <Label className="label-theme"> Position </Label>
                    <FormGroup>
                    

                        <Field
                            className="form-control-theme"
                            name="position"
                            onChange={(e) =>  this.onChangeHandler('position', e) }
                            value={position || ''}
                            placeholder="position"
                            required
                        />

                    </FormGroup>
                    <Label className="label-theme">Sub categories </Label>
                    { sub_categories && 
                        <List items={sub_categories} onChange={ this.onChangeHandler } removeAttibute={ this.removeAttibute } onSortEnd={this.onSortEnd} />
                    }

                    <div className="wrapper-addmore-link">
                        <Link to={"#"}   onClick={this.addNewAttribute} className="more-category add-more-link"> Add more sub-category </Link>
                    </div>

                    <Button color="primary" type="submit">
                        Submit
                    </Button>
                </Form>
                )}
            </Formik>


            </CardBody>
        </Card>
        </Colxx>
    </Row>
    </React.Fragment>
    )}
}

const mapStateToProps = state => ({
    category: state.adminPage.category,
});


export default connect(mapStateToProps, {getCategoryDetail, updateCategoryDetail, clearCategory })(withRouter(EditCategory));




