import React from 'react';
import {connect} from 'react-redux';
import { withRouter } from 'react-router-dom'; 
import { addApplication } from '../../../store/actions/superAdminActions';
import ApplicationForm from './ApplicationForm';

class SuperDashboardSettings extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            application_name: '',
            domain: '',
            stripe_publishable_key	: '',
            stripe_secret_key	: '',
            ticket_templete: '',
            ticket_response_templete: '',
            forget_password_templete: '',
            therapist_email_templete: ''
        };
    }

    onChange = ( e ) => {
        
        this.setState({
            [e.target.name]: e.target.value
        });     
    }

    onSubmitHandler = (e) => {
        e.preventDefault();
        const { application_name, domain, stripe_publishable_key,stripe_secret_key, 
            ticket_templete, ticket_response_templete, forget_password_templete, therapist_email_templete } = this.state;

        const formData = {
            application_name,
            domain,
            stripe_publishable_key,
            stripe_secret_key,
            ticket_templete,
            ticket_response_templete,
            forget_password_templete,
            therapist_email_templete
        };

        this.props.addApplication(formData, this.props.history);
    } 
    
    static getDerivedStateFromProps(props, state) {

        if(window.location.href.indexOf("/applications") > -1 && props.hist && props.match.path !== props.hist.path  ){
            props.changematch();
            props.history.push(props.hist.path);
        }

        return null
    }

    render() {

        return(
        <div className="settings-page">
            <h1 className="pageMainTitle">Add Application</h1>
            <div className="settings-page-content">
                <ApplicationForm state={this.state} onChange={this.onChange} onSubmit={this.onSubmitHandler} />
            </div>
        </div>
        )
    }
}
  
export default connect(null, {  addApplication })(withRouter(SuperDashboardSettings));