import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { resetPassword } from '../../store/actions/authActions';
import { setError } from '../../store/actions/therapistActions';

import Header from '../common/Header';
import Footer from '../common/Footer';
import AlertMessage from '../common/Alert';
import { redirectDashboardIfAlreadyLogin } from '../common/lib';
import Spinner from '../common/Spinner';

class ResetPassword extends React.Component{

    constructor(){
        super();
        this.state = {
            newPassword : '',
            confirmPasswrord : ''
        }
    } 

    onChange = e => {
        this.setState({[e.target.name]: e.target.value});
    }

    componentDidMount() {
        redirectDashboardIfAlreadyLogin(this.props.auth, this.props.history);
    }

    onSubmit = (e) =>{
        e.preventDefault();

        if(this.state.newPassword !== this.state.confirmPassword){
            this.props.setError('Password Not Match')
            return
        }

        const data = {
            password : this.state.newPassword,
            token : this.props.match.params.token
        }

        this.props.resetPassword(data, this.props.history);
    }

    render(){
        const { newPassword, confirmPassword } = this.state;

        return (
        <>
        <div className="login-bg">
            <Spinner />
            <Header />
            <div className="bss-bg">
                <div className="container-fluid">
                    <div className="row bss-224-login">
                    
                       <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 bss-321">
                                 
                            <AlertMessage />  

                            <form onSubmit={this.onSubmit} className="bss-323">
                                
                                <div className="bss-322">Reset Password</div>
                                    <div className="form-group">
                                        <label htmlFor="newPassword">New Password</label>
                                        <input 
                                            type="password" 
                                            className="form-control" 
                                            id="newPassword"
                                            name="newPassword"    
                                            value={ newPassword }
                                            onChange={this.onChange}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="confirmPassword">Confirm Password</label>
                                        <input 
                                            type="password" 
                                            className="form-control" 
                                            id="confirmPassword"
                                            name="confirmPassword"    
                                            value={ confirmPassword }
                                            onChange={this.onChange}
                                        />
                                    </div>

                                    <button type="submit" className="btn btn-primary bss-fm-bttn">Reset</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            
            <Footer />  
        </div>
        </>
        )
    }
}


ResetPassword.propTypes = {
    setError: PropTypes.func.isRequired,
    resetPassword: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps,{ resetPassword, setError })(withRouter(ResetPassword));