import React, { Component, Fragment } from "react";
import {connect} from 'react-redux';
import { getAccounts, accountActive } from '../../../store/actions/adminActions';
import { adminLoginAsUser } from '../../../store/actions/authActions';
import { Colxx, Separator } from "../../theme/CustomBootstrap";
import ReactTable from "react-table";
import DataTablePagination from "../../theme/DatatablePagination";
import {
    Row,
    Card,
    CardBody,
    InputGroup,
    InputGroupAddon,
    Input,
    Button
} from "reactstrap";


class SuperDashboardAccounts extends Component {

    constructor(props) {
        super(props);
        this.state = {
			accounts: {},
			limit: 10,
            offset: 0,
            search: '',
            showPageJump : true,
            page : 0
        };

        this.dataTableColumns =[
            {
                Header: "Accounts ID",
                accessor: "account_id",
                Cell: props => <p className="p-theme text-muted">{props.value}</p>
            },
            {
                Header: "First Name",
                accessor: "first_name",
                Cell: props => <p className="p-theme text-muted">{props.value}</p>
            },
            {
                Header: "Last Name",
                accessor: "last_name",
                Cell: props => <p className="p-theme text-muted">{props.value}</p>
            },
            {
                Header: "Display Name",
                accessor: "display_name",
                Cell: props => <p className="p-theme list-item-heading" >{props.value}</p>
            },
            {
                Header: "Email",
                accessor: "email",
                Cell: props => <p className="p-theme list-item-heading">{props.value}</p>
            },
            {
                Header: "Action",
                Cell: ({ original }) =>  <div style={{justifyContent: 'space-evenly' }}>
                        <Button color="secondary"  onClick={() => this.props.onLoginAsUser(original.account_id)} >Login</Button>{ " "}
                        { original.is_active === false ?  
                            <Button color="success" onClick={() => this.accountActiveHandler(original.account_id, original.is_active) } >Enable</Button> : 
                            <Button color="danger" onClick={() => this.accountActiveHandler(original.account_id, original.is_active) } >Disable</Button>
                        } 
                </div>    
            }
        ]
    }


	static getDerivedStateFromProps(props, state) {

		let accounts = props.accounts;
	  
	  
		let stateChanged = false;
		let changedState = {};
	  
		if(accounts && JSON.stringify(state.accounts) !== JSON.stringify(accounts)){
            changedState.accounts= accounts;
            stateChanged = true; 
		}

        if(stateChanged){
            return changedState;
        }
        
        
        return null;
    }
      

    componentDidMount() {
        const { offset, limit } = this.state;
        const formData = {
            offset: offset,
            limit: limit
        }

        this.props.onGetAccounts(formData);
    }


    pagnationHandler = ( offset ) => {

        this.setState({
              offset: offset
            },() => {
                const formData = {
                    offset: offset,
                    limit: this.state.limit,
                    search: this.state.search
                };

                this.props.onGetAccounts(formData);
            }
        );
    }
      
    onChangeHandler = (e) => {
        const targetValue = e.target.value;
        this.setState({ search: targetValue });
    }

    onSearchHandler = () => {
        this.setState({
            offset : 0
        })

        const { limit, search } = this.state;
        const formData = {
            offset: 0,
            limit: limit,
            search: search
        };

        this.props.onGetAccounts(formData);
    }

    accountActiveHandler = ( id, status ) => {
        const formData = {
            account_id: id,
            is_active: !status
        }

        this.props.onAccountActivate( formData );
    }
    
    render() {
        const {  accounts, offset, limit } = this.state;

        let totalPages = 0;


        if(accounts && accounts.count){
            totalPages = Math.ceil(accounts.count/limit);
        }
        

        return (    
        <Fragment>
            <Row>
            <Colxx xxs="12">
                {/* <Breadcrumb heading="Accounts" match={this.props.match} /> */}
                <h1 className="h1-theme">Accounts</h1>
                <Separator className="mb-5" />
            </Colxx>
            </Row>

            <Row>
            
            <Colxx xxs="6">
                <InputGroup className="mb-3">
                    <Input 
                        placeholder="Search" 
                        name="srch-term" 
                        id="srch-term" 
                        type="text"
                        onChange={this.onChangeHandler}
                    />
                    <InputGroupAddon addonType="append">
                        <Button outline color="secondary" onClick={this.onSearchHandler}>
                            Search
                        </Button>
                    </InputGroupAddon>
                </InputGroup>
            </Colxx>
            </Row>
            <Row>

            <Colxx xxs="12">

            <Card className="mb-4">
                <CardBody>
                

                    <ReactTable
                        data={accounts.rows}
                        columns={this.dataTableColumns}
                        paginationMaxSize={3}
                        defaultPageSize={ limit }
                        showPageJump={true}
                        showPageSizeOptions={false}
                        totalPages={totalPages}
                        PaginationComponent={DataTablePagination}
                        className={"react-table-fixed-height"}
                        currentPage={ offset }
                        onPageChange={this.pagnationHandler}
                    />
                    
                </CardBody>
                </Card>
            </Colxx>
            
            </Row>
        </Fragment>
        );
    }
}

const mapStateToProps = state => {
	return {
		accounts: state.adminPage.accounts,
	}
};
  
const mapDispatchToProps = dispatch => {
	return {
        onGetAccounts: (formData) => dispatch(getAccounts(formData)),
        onAccountActivate: (formData) => dispatch(accountActive(formData)),
        onLoginAsUser: (id) => dispatch(adminLoginAsUser(id)),
	}
};
  
  
export default connect(mapStateToProps, mapDispatchToProps)(SuperDashboardAccounts);