import axios from 'axios';
import setAuthToken from '../../utils/setAuthToken';
import jwt_decode from 'jwt-decode';

import {
    PAGE_LOADED,
    PAGE_LOADING,
    SET_THERAPIST,
    CLEAR_THERAPIST,
    CLEAR_NOTIFICATION,
    SET_ERRORS,
    SET_SUCCESS
} from './types';
 
let backendServerURL = process.env.REACT_APP_API_URL;
let baseURL = process.env.REACT_APP_BASE_URL;
       
export const setPageLoading = () => {
	return {
		type: PAGE_LOADING
	};
};

export const clearPageLoading = () => {
	return {
		type: PAGE_LOADED
	};
};


// Set logged in Admin (Verified)
export const setCurrentTherapist = decoded => {
    return {
        type: SET_THERAPIST,
        payload: decoded
    };
};

export const clearNotification = () => {
	return {
		type: CLEAR_NOTIFICATION
	};
};

export const clearCurrenttherapist = () => {
    localStorage.removeItem('jwtToken');
    return {
		type: CLEAR_THERAPIST
	};
};


// Log Admin out (Verified)
export const logouttherapist = history => dispatch => {
    localStorage.removeItem('jwtToken');
    setAuthToken(false);
    dispatch(clearCurrenttherapist());
};

export const login = (data, history) => dispatch => {

    dispatch(setPageLoading());

    axios
    .post(
        backendServerURL+'/auth/login',
        data
    )
    .then(res => {
        const {token} = res.data;  
        localStorage.setItem('jwtToken', token);
        setAuthToken(token);
        const decoded = jwt_decode(token)

        dispatch(setCurrentTherapist(decoded));
        history.push(`/therapist/dashboard`)
    })
    .catch(err => {
        dispatch({type: SET_ERRORS, payload: err.response.data && err.response.data.message })
    })
    .finally(() => dispatch(clearPageLoading()));
}

export const register = (data, history) => dispatch => {

    dispatch(setPageLoading());

    axios
    .post(
        backendServerURL+'/auth/register',
        data
    )
    .then(res => {
        const {token} = res.data;  
        localStorage.setItem('jwtToken', token);
        setAuthToken(token);
        const decoded = jwt_decode(token);

        dispatch(setCurrentTherapist(decoded));

        history.push(`/therapist/dashboard`)
    })
    .catch(err => {
        dispatch({type: SET_ERRORS, payload: err.response.data && err.response.data.message })
    })
    .finally(() => dispatch(clearPageLoading()));
}

export const adminLoginAsUser = (id) => dispatch => {

    dispatch(setPageLoading());

    axios
    .get( backendServerURL+`/admin/login-as-user?id=${id}`)
    .then(res => {
        
        const {token} = res.data;  
        localStorage.setItem('jwtToken', token);
        setAuthToken(token);
        const decoded = jwt_decode(token);
        dispatch(setCurrentTherapist(decoded));
        window.open( baseURL+'/therapist/dashboard', "_blank");
    })
    .finally(() => dispatch(clearPageLoading()));
}

export const sendRecoveryEmail = (data) => dispatch => {
    dispatch(setPageLoading());

    axios
    .post(
        backendServerURL+'/auth/recovery-email',
        data
    )
    .then(res => {

        dispatch({ type: SET_SUCCESS, payload: 'Email has Been Sent'})
    })
    .catch(err => {
        dispatch({type: SET_ERRORS, payload: err.response.data && err.response.data.message })
    })
    .finally(() => dispatch(clearPageLoading()));
}

export const resetPassword = (data, history) => dispatch => {

    dispatch(setPageLoading());
    axios
    .post(
        backendServerURL+'/auth/reset-password',
        data
    )
    .then(res => {

        history.push('/login')

        dispatch({ type: SET_SUCCESS, payload: 'password has been changed'})
    })
    .catch(err => {
        dispatch({type: SET_ERRORS, payload: err.response.data && err.response.data.message })
    })
    .finally(() => dispatch(clearPageLoading()));
}