import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {withRouter , Link} from 'react-router-dom';
import { register } from '../../store/actions/authActions';
import Header from '../common/Header';
import Footer from '../common/Footer';
import AlertMessage from '../common/Alert';
import { redirectDashboardIfAlreadyLogin } from '../common/lib';
import Spinner from '../common/Spinner';
import ReCAPTCHA from 'react-google-recaptcha';

class SignUp extends React.Component {

    constructor(){
        super();

        this.state = {
            firstName : '',
            lastName : '',
            displayName : '',
            email : '',
            password: ''
        } 

        this.recaptchaRef = React.createRef();
    }

    onChange = e => {
        this.setState({[e.target.name]: e.target.value});
    }

    componentDidMount() {
        redirectDashboardIfAlreadyLogin(this.props.auth, this.props.history);
    }

    onSubmit = (e) =>{
        e.preventDefault();

        const recaptchaValue = this.recaptchaRef.current.getValue();

        console.log('recaptchaValue', recaptchaValue)

        const data = {
            ...this.state,
            recaptcha: recaptchaValue,
            hostName : window.location.hostname
        }

        this.props.register(data, this.props.history);
    }

    render(){

        return (
        <div className="signup-bg">
            <Spinner />
            <Header />
            <div className="bss-bg">
                <div className="container-fluid">
                    <div className="row bss-224">
                        <div className="col-xl-6 col-lg-6 col-md-6 bss-001">
                            <h1 className="bss-011">Join our directory<br /> of licensed therapists</h1>
                        </div>
                        <div className="col-sm-12 col-12 text-center bss-334">Sign Up</div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 bss-321">
                            <AlertMessage />

                            <div className="bss-322">Sign Up</div>
                            <form className="bss-323" onSubmit={ this.onSubmit }>
                                <div className="form-row">
                                    <div className="form-group col-md-12 col-lg-6 bss-f01">
                                        <label htmlFor="firstName">First Name</label>
                                        <input 
                                            type="text" 
                                            className="form-control"
                                            id="firstName" 
                                            name="firstName"
                                            value={this.state.firstName}
                                            onChange={this.onChange} 
                                        />
                                    </div>
                                    <div className="form-group col-md-12 col-lg-6 bss-f02">
                                        <label htmlFor="lastName">Last Name</label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            id="lastName"
                                            name="lastName"
                                            value={this.state.lastName}
                                            onChange={this.onChange} 
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="displayName">Display Name</label>
                                    <input 
                                        type="text" 
                                        className="form-control"
                                        id="displayName"
                                        name="displayName"
                                        value={this.state.displayName}
                                        onChange={this.onChange}    
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="email">Email</label>
                                    <input 
                                        type="email" 
                                        className="form-control" 
                                        id="email"
                                        name="email"
                                        value={this.state.email}
                                        onChange={this.onChange}    
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="password">Password</label>
                                    <input 
                                        type="password" 
                                        className="form-control" 
                                        id="password"
                                        name="password"
                                        value={this.state.password}
                                        onChange={this.onChange}    
                                    />
                                </div>

                                <ReCAPTCHA
                                    ref={this.recaptchaRef}
                                    size="normal"
                                    sitekey={ process.env.REACT_APP_RECAPTCHA }
                                />

                                <button type="submit" className="btn btn-primary bss-fm-bttn">Sign up</button>
                                <Link to={'/login'} className="bss-325">Already have account?</Link>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
        )
    }
}

SignUp.propTypes = {
    register: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    auth : state.auth
});

export default connect(mapStateToProps,{ register })(withRouter(SignUp));