import React from 'react';
import { Link } from 'react-router-dom';
import {connect} from 'react-redux';
import { getFilters } from '../../../store/actions/publicActions';
import { deleteGroup } from '../../../store/actions/adminActions';
import ReactTable from "react-table";
import {
    Row,
    Card,
    CardBody,
    Button,
    Badge
} from "reactstrap";

import { Colxx , Separator } from "../../theme/CustomBootstrap";

class AdminCategories extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            limit: 5,
            offset: 0,
        };

        this.dataTableColumns =[
            {
                Header: "Name",
                accessor: "category",
                Cell: props => <p className="p-theme list-item-heading">{props.value}</p>
            },
            {
                Header: "Pos",
                accessor: "position",
                Cell: props => <p className="p-theme list-item-heading">{props.value}</p>
            },
            {
                Header: "Sub Categories",
                accessor: "sub_categories",
                Cell: props => <div style={{ display: 'flex', flexWrap: 'wrap' }}>{props.value.map(( item,index) =>  <Badge key={index} color="primary" pill className="m-1">{item.sub_category}</Badge>) }</div>
            },
            {
                Header: "Action",
                accessor: "category_id",
                Cell: props => <div style={{justifyContent: 'space-evenly' }}>
                    <Link to={`/admin/edit-category/${props.value}`}>
                        <Button color="secondary">Edit</Button>
                    </Link>{" "}
                    <Button color="danger" onClick={() =>  this.deleteHandler(props.value)}>Delete</Button>
                </div>
            }
        ]

    }

	static getDerivedStateFromProps(props, state) {

		let { Categories }  = props.public;
        
        if(Categories && JSON.stringify(state.categories) !== JSON.stringify(Categories)){
            return {
                categories: Categories
            }
        }

        return null;
    }
      
    deleteHandler = (id) => {
        this.props.deleteGroup(id);
    }
      

    componentDidMount() {
        this.props.getFilters();
    }

   

    render() {
        const { categories } = this.state;

        return(
        <React.Fragment>
            <Row>
                <Colxx xxs="12">
                    {/* <Breadcrumb heading="Accounts" match={this.props.match} /> */}
                    <h1 className="h1-theme">Categories</h1>
                    <div className="text-zero top-right-button-container">
                    <Link to={"/admin/add-category"}>
                        <Button color="success">Add Category</Button>
                    </Link>

                    </div>
                    <Separator className="mb-5" />
                </Colxx>
            </Row>

            <Row>
                <Colxx xxs="12">
                    <Card className="mb-4">
                        <CardBody>
                            <ReactTable
                                data={categories && [...categories]}
                                columns={this.dataTableColumns}
                                showPageJump={false}
                                showPageSizeOptions={false}
                            />
                            
                        </CardBody>
                    </Card>
                </Colxx>
            </Row>

        </React.Fragment>

        // <div className="edit-category-page">
        //     <div className="Category-btnContainer">
        //         <Link to={"/admin/add-category"}>
        //             <button type="button" className="add-category-Btn">Add Category</button>
        //         </Link>
        //     </div>

        //     <h1 className="pageMainTitle">Our Categories</h1>
        //     <div className="edit-categories-section">
        //         <div className="edit-categories-table">
        //             <table className="table table-bordered">
        //                 <thead>
        //                     <tr>
        //                         <th scope="col" className="category-column">Categories</th>
        //                         <th scope="col" className="subCategory-column">Sub-categories</th>
        //                         <th scope="col" className="action-column">Action</th>
        //                     </tr>
        //                 </thead>
        //                 <tbody>
        //                     {filters && filters.length ? filters.map( (filter, index) => (
        //                         <tr key={index}>
        //                         <td> { filter.category } </td>
        //                         <td>
        //                             { filter.sub_categories.length && 
        //                             filter.sub_categories.map( (sub_category, ind) => (
        //                                 <span className="badge badge-pill" key={ind}> { sub_category.sub_category } </span>
        //                             ) ) }
        //                         </td>
        //                         <td className="action-btn-column">
        //                             <Link to={`/admin/edit-category/${filter.category_id}`}>
        //                                 <button type="button" className="btn edit-btn">Edit</button>
        //                             </Link>
        //                             <button type="button" onClick={() =>  this.deleteHandler(filter.category_id)} className="btn delete-btn">Delete</button>
        //                         </td>
        //                         </tr>
        //                     )) : 
        //                     <tr>
        //                         <td colSpan="3"> No data found! </td>
        //                     </tr>}
        //                 </tbody>
        //             </table>
        //         </div>
        //     </div>
        // </div>
        )
    }
}



const mapStateToProps = state => ({
    public: state.public
});    
  
export default connect(mapStateToProps, {getFilters, deleteGroup })(AdminCategories);



