import { 
	SET_ADMIN_DASHBOARD_DATA,

	SET_ADMIN_CONTACT_TICKET_DATA,
	SET_ADMIN_ACCOUNT_TICKET_DATA,
	SET_ADMIN_SAVE_CONTACT_TICKET_RESPONSE,
	SET_ADMIN_SAVE_ACCOUNT_TICKET_RESPONSE,

	SET_ADMIN_ACCOUNTS_DATA,
	SET_ADMIN_ACCOUNTS_STATUS_HANDLER,

	SET_ADMIN_SINGLE_CATEGORY,

	SET_ADMIN_KNOWLEDGEBASES_DATA,
	SET_ADMIN_SINGLE_KNOWLEDGEBASE,
	SET_ADMIN_DELETE_KNOWLEDGEBASE,

	SET_ADMIN_SETTING

 } from '../actions/types';

const initialState = {

	dashbaordData:  {},
	contactTickets: {},
	accountTickets: {},
	accounts: [],
	category: {},
	knowledgebases: [],
	singleKnowledgebase: {},
	settings: {}

};

export default function(state = initialState, action) {
	switch (action.type) {

		case SET_ADMIN_DASHBOARD_DATA:
			return {
				...state,
				dashbaordData: action.payload
			};
		case SET_ADMIN_CONTACT_TICKET_DATA:
			return {
				...state,
				contactTickets: action.payload
			};
		case SET_ADMIN_ACCOUNT_TICKET_DATA:
			return {
				...state,
				accountTickets: action.payload
			};

		case SET_ADMIN_SAVE_CONTACT_TICKET_RESPONSE:
			{
				const oldContactTickets = {...state.contactTickets};
				const index = oldContactTickets.rows.findIndex(r => r.support_tickets_id === action.payload.support_tickets_id );
				oldContactTickets.rows[index] = action.payload;

				return {
					...state,
					contactTickets: {...oldContactTickets}
				};
			}
		case SET_ADMIN_SAVE_ACCOUNT_TICKET_RESPONSE:
			{
				const oldAccountTickets = {...state.accountTickets};
				const index = oldAccountTickets.rows.findIndex(r => r = r.support_tickets_id === action.payload.support_tickets_id );
				
				oldAccountTickets.rows[index] = action.payload;
				return {
					...state,
					accountTickets: {...oldAccountTickets}
				};
			}
		
		case SET_ADMIN_ACCOUNTS_DATA:
			return {
				...state,
				accounts: action.payload
			};
			
		case SET_ADMIN_ACCOUNTS_STATUS_HANDLER:
			{
				const oldData = [...state.accounts.rows];

				const index = oldData.findIndex(r => r.account_id === action.payload.account_id);
				oldData[index] = action.payload;

				return {
					...state,
					accounts: {
						count : state.accounts.count,
						rows: [...oldData]
					}
				};
			}

		case SET_ADMIN_SINGLE_CATEGORY:
			return {
				...state,
				category: action.payload
			};


		case SET_ADMIN_KNOWLEDGEBASES_DATA:
		return {
			...state,
			knowledgebases: action.payload
		};
		case SET_ADMIN_SINGLE_KNOWLEDGEBASE:
			return {
				...state,
				singleKnowledgebase: action.payload
		};

		case SET_ADMIN_DELETE_KNOWLEDGEBASE:
            {
				const oldData = [...state.knowledgebases];
                const updatedData = oldData.filter( r => r.knowledgebase_id !== parseInt(action.payload.id) );

				return {
                    ...state,
                    knowledgebases : [...updatedData]
                }
            }
           
		case SET_ADMIN_SETTING:
			return {
				...state,
				settings: action.payload
		};
			
		default:
			return state;
	}
}