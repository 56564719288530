import { 
	SET_ADMIN,
	CLEAR_ADMIN
 } from '../actions/types';

const initialState = {
	isAuthenticated: false,
	admin: {}
};

export default function(state = initialState, action) {
	switch (action.type) {

		case SET_ADMIN:
			return {
				...state,
				isAuthenticated: true,
				admin: action.payload
			};
		case CLEAR_ADMIN:
			return {
				...state,
				isAuthenticated: false,
				admin : {}
			};

		default:
			return state;
	}
}