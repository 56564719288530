import React from 'react';
import { Collapse } from 'react-collapse';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import { getFilters  } from '../../../store/actions/publicActions';
import Checkbox from 'react-simple-checkbox';

class SideBar extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            size : 3,
            Categories  : [],
            collapse : []
        }
    }

    componentDidMount(){

        this.props.getFilters();
    }


    static getDerivedStateFromProps(props, state) {

        let { Categories }  = props.public;
        
        if(Categories && JSON.stringify(state.Categories) !== JSON.stringify(Categories)){
            return {
                Categories: Categories
            }
        }

        return null;
    }

    addCollapse = (id) => {

        this.setState( (state) => ({
            collapse : [...state.collapse, id]
        }))
    }

    removeCollapse = (id) => {
        var newCollapse = [...this.state.collapse];

        var index = this.state.collapse.indexOf(id);

        newCollapse.splice(index, 1);

        this.setState( {
            collapse : newCollapse
        })
    }
    

    render(){

        const { Categories , size , collapse } = this.state;
        const { selected , find , onChange} = this.props;

    return (
    <>
    <div className="col-md-4 col-lg-3 sideBar">
        <div className="desktopPadding coloredSideBar">

        <h5 className="filterTitle">Filter Results</h5>

        <div className="filterSearch">
            <i className="fa fa-search searchIcon" aria-hidden="true"></i>
            <input 
                type="text" 
                name="find" 
                placeholder="City, Zip or Name" 
                value={ find }
                onChange={ onChange }
                />
        </div>

        { Categories.length ? Categories.map((category, index) => {
            return (
            <div key={index} className="filterResultTitle">
                <h6>{ category.category } </h6>
                <div className="filterCheckContainer">
                    { category.sub_categories.slice(0, size).map( (attribute,index2) =>(
                        <div key={index2} className="custom-checkbox ksk_check">
                            
                            <Checkbox 
                                id={ attribute.sub_category }
                                name={attribute.sub_category}
                                size={2.5}
                                color="#ea9901"
                                backAnimationDuration={100}
                                tickAnimationDuration={150}
                                checked={ selected.find(element => element === attribute.sub_category_id ) }
                                onChange={(value) => this.props.handleCheck( attribute.sub_category_id ,value)}
                            >

                            </Checkbox> 

                            <label className="lableClass cursor-pointer" htmlFor={ attribute.sub_category } > 
                                <span className="searchLabelText"> { attribute.sub_category } </span>
                            </label>

                        </div>
                    )) }

                    <Collapse isOpened={ collapse.find(element => element === category.category_id )} >
                        { category.sub_categories.slice(size,  category.sub_categories.length ).map((attribute,index2) =>(
                            <div key={ index2 }className="custom-control custom-checkbox ksk_check">
                               <Checkbox 
                                    id={ attribute.sub_category }
                                    name={attribute.sub_category}
                                    size={2.5}
                                    color="#ea9901"
                                    backAnimationDuration={100}
                                    tickAnimationDuration={150}
                                    checked={ selected.find(element => element === attribute.sub_category_id ) }
                                    onChange={(value) => this.props.handleCheck( attribute.sub_category_id ,value)}
                                >

                                </Checkbox> 

                                <label className="lableClass cursor-pointer" htmlFor={ attribute.sub_category } > 
                                    <span className="searchLabelText"> { attribute.sub_category } </span>
                                </label>
                            </div>
                        )) }
                    </Collapse>
                </div>
                
                { category.sub_categories.length > 3 &&
                    <div className="filterMoreBtn">
                        { collapse.find(element => element === category.category_id ) ?
                            <button onClick={() => this.removeCollapse(category.category_id) } type="button" className="border-0 p-0">Less -</button>
                            : <button onClick={() => this.addCollapse(category.category_id) } type="button" className="border-0 p-0">More +</button>
                        }
                    </div>
                }

            </div>
            ) } ) : '' }

        </div>
    </div>
    
    </>
    )}
    
}

SideBar.propTypes = {
    getFilters: PropTypes.func.isRequired,
    public: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    public: state.public
});

export default connect(mapStateToProps,{getFilters})(withRouter(SideBar));