import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { getFilters } from '../../../store/actions/publicActions';
import { addListings, getPaymentData } from '../../../store/actions/therapistActions';
import SideBar from './SideBar';
import General from './General';
import Location from './Location';
import PracticeDetails from './PracticeDetails';
import Payment from './Payment';
import Review from './Review';

const initialState = {
    activePage: 1,
    image: '',
    previousPaymentMethod: false,
    general: {
        name: '',
        listingType: '',
        title: '',
        image: '',
        phone: '',
        newClients: '',
        remoteSessions: '',
        prescribeMedication: '',
        slidingScale: '',
        email: '',
        website: '',
        license: '',
        licenseState: '',
        description: '',
        interval: ''
    },
    locations: [{
        address: '',
        city: '',
        state: '',
        zipCode: ''
    }],
    prices: [],
    subScription: '',
    filters: [],
    selected: [],
    radioChecked: [],
    newListings: [],
    paymentLocation: {
        address: '',
        city: '',
        state: '',
        zipCode: ''
    },
    togglePayment: false
};

class NewListing extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount() {
        this.props.getFilters();
        this.props.getPaymentData();
    }


    static getDerivedStateFromProps(props, state) {

        const { Categories } = props.public;
        const { paymentData } = props.therapist;
        const { notification } = props;

        if (Categories && JSON.stringify(state.filters) !== JSON.stringify(Categories)) {
            return {
                filters: Categories
            }
        }

        if (paymentData && JSON.stringify(state.paymentData) !== JSON.stringify(paymentData)) {
            return {
                paymentData: paymentData
            }
        }

        if (notification.message && state.activePage === 4) {
            return {
                paymentId: null
            }
        }

        return null;
    }

    onChangeImage = (event) => {

        this.setState({
            image: event.target.files[0],
            general: {
                ...this.state.general,
                image: event.target.value
            }
        });
    }

    handleCheck = (category, sub_category, value) => {

        const attribute = {
            category_id: category,
            sub_category_id: sub_category
        }

        if (value) {

            this.setState((state) => ({
                selected: [...state.selected, attribute]
            }))

        } else {
            var newSelected = [...this.state.selected];

            var index = this.state.selected.indexOf(item => item.sub_category_id === sub_category);

            newSelected.splice(index, 1);

            this.setState({
                selected: newSelected
            })
        }

    }

    radioCheck = e => {


        const attribute = {
            categorie: e.target.name,
            value: e.target.value
        }

        var newRadioChecked = [...this.state.radioChecked];

        const index = this.state.radioChecked.findIndex(element => element.categorie === attribute.categorie);

        if (index !== -1) {
            newRadioChecked.splice(index, 1);
        }

        this.setState({
            radioChecked: [...newRadioChecked, attribute]
        })
    }


    selectParacticeType = (type) => {
        this.setState({
            general: {
                ...this.state.general,
                listingType: type
            }
        });
    }

    onChange = (e, object) => {
        let value = e.target.value;
        if (value === "true") {
            value = true
        }
        else if (value === "false") {
            value = false
        }

        let newObject = {
            ...this.state[object],
            [e.target.name]: value
        }

        this.setState({
            [object]: newObject
        })
    }

    locationDataChange = e => {
        const targetName = e.target.name;

        const Fieldname = targetName.split(',')[0];
        const FieldId = targetName.split(',')[1];

        const locations = [...this.state.locations];


        locations[FieldId] = {
            ...locations[FieldId],
            [Fieldname]: e.target.value
        }

        this.setState({ locations });

    }


    onGenreralSubmit = e => {
        e.preventDefault();

        this.setState({
            activePage: 2
        })
    }

    onLocationSubmit = e => {
        e.preventDefault();

        this.setState({
            activePage: 3
        })
    }

    onPracticeDetailsSubmit = (e) => {
        e.preventDefault();

        let attributes = [...this.state.selected];


        this.state.radioChecked.map(radio => (
            attributes.push(parseInt(radio.value))
        ))

        this.setState({
            selected: [...attributes],
            activePage: 4
        })
    }

    onPaymentSubmit = (id, value) => {

        this.setState({
            activePage: 5,
            paymentId: id,
            previousPaymentMethod: value === "true" ? true : false
        })
    }

    addNewLocation = () => {
        const newLocation = {
            address: '',
            city: '',
            state: '',
            zipCode: ''
        };

        this.setState({
            locations: [...this.state.locations, newLocation]
        })
    }

    removeLocation = (position) => {
        const oldLocations = [...this.state.locations];

        oldLocations.splice(position, 1);

        this.setState({
            locations: oldLocations
        })
    }


    finalSubmit = (e) => {
        e.preventDefault();

        const data = {
            ...this.state.general,
            subScription: this.state.subScription,
            locations: [...this.state.locations],
            attributes: [...this.state.selected],
            paymentLocation: this.state.paymentLocation,
            imagefile: this.state.image,
            previousPaymentMethod: this.state.previousPaymentMethod
        }
        let formData = new FormData();

        const listings = [...this.state.newListings, data]

        listings.map(list => formData.append('images', list.imagefile));

        formData.append('listings', JSON.stringify(listings));
        formData.append('paymentId', this.state.paymentId);
        console.log('data', listings)
        this.props.addListings(formData, this.props.history);
    }

    addMoreListing = (e) => {
        e.preventDefault();
        console.log('this.state.general.interval', this.state.general.interval)
        const data = {
            ...this.state.general,
            subScription: this.state.subScription,
            locations: [...this.state.locations],
            attributes: [...this.state.selected],
            imagefile: this.state.image,
            previousPaymentMethod: this.state.previousPaymentMethod,
            paymentLocation: this.state.paymentLocation
        }

        this.setState({
            ...initialState,
            previousPaymentMethod: this.state.previousPaymentMethod,
            paymentLocation: this.state.paymentLocation,
            newListings: [...this.state.newListings, data]
        });
    }

    setActivePage = page => {

        if (page < this.state.activePage) {
            this.setState({
                activePage: page
            })
        }

    }

    changeSubscription = (type, interval, toggle) => {
        console.log('interval', interval, type)
        const state = {
            subScription: type,
            general: {
                ...this.state.general,
                interval
            },
            togglePayment: toggle ? toggle : false
        }

        if (this.state.paymentId) {
            state['activePage'] = 5;
        }
        this.setState(state)
    }

    changePaymentLocations = (e) => {
        console.log('this.state', this.state)
        let locations = {
            ...this.state.paymentLocation,
            [e.target.name]: e.target.value
        }
        this.setState({ paymentLocation: locations });
    }
    render() {

        let pageContent = ''

        if (this.state.activePage === 1) {
            pageContent = <General
                onClick={this.selectParacticeType}
                onChange={this.onChange}
                general={this.state.general}
                onChangeImage={this.onChangeImage}
                onSubmit={this.onGenreralSubmit}
            />
        }

        else if (this.state.activePage === 2) {
            pageContent = <Location
                onChange={this.locationDataChange}
                locations={this.state.locations}
                onSubmit={this.onLocationSubmit}
                addNewLocation={this.addNewLocation}
                removeLocation={this.removeLocation}
            />
        }
        else if (this.state.activePage === 3) {
            pageContent = <PracticeDetails
                onChange={this.onChange}
                filters={this.state.filters}
                selected={this.state.selected}
                handleCheck={this.handleCheck}
                radioCheck={this.radioCheck}
                onSubmit={this.onPracticeDetailsSubmit}
            />
        }
        else if (this.state.activePage === 4) {
            pageContent = <Payment
                subScription={this.state.subScription}
                paymentData={this.state.paymentData}
                onChange={this.changeSubscription}
                onSubmit={this.onPaymentSubmit}
                paymentId={this.state.paymentId}
                togglePayment={this.state.togglePayment}
                interval={this.state.general.interval}
                paymentLocation={this.state.paymentLocation}
                changeLocation={this.changePaymentLocations}
            />
        }
        else {
            pageContent = <Review
                general={this.state.general}
                filters={this.state.filters}
                selected={this.state.selected}
                locations={this.state.locations}
                onSubmit={this.finalSubmit}
                addMoreListing={this.addMoreListing}

            />
        }

        return (

            <div className="new-listing-Container">

                <SideBar
                    setActivePage={this.setActivePage}
                    activePage={this.state.activePage}
                />

                { pageContent}

            </div>
        );
    }
}

NewListing.propTypes = {
    getFilters: PropTypes.func.isRequired,
    addListings: PropTypes.func.isRequired,
    getPaymentData: PropTypes.func.isRequired,
    public: PropTypes.object.isRequired,

};

const mapStateToProps = state => ({
    public: state.public,
    therapist: state.therapist,
    notification: state.notification
});

export default connect(mapStateToProps, { getFilters, addListings, getPaymentData })(withRouter(NewListing));